
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'nuxt-property-decorator';
import ProductPrice from '~/models/product-price';
import { cx } from 'class-variance-authority';

@Component({
  name: 'dn-product-price-view',
})
export default class ProductPriceView extends Vue {
  @Prop({ type: Boolean, default: false }) forPDP?: boolean;
  @Prop({ required: true }) data!: ProductPrice;

  private get isLongPrice() {
    return this.basePrice > 99;
  }

  private get isPromotional() {
    return this.promotional.length > 0 && this.isMultiBuy;
  }

  private get isXYPromo() {
    return this.data.isXyPromo;
  }

  private get isDiscount() {
    return this.discount && !this.isMultiBuy;
  }

  private get isSuperPrice() {
    return this.data.isSuperPrice;
  }

  private get isMultiBuy() {
    return this.data.multibuy;
  }

  private get multiBuyText() {
    return this.data.multibuyText;
  }

  private get promotional() {
    return [
      String(this.data.specialPriceConditions)
        ?.split(' ')
        .slice(0, 2)
        .join(' '),
      String(this.data.specialPriceConditions)?.split(' ').slice(2).join(' '),
    ].filter(Boolean);
  }

  private get discount() {
    return this.data.reductionValue;
  }

  private get unit() {
    return this.data.unit;
  }

  private get greenText() {
    return this.data.greenText;
  }

  private get xyPromoText() {
    return this.data.xyPromoText;
  }

  private get basePrice() {
    return this.currentPrice[0] || 0;
  }

  private get penniesPrice() {
    return this.currentPrice[1] || 0;
  }

  private get currentPrice() {
    const price = this.data.isXyPromo ? this.data.oldPrice : (this.data.price || this.data.oldPrice);

    try {
      return new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
        .formatToParts(Number(price))
        .filter(({ type }) => type !== 'decimal')
        .map(({ value }) => value);
    } catch {
      return new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
        .formatToParts(0)
        .filter(({ type }) => type !== 'decimal')
        .map(({ value }) => value);
    }
  }

  get containerClasses() {
    if (this.forPDP) {
      return this.$options.filters?.merge(
        'border-2 border-red bg-red overflow-hidden self-end rounded-2xl size-w-[78px] xl:size-w-[114px] rounded-[12px] xl:rounded-[18px]',
        {
          'rounded-[12px] xl:rounded-[18px]':
            this.isDiscount || this.isXYPromo || this.isPromotional,
        },
        this.$vnode.data?.staticClass,
      );
    }

    return this.$options.filters?.merge(
      'border md:border-2 border-red bg-red overflow-hidden self-end rounded-2xl size-w-[54px] md:size-w-[78px] rounded-[10px] md:rounded-[12px]',
      {
        'rounded-[10px] md:rounded-[12px]':
          this.isDiscount || this.isXYPromo || this.isPromotional,
      },
      this.$vnode.data?.staticClass,
    );
  }

  get priceWrapperClasses() {
    if (this.forPDP) {
      return cx(
        'relative flex flex-col size-h-[74px] xl:size-h-[108px] w-full items-end justify-between bg-white bg-price bg-full bg-center-bottom bg-no-repeat',
        'after:absolute after:left-0 after:bottom-0 after:block after:w-full after:h-[15px] after:bg-green after:z-0 after:h-[12px] xl:after:h-[15px]',
        'pt-[8px] xl:pt-[14px] rounded-[10px] xl:rounded-[16px]',
      );
    }

    return cx(
      'relative flex flex-col size-h-[50px] md:size-h-[74px] w-full items-end justify-between bg-white bg-price bg-full bg-center-bottom bg-no-repeat',
      'after:absolute after:left-0 after:bottom-0 after:block after:w-full after:h-[15px] after:bg-green after:z-0 after:h-[10px] md:after:h-[12px]',
      'pt-[4px] md:pt-[8px] rounded-[8px] md:rounded-[10px]',
    );
  }

  get priceClasses() {
    if (this.forPDP) {
      return this.$options.filters?.merge(
        'flex text-red text-left px-0.5 font-normal font-impact',
        {
          'text-[46px] leading-[42px] xl:text-[64px] xl:leading-[54px]':
            !this.isLongPrice,
          'text-[32px] leading-[42px] xl:text-[46px] xl:leading-[54px]':
            this.isLongPrice,
        },
      );
    }

    return this.$options.filters?.merge(
      'flex text-red text-left px-0.5 font-normal font-impact',
      {
        'text-[29px] leading-[30px] md:text-[46px] md:leading-[42px]':
          !this.isLongPrice,
        'text-[21px] leading-[30px] md:text-[32px] md:leading-[42px]':
          this.isLongPrice,
      },
    );
  }

  get penniesClasses() {
    if (this.forPDP) {
      return this.$options.filters?.merge('font-normal text-right', {
        'text-[20px] leading-[21px] xl:text-4xl xl:leading-[32px] ml-[1px]':
          !this.isLongPrice,
        'text-[18px] leading-[32px] xl:text-3xl xl:leading-[41px] ml-[0.5px]':
          this.isLongPrice,
      });
    }

    return this.$options.filters?.merge('font-normal text-right', {
      'text-[15px] leading-[21px] md:text-[20px] md:leading-[21px] ml-[1px]':
        !this.isLongPrice,
      'text-[13px] leading-[23px] md:text-[18px] md:leading-[32px] ml-[0.5px]':
        this.isLongPrice,
    });
  }

  get labelClasses() {
    if (this.forPDP) {
      return this.$options.filters?.merge(
        'font-poppins font-extrabold text-white w-full text-right uppercase z-1 p-[0px_4px_4px_2px] xl:p-[0px_6px_6px_2px] text-[8px] xl:text-sm',
        {
          'text-[8px] xl:text-xs leading-none xl:leading-none text-center !p-[0_2px_2px]':
            this.isXYPromo,
        },
      );
    }
    return this.$options.filters?.merge(
      'font-poppins font-extrabold text-white w-full text-right uppercase z-1 p-[0px_2px_2px_2px] md:p-[0px_4px_4px_2px] text-[6px] md:text-[8px]',
      {
        'text-[6px] md:text-[8px] leading-none md:leading-none text-center !p-[0_2px_2px]':
          this.isXYPromo,
      },
    );
  }

  get discountWrapperClasses() {
    if (this.forPDP) {
      return this.$options.filters?.merge(
        'font-poppins h-[36px] xl:h-[52px] px-1 w-full flex flex-col items-center justify-center',
        { 'p-[4px_4px_0] xl:p-[11px_4px_0]': this.isXYPromo },
      );
    }

    return this.$options.filters?.merge(
      'font-poppins h-[28px] md:h-[36px] px-1 w-full flex flex-col items-center justify-center',
      { 'p-[4px_4px_0]': this.isXYPromo },
    );
  }
}
