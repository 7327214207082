export const API_URL: any = {
  SCREENS: {
    ROOT: '/sections/',
    SCREEN: (slug: string) => `/screens/${slug}/`,
  },
  SECTIONS: {
    SECTION: (slug: string) => `/sections/${slug}/`,
  },
  WIDGETS: {
    BANNER: (slug: string) => `/banners/${slug}/`,
  },
  BRANDS: {
    ROOT: `/dino_content/own_brands/`,
    BRAND: (slug: string) => `/dino_content/own_brands/${slug}/`,
    BY_NAME: (name: string) => `/dino_content/own_brands/by_name/${name}/`,
  },
  PRODUCTS: {
    DETAILS: (id: number) => `/products/variants/${id}/`,
    VARIANTS: `/products/variants/`,
    VARIANTS_BY_GROUP: (id: number) => `/products/variants/by_group/${id}`,
  },
  CAREERS: {
    ROOT: `/careers/`,
    CITIES: `/careers/cities/`,
    CATEGORIES: `/careers/categories/`,
    CITY: (id: string) => `/careers/cities/${id}/`,
    REVERSE_GEOCODE: `/careers/cities/reverse_geocode/`,
    CV_LINKS: `/careers/cv_links/`,
    JOB_OFFER: (slug: string) => `/careers/${slug}/`,
  },
  LAYOUT: {
    TOP_BANNERS: '/layout/top_banners/',
  },
  LEAFLET: {
    ROOT: '/promo_news/',
  },
  NEWSLETTER: {
    ROOT: '/newsletter/',
  },
  FOUNDATION: {
    ROOT: '/dino_content/charity_reports/',
  },
  BUSINESS_OFFER: {
    CONTACT: '/emails/contact/',
    COMPLAINT: '/emails/complaint/',
    FOR_DELIVERY: '/emails/for_delivery/',
    LAND_RENTAL: '/emails/land_rental/',
    LOOKING_FOR_LAND: '/emails/looking_for_land/',
    PLANTS_ORDERING: '/emails/plants/',
  },
  SEO: {
    META_INFO: '/seo/meta-information/',
  },
  SEARCH: {
    VARIANT_DETAILS: '/search/variant-details-search/',
  },
  CMS: {
    HERO_PRODUCT_BANNERS: 'cms/hero-product-banners/',
    VIDEO_WITH_PRODUCTS: 'cms/video-with-products/',
  },
  POPUPS: {
    ROOT: '/content/popups/',
  }
};
