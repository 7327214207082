
import { getModule } from 'nuxt-property-decorator';
import Vue from 'vue';
import { mapState } from 'vuex';
import GlobalModule from '~/store/GlobalModule';

export default {
  computed: {
    ...mapState('GlobalModule', ['isOverlayVisible', 'isMainOverlayVisible']),
  },
  methods: {
    clickOverlay() {
      getModule(GlobalModule, this.$store).setIsOverlayVisible(false);
      OverlayEventBus.$emit('overlayClicked');
    },
  },
};

export const OverlayEventBus = new Vue();
