export enum ImageSize {
  DESKTOP_SQUARE_SMALL = 'desktop_square_small',
  DESKTOP_SQUARE_MEDIUM = 'desktop_square_medium',
  DESKTOP_PRODUCT = 'desktop_product',
  DESKTOP_LEAFLET = 'desktop_leaflet',
  DESKTOP_SLIDER = 'desktop_slider',
  DESKTOP_BANNER = 'desktop_banner',
  DESKTOP_LEAFLET_BANNER = 'desktop_leaflet_banner',
  DESKTOP_BANNER_TWO_COLUMNS = 'desktop_banner_two_columns',
  DESKTOP_BANNER_FOUR_COLUMNS = 'desktop_banner_four_columns',
  DESKTOP_BANNER_NO_SPACE = 'desktop_banner_no_space',
  MOBILE_SQUARE_SMALL = 'mobile_square_small',
  MOBILE_SQUARE_MEDIUM = 'mobile_square_medium',
  MOBILE_PRODUCT = 'mobile_product',
  MOBILE_LEAFLET = 'mobile_leaflet',
  MOBILE_BANNER = 'mobile_banner',
  MOBILE_LEAFLET_BANNER = 'mobile_leaflet_banner',
  MOBILE_BANNER_TWO_COLUMNS = 'mobile_banner_two_columns',
  MOBILE_BANNER_FOUR_COLUMNS = 'mobile_banner_four_columns',
  MOBILE_BANNER_NO_SPACE = 'mobile_banner_no_space',
  DESKTOP_MOVIE_BANNER = 'desktop_movie_banner',
  MOBILE_MOVIE_BANNER = 'mobile_movie_banner'
}
