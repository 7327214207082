import { Expose } from 'class-transformer';
import BannerFile from '~/models/file';
export default class Product {
  @Expose()
  id!: number;

  @Expose()
  photo!: BannerFile;

  @Expose({ name: 'title' })
  name!: string;

  @Expose({ name: 'body' })
  manufacturer!: string;
}
