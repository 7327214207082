import { Expose, Type } from 'class-transformer';
import Banner from './banner';
import Product from './product';

export class Popup {
  @Expose()
  id!: string | number;

  @Expose()
  title!: string;

  @Expose()
  description!: string;

  @Expose({ name: 'date_from' })
  dateFrom!: string;

  @Expose({ name: 'date_to' })
  dateTo!: string;

  @Expose({ name: 'is_active' })
  isActive!: string;

  @Expose({ name: 'content_type' })
  contentType!: 'file' | 'variant';
}

export class VariantPopup extends Popup {
  @Expose({ name: 'variant' })
  @Type(() => Product)
  variant!: Product;
}

export class BannerPopup extends Popup {
  @Expose({ name: 'banner_files' })
  @Type(() => Banner)
  bannerFiles!: Banner[];
}