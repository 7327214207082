import { AxiosError } from 'axios';
import { Route } from 'vue-router';
import { Vue } from 'nuxt-property-decorator';
import { NuxtError } from '@nuxt/types';

interface Callbacks {
  redirect(status: number, path: string, query?: Route['query']): void;
  redirect(path: string, query?: Route['query']): void;
  error(params: NuxtError): void;
}

interface ErrorHandler {
  (error: AxiosError<any>, context: Vue, callbacks: Callbacks): void;
}

const notFound: ErrorHandler = (_, context, { error }) => {
  error({ message: context.$t('ERROR__NOT_FOUND') as string, statusCode: 404 });
};

const serverError: ErrorHandler = (_, context, { error }) => {
  error({
    message: context.$t('ERROR__INTERNAL_SERVER') as string,
    statusCode: 500,
  });
};

const serviceUnavailable: ErrorHandler = (_, context, { error }) => {
  error({
    message: context.$t('ERROR__SERVICE_UNAVAILABLE') as string,
    statusCode: 503,
  });
};

const handlers: { [key: string]: ErrorHandler } = {
  '404': notFound,
  '500': serverError,
  '503': serviceUnavailable,
};

const handler: ErrorHandler = (error, context, callbacks) => {
  try {
    const code = error.response!.status.toString();

    if (Object.hasOwnProperty.call(handlers, code)) {
      handlers[code](error, context, callbacks);

      return Promise.resolve(false);
    }
  } catch (e) {
    throw e;
  }
};

export { handler };
