import { Expose, Type } from 'class-transformer';
import LeafletData from '~/models/leaflet-data';
import 'reflect-metadata';

export default class LeafletResponse {
  @Expose()
  count!: number;

  @Expose()
  next!: string | null;

  @Expose()
  previous!: string | null;

  @Type(() => LeafletData)
  results!: LeafletData[];
}
