
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({
  name: 'dn-form',
})
export default class Form extends Vue {
  @Prop({ type: Object, default: {} }) form!: any;
  private invalid: boolean = true;

  async submit() {
    await this.form?.validateModel();
    this.invalid = !this.form?.isValid;
    
    if (this.invalid) {
      this.$emit('error', this.form?.errors, this.form?.fields);
    } else {
      this.$emit('submit');
    }
  }
}
