import { Expose, Type } from 'class-transformer';
import 'reflect-metadata';
import BadgeImg from '~/models/badge-img';

export default class FlowerBadge {
  @Expose()
  id!: number;

  @Expose({ name: 'desktop_img' })
  @Type(() => BadgeImg)
  desktopImg!: BadgeImg;

  @Expose({ name: 'tablet_img' })
  @Type(() => BadgeImg)
  tabletImg!: BadgeImg;

  @Expose({ name: 'mobile_img' })
  @Type(() => BadgeImg)
  mobileImg!: BadgeImg;

  @Expose()
  name!: string;

  @Expose({ name: 'sort_order' })
  sortOrder!: number;

  @Expose({ name: 'badge_type' })
  badgeType!: string;
}
