
import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch,
} from 'nuxt-property-decorator';
import cuid from 'cuid';
import { CheckboxType } from '@/models/enums/checkbox-type';

@Component({
  name: 'dn-checkbox-input',
})
export default class CheckboxInput extends Vue {
  $refs!: Vue['$refs'] & {
    [key: string]: HTMLElement;
  };
  @Model('change', { type: Boolean }) value!: boolean;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: '' }) checkboxClass!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) active!: boolean;
  @Prop({ type: Boolean, default: false }) redesigned!: boolean;
  @Prop({ type: String, default: CheckboxType.PRIMARY }) type!: CheckboxType;

  private isChecked: boolean = this.value;
  private cuid: string = '';

  private get id(): string {
    return `checkbox_${this.cuid}`;
  }

  private inputClasses: Record<CheckboxType, string> = {
    [CheckboxType.PRIMARY]: 'border border-gray-1',
    [CheckboxType.SECONDARY]: 'border border-white',
    [CheckboxType.INVERSED]: 'border border-gray-1',
  };

  private inputIconClasses: Record<CheckboxType, string> = {
    [CheckboxType.PRIMARY]: 'bg-white',
    [CheckboxType.SECONDARY]: 'bg-white',
    [CheckboxType.INVERSED]: 'bg-primary',
  };

  private inputCheckClasses: Record<CheckboxType, string> = {
    [CheckboxType.PRIMARY]: 'border border-primary bg-primary',
    [CheckboxType.SECONDARY]: 'border border-white bg-transparent',
    [CheckboxType.INVERSED]: 'border border-gray-1 bg-transparent',
  };

  private get inputIconClass(): string {
    return this.inputIconClasses[this.type];
  }

  private get inputClass(): string {
    const commonCls = "relative -top-px mr-2 flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center rounded shadow-none focus:shadow-none focus:ring-0 focus:ring-offset-0";

    if (this.redesigned) {
      return this.$options.filters?.merge(
        commonCls,
        'border-2 border-zinc-600',
        this.isChecked && this.inputCheckClasses[this.type]
      )
    }
    
    return this.isChecked
      ? this.$options.filters?.merge(commonCls, this.inputCheckClasses[this.type])
      : this.$options.filters?.merge(commonCls, this.inputClasses[this.type]);
  }

  private mounted() {
    this.cuid = cuid();
  }

  private setCheckboxVal() {
    this.$refs[this.id]!.click();
  }

  @Watch('value')
  private setValue(val: boolean) {
    this.isChecked = val;
  }

  @Emit('change')
  private onChange(val: any): boolean {
    val.preventDefault();
    this.isChecked = val.target.checked;
    return val.target.checked;
  }
}
