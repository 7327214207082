import { Component, Vue, Watch, getModule } from 'nuxt-property-decorator';
import { Snack } from '~/store/SnacksModule';
import SnacksModule from '~/store/SnacksModule';

@Component
export default class SnackbarMixin extends Vue {
  protected currentSnack: Snack | null = null;
  protected isSnackVisible: boolean = false;
  protected snacksModule!: SnacksModule;

  protected get snacks(): Snack[] {
    return this.$store.getters['SnacksModule/list'];
  }

  protected dispatch() {
    if (this.currentSnack === null && this.snacks.length > 0) {
      const snack = this.snacks.slice(0, 1).shift();
      this.snacksModule.shift();

      this.currentSnack = snack!;

      this.$nextTick(() => (this.isSnackVisible = true));
      setTimeout(this.clear.bind(this), 5000);
      setTimeout(this.dispatch.bind(this), 6000);
    }
  }

  protected clear() {
    this.isSnackVisible = false;
    setTimeout(() => (this.currentSnack = null), 500);
  }

  protected callback() {
    this.currentSnack?.action?.callback();
    this.clear();
  }

  created() {
    this.snacksModule = getModule(SnacksModule, this.$store);
  }

  @Watch('snacks')
  protected watch() {
    this.dispatch();
  }

  @Watch('$route')
  protected routeChanged() {
    this.clear();
    this.snacksModule.clearSnacks();
  }
}
