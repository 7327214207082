import { render, staticRenderFns } from "./NavbarDropdown.vue?vue&type=template&id=6cf0c40e"
import script from "./NavbarDropdown.vue?vue&type=script&lang=ts"
export * from "./NavbarDropdown.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/frontend/components/ui/loader/Loader.vue').default,Img: require('/frontend/components/ui/img/img.vue').default,Button: require('/frontend/components/ui/buttons/Button.vue').default})
