import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { plainToInstance } from 'class-transformer';
import { API_URL } from '~/constants/api-url';
import { Popup, VariantPopup, BannerPopup } from '~/models/popup';

export class PopupService {
  static getPopups(
    axios: NuxtAxiosInstance
  ): Promise<Popup[]> {
    return axios
      .get(API_URL.POPUPS.ROOT)
      .then(({ data }: { data: { results: Popup[] } }) => {
        return data.results.map((popup: Popup) => {

          const model = plainToInstance(Popup, popup).contentType === 'variant' ? VariantPopup : BannerPopup;
          // @ts-ignore
          return plainToInstance(model, popup) as unknown as Popup;
        });
      });

  }
}