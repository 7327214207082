
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import LinkTypeMixin from '~/mixins/link-type.mixin';
import NavbarItem from '~/models/navbarItem';

@Component({
  name: 'dn-navbar-item-mobile',
})
export default class NavbarItemMobile extends mixins(LinkTypeMixin) {
  @Prop({ type: Object, required: true }) private item!: NavbarItem;

  private get isLeaf(): boolean {
    return !this.item.dropdownData && !this.item.dropdownDataDynamicFn;
  }
}
