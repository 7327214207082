import {
  Module,
  VuexAction,
  VuexModule,
  VuexMutation,
} from 'nuxt-property-decorator';
import Vue from 'vue';
import { LeafletService } from '~/services/leaflet.service';
import Leaflet from '~/models/leaflet';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import File from '~/models/file';
import { WrongPageError } from '~/utils/custom-errors';
import LeafletData from '~/models/leaflet-data';
import LeafletsList from '~/models/leaflet-list';

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'LeafletModule',
})
export default class LeafletModule extends VuexModule {
  leaflets: LeafletData[] | null = null;
  currentLeaflet: {
    selectedPage: Leaflet | null;
    selectedGroup: Leaflet[] | null;
    pages: Leaflet[] | null;
    groupedPages: Leaflet[][] | null;
    totalCount: number | null;
    isGridVisible: boolean;
    pdf: File | null;
  } = {
    selectedPage: null,
    pages: null,
    selectedGroup: null,
    groupedPages: null,
    totalCount: 0,
    isGridVisible: false,
    pdf: null,
  };

  get alcoholLeaflets(): LeafletData[] {
    return !this.leaflets
      ? []
      : this.leaflets.filter((item: LeafletData) => item.isAlcohol);
  }

  get standardLeaflets(): LeafletData[] {
    return !this.leaflets
      ? []
      : this.leaflets.filter((item: LeafletData) => !item.isAlcohol);
  }

  get leafletsFiltered(): LeafletsList {
    return {
      alcohol: this.alcoholLeaflets,
      standard: this.standardLeaflets,
    };
  }

  get nextPage(): Leaflet | null {
    const currentPage = this.currentLeaflet.selectedPage?.no;

    if (
      currentPage &&
      currentPage !== 1 &&
      this.currentLeaflet.pages &&
      this.currentLeaflet.totalCount &&
      currentPage < this.currentLeaflet.totalCount
    ) {
      return this.currentLeaflet.pages[currentPage];
    }
    return null;
  }

  @VuexMutation
  SET_LEAFLETS(leaflets: LeafletData[]) {
    this.leaflets = leaflets;
  }

  @VuexMutation
  SET_PAGES(pages: Leaflet[] | null) {
    Vue.set(this.currentLeaflet, 'pages', pages);
  }

  @VuexMutation
  SET_GROUPED_PAGES(groupedPages: Leaflet[][] | null) {
    Vue.set(this.currentLeaflet, 'groupedPages', groupedPages);
  }

  @VuexMutation
  SET_SELECTED_PAGE(page: Leaflet | null) {
    Vue.set(this.currentLeaflet, 'selectedPage', page);
  }

  @VuexMutation
  SET_SELECTED_GROUP(group: Leaflet[] | null) {
    Vue.set(this.currentLeaflet, 'selectedGroup', group);
  }

  @VuexMutation
  SET_TOTAL_COUNT(totalCount: number) {
    Vue.set(this.currentLeaflet, 'totalCount', totalCount);
  }

  @VuexMutation
  SET_PDF_LINK(pdfLink: File | null) {
    Vue.set(this.currentLeaflet, 'pdf', pdfLink);
  }

  @VuexMutation
  SET_IS_GRID_VISIBLE(value: boolean) {
    Vue.set(this.currentLeaflet, 'isGridVisible', value);
  }

  @VuexAction({ rawError: true })
  async fetchLeaflets({ axios }: { axios: NuxtAxiosInstance }) {
    try {
      const { results } = await LeafletService.getLeaflet(axios);
      this.SET_LEAFLETS(results);
    } catch (err) {
      throw err;
    }
  }

  @VuexAction({ rawError: true })
  setGroupedPages(pages: Leaflet[]) {
    const groupsCount = Math.floor(
      (this.currentLeaflet.totalCount || 0) / 2 + 1
    );
    const groups = [];
    for (let i = 0; i < groupsCount; i++) {
      groups[i] =
        i === 0
          ? [pages[i * 2]]
          : i === groupsCount - 1 &&
            (this.currentLeaflet.totalCount || 0) % 2 === 0
          ? [pages[i * 2 - 1]]
          : [pages[i * 2 - 1], pages[i * 2]];
    }
    this.SET_GROUPED_PAGES(groups);
  }

  @VuexAction({ rawError: true })
  async setCurrentLeaflet({
    id,
    page,
    gridSize,
  }: {
    id: number;
    page: string;
    gridSize?: number;
  }) {
    const leaflet = this.leaflets?.find((item) => {
      return item.id == id;
    });
    if (!leaflet) {
      throw new WrongPageError();
    }
    const isGridWrongPage =
      gridSize &&
      !(+page >= 1 && +page <= Math.ceil(leaflet.pages.length / gridSize));
    const isLeafletWrongPage =
      !gridSize && (+page < 1 || +page > leaflet.pages.length);
    if (isGridWrongPage || isLeafletWrongPage) {
      throw new WrongPageError();
    }
    this.SET_PAGES(leaflet.pages);
    this.SET_PDF_LINK(leaflet.pdf);
    this.SET_TOTAL_COUNT(leaflet.pages.length);
    this.setGroupedPages(leaflet.pages);
    this.setPage(page);
  }

  @VuexAction({ rawError: true })
  async setPage(page: string) {
    const data = this.currentLeaflet.pages!.find((e: Leaflet) => {
      return e.no === +page;
    });
    const group =
      data && this.currentLeaflet.groupedPages
        ? this.currentLeaflet.groupedPages[Math.floor(data.no / 2)]
        : null;
    this.SET_SELECTED_PAGE(data || null);
    this.SET_SELECTED_GROUP(group);
  }

  @VuexAction({ rawError: true })
  async setGroup(group: string) {
    const data = this.currentLeaflet.groupedPages![+group - 1];
    const page = this.currentLeaflet.pages![(+group - 1) * 2];
    this.SET_SELECTED_GROUP(data);
    this.SET_SELECTED_PAGE(page);
  }

  @VuexAction({ rawError: true })
  async setGridVisibility(value: boolean) {
    this.SET_IS_GRID_VISIBLE(value);
  }

  @VuexAction({ rawError: true })
  clearCurrentLeaflet() {
    this.SET_PAGES(null);
    this.SET_GROUPED_PAGES(null);
    this.SET_PDF_LINK(null);
    this.SET_SELECTED_PAGE(null);
    this.SET_TOTAL_COUNT(0);
  }

  get firstPageNumber(): number {
    return this.currentLeaflet.pages![0].no;
  }

  get lastPageNumber(): number {
    return this.currentLeaflet.pages![this.currentLeaflet.pages!.length - 1].no;
  }

  get selectedGroupId(): number {
    return this.currentLeaflet.selectedPage && this.currentLeaflet.groupedPages
      ? Math.floor(this.currentLeaflet.selectedPage.no / 2)
      : 0;
  }
}
