
import { Component, Vue } from 'nuxt-property-decorator';

@Component({
  name: 'dn-sticky',
})
export default class Sticky extends Vue {
  private isDetached: boolean = false;
  private observer: IntersectionObserver | null = null;
  private lastScrollTop: number = 0;
  private scrollDirection: string = '';

  private mounted() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };

    this.observer = new IntersectionObserver(this.handleIntersect, options);

    const childElement = this.$refs.child as Element;
    if (childElement) {
      this.observer.observe(childElement);
    }

    this.scrollDirectionObserver();
  }

  private beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private handleIntersect(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      this.isDetached = !entry.isIntersecting;
    });
  }

  private shouldShow(): boolean {
    return this.scrollDirection === 'up' && !document.body.classList.contains('freeze');
  }
  
  private scrollDirectionObserver() {
    window.addEventListener('scroll', () => {
      const { scrollTop } = document.documentElement;
      if (scrollTop > this.lastScrollTop) {
        this.scrollDirection = 'down';
      } else {
        this.scrollDirection = 'up';
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    });
  }
}

