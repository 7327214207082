import { Expose, Type } from 'class-transformer';
import ProductSimple from '~/models/product-simple';
import 'reflect-metadata';
export default class DemoCategory {
  @Expose()
  id!: number;

  @Expose()
  name?: string;

  @Type(() => ProductSimple)
  products!: ProductSimple[];
}
