
import { Component, Prop, Vue } from 'nuxt-property-decorator';

export enum LoaderSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra_large',
}

@Component({
  name: 'dn-loader',
})
export default class Loader extends Vue {
  @Prop({ type: String, default: LoaderSizes.MEDIUM }) size?: LoaderSizes

  private get sizeCls() {
      return ({
        'w-[132px] h-[132px] lg:w-[180px] lg:h-[180px]': this.size === LoaderSizes.EXTRA_LARGE,
        'w-[110px] h-[110px] lg:w-[150px] lg:h-[150px]': this.size === LoaderSizes.LARGE,
        'w-[80px] h-[80px] lg:w-[120px] lg:h-[120px]': this.size === LoaderSizes.MEDIUM || !this.size,
        'w-[35px] h-[35px] lg:w-[50px] lg:h-[50px]': this.size === LoaderSizes.SMALL,
      })
  }

  mounted() {
    this.loadLottie('animation', '/lottie/koszyk_dino.json');
  }

  loadLottie(reference: string, path: any) {
    this.$lottie.loadAnimation({
      container: this.$refs[reference] as HTMLElement,
      prerender: true,
      loop: true,
      autoplay: true,
      path
    });
  }
}
