
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'nuxt-property-decorator';
import { cva, VariantProps } from 'class-variance-authority';

const button = cva(
  'inline-flex justify-center items-center rounded-full font-poppins transition-colors border border-transparent transition',
  {
    variants: {
      color: {
        default: '',
        secondary: '',
        red: '',
      },
      variant: {
        default: '',
        outline: '',
        ghost: '',
      },
      size: {
        default: 'space-x-1.5 py-2 px-3 text-xs font-medium md:space-x-2 md:py-2 md:px-4 md:text-base md:font-medium',
        mobile: 'space-x-1.5 py-2 px-3 text-xs font-medium',
      },
    },
    compoundVariants: [
      {
        color: 'default',
        variant: 'default',
        class:
          'bg-primary text-white hover:bg-primary-dark active:bg-primary-darker focus:bg-primary-darker disabled:opacity-40',
      },
      {
        color: 'secondary',
        variant: 'default',
        class:
          'bg-white text-zinc-600 hover:bg-zinc-100 hover:text-zinc-700 active:bg-zinc-200 active:text-zinc-700 focus:bg-zinc-200 focus:text-zinc-700 disabled:opacity-40',
      },
      {
        color: 'red',
        variant: 'default',
        class:
          'bg-red text-white hover:bg-red-dark focus:bg-red-darker active:bg-red-darker disabled:opacity-40',
      },
      {
        color: 'default',
        variant: 'outline',
        class:
          'border-primary bg-transparent text-primary hover:border-primary-dark hover:text-primary-dark hover:bg-primary-lighter active:border-primary-dark active:text-primary-dark active:bg-primary-light focus:border-primary-dark focus:text-primary-dark focus:bg-primary-light disabled:opacity-40',
      },
      {
        color: 'secondary',
        variant: 'outline',
        class:
          'bg-transparent text-zinc-600 border-zinc-300 hover:text-zinc-700 hover:bg-zinc-100 active:text-zinc-700 active:bg-zinc-200 active:border-zinc-400 focus:text-zinc-700 focus:bg-zinc-200 focus:border-zinc-400 disabled:opacity-40',
      },
      {
        color: 'red',
        variant: 'outline',
        class:
          'bg-transparent text-red border-red hover:text-red-dark hover:border-red-dark hover:bg-red-lighter active:text-red-darker active:bg-red-light active:border-red-darker focus:text-zinc-700 focus:text-red-darker focus:bg-red-light focus:border-red-darker disabled:opacity-40',
      },
      {
        color: 'default',
        variant: 'ghost',
        class:
          'bg-transparent text-primary hover:text-primary-dark hover:bg-primary-lighter active:text-primary-dark active:bg-primary-light focus:text-primary-dark focus:bg-primary-light disabled:opacity-40',
      },
      {
        color: 'secondary',
        variant: 'ghost',
        class:
          'bg-transparent text-zinc-600 hover:text-zinc-700 hover:bg-zinc-100 active:text-zinc-700 active:bg-zinc-200 focus:text-zinc-700 focus:bg-zinc-200 disabled:opacity-40',
      },
      {
        color: 'red',
        variant: 'ghost',
        class:
          'bg-transparent text-red hover:text-red-dark hover:bg-red-lighter active:text-red-darker active:bg-red-light focus:text-zinc-700 focus:text-red-darker focus:bg-red-light disabled:opacity-40',
      },
    ],
    defaultVariants: {
      color: 'default',
      variant: 'default',
      size: 'default',
    },
  },
);

@Component({
  name: 'dn-button',
  inheritAttrs: false,
})
export default class Button extends Vue {
  @Prop() variant: VariantProps<typeof button>['variant'];
  @Prop() color: VariantProps<typeof button>['color'];
  @Prop() size: VariantProps<typeof button>['size'];

  get classes() {
    return this.$options.filters?.merge(
      button({
        variant: this.variant,
        size: this.size,
        color: this.color,
        class: this.$vnode.data?.staticClass,
      }),
    );
  }

  get component() {
    if (this.$attrs.href) {
      return 'a';
    }

    if (this.$attrs.to) {
      return 'nuxt-link';
    }
    
    return 'button';
  }
}
