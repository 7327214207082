import { WidgetType } from '~/models/enums/widget-type';
import { ImageSize } from '~/models/enums/image-size';

export class WidgetImageSize {
  private isMobile!: boolean;
  private sizes!: Record<WidgetType, ImageSize>;

  constructor(isMobile: boolean) {
    this.isMobile = isMobile;
    this.setSizes();
  }

  private setElementSize(mobile: ImageSize, desktop: ImageSize) {
    return this.isMobile ? mobile : desktop;
  }

  private setSizes() {
    //TODO update when backend ready
    this.sizes = {
      [WidgetType.LEAFLET_WITH_BANNER_SLIDER]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.DESKTOP_SLIDER,
      ),
      [WidgetType.THREE_LEAFLET_WITH_BANNER_SLIDER]: this.setElementSize(
        ImageSize.MOBILE_MOVIE_BANNER,
        ImageSize.DESKTOP_MOVIE_BANNER,
      ),
      [WidgetType.BANNER_SINGLE]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.DESKTOP_BANNER,
      ),
      [WidgetType.BANNER_LIST_AUTO]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.DESKTOP_BANNER,
      ),
      [WidgetType.BANNER_LIST]: this.setElementSize(
        ImageSize.DESKTOP_SLIDER,
        ImageSize.DESKTOP_SLIDER,
      ),
      [WidgetType.BANNER_LIST_TWO_COLUMNS]: this.setElementSize(
        ImageSize.DESKTOP_BANNER_TWO_COLUMNS,
        ImageSize.MOBILE_BANNER_TWO_COLUMNS,
      ),
      [WidgetType.BANNER_LIST_FOUR_COLUMNS]: this.setElementSize(
        ImageSize.MOBILE_SQUARE_SMALL,
        ImageSize.DESKTOP_SQUARE_SMALL,
      ),
      [WidgetType.BANNER_CAROUSEL_FOUR_COLUMNS]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.MOBILE_BANNER,
      ),
      [WidgetType.PRODUCT_CAROUSEL]: this.setElementSize(
        ImageSize.DESKTOP_PRODUCT,
        ImageSize.DESKTOP_PRODUCT,
      ),
      [WidgetType.PRODUCT_GROUP_LIST]: this.setElementSize(
        ImageSize.DESKTOP_PRODUCT,
        ImageSize.DESKTOP_PRODUCT,
      ),
      [WidgetType.PRODUCT_GROUP_LIST_TAB]: this.setElementSize(
        ImageSize.DESKTOP_PRODUCT,
        ImageSize.DESKTOP_PRODUCT,
      ),
      [WidgetType.PRODUCT_GROUP_GRID_LIST]: this.setElementSize(
        ImageSize.DESKTOP_PRODUCT,
        ImageSize.DESKTOP_PRODUCT,
      ),
      [WidgetType.GRID_BANNERS_WITH_DESCRIPTION]: this.setElementSize(
        ImageSize.MOBILE_BANNER_TWO_COLUMNS,
        ImageSize.DESKTOP_BANNER_TWO_COLUMNS,
      ),
      [WidgetType.BANNER_LIST_NO_SPACE]: this.setElementSize(
        ImageSize.MOBILE_BANNER_NO_SPACE,
        ImageSize.DESKTOP_BANNER_NO_SPACE,
      ),
      [WidgetType.CATEGORY_TWO_COLUMNS_PROMO]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.DESKTOP_BANNER,
      ),
      [WidgetType.CATEGORY_FULL_WIDTH_PROMO]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.DESKTOP_BANNER,
      ),
      [WidgetType.CUSTOM_HTML]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.DESKTOP_BANNER,
      ),
      [WidgetType.VIDEO_WITH_PRODUCTS_SLIDER]: this.setElementSize(
        ImageSize.MOBILE_MOVIE_BANNER,
        ImageSize.DESKTOP_MOVIE_BANNER,
      ),
      [WidgetType.CONTACT_BOXES]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.DESKTOP_BANNER,
      ),
      [WidgetType.SLIDER_WITH_PRODUCT_GROUPS]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.DESKTOP_BANNER,
      ),
      [WidgetType.TWO_TILE_BANNER]: this.setElementSize(
        ImageSize.MOBILE_BANNER,
        ImageSize.DESKTOP_BANNER,
      ),
      [WidgetType.FOUR_TILE_BANNER]: this.setElementSize(
        ImageSize.DESKTOP_PRODUCT,
        ImageSize.DESKTOP_PRODUCT,
      ),
      [WidgetType.CONTEST]: this.setElementSize(
        ImageSize.DESKTOP_PRODUCT,
        ImageSize.DESKTOP_PRODUCT,
      ),
    };
  }

  getSize(): Record<WidgetType, ImageSize> {
    return this.sizes;
  }
}
