import { Component, getModule, Watch } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';
import Leaflet from '~/models/leaflet';
import LeafletModule from '~/store/LeafletModule';

@Component
export default class LeafletMixin extends DeviceMixin {
  currentPageNumber: number = 0;
  leafletModule!: LeafletModule;

  get totalPageNumber(): number {
    return this.$store.state.LeafletModule.currentLeaflet.totalCount!;
  }

  get totalGroupNumber(): number {
    return this.$store.state.LeafletModule.currentLeaflet.groupedPages.length;
  }

  get currentGroupId(): number {
    return this.$store.state.LeafletModule.currentLeaflet.selectedPage &&
      this.$store.state.LeafletModule.currentLeaflet.groupedPages
      ? Math.floor(
          this.$store.state.LeafletModule.currentLeaflet.selectedPage.no / 2
        )
      : 0;
  }

  get currentPagesNumbers(): number[] {
    return this.$store.state.LeafletModule.currentLeaflet.selectedGroup.map(
      (leaflet: Leaflet) => leaflet.no
    );
  }

  get isGridVisible(): boolean {
    return this.$store.state.LeafletModule.currentLeaflet.isGridVisible;
  }

  pageByGroupId(value: number) {
    return value <
      this.$store.state.LeafletModule.currentLeaflet.groupedPages.length
      ? this.$store.state.LeafletModule.currentLeaflet.groupedPages[value][0].no
      : '1';
  }

  created() {
    this.leafletModule = getModule(LeafletModule, this.$store);
    this.currentPageNumber = this.isMobile
      ? +this.$route.params.page
      : +this.$route.params.page - 1;
  }

  copyLink() {
    const textToCopy = window.location.toString();
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      return new Promise((res, rej) => {
        document.execCommand('copy') ? res(true) : rej();
        textArea.remove();
      });
    }
  }

  downloadPdf() {
    window.open(this.leafletModule.currentLeaflet.pdf!.url);
  }

  //TODO when api ready
  goToGrid() {
    if (!this.isGridVisible) {
      this.$router.push(
        this.localePath({
          name: 'leaflet-grid-id-page',
          params: { page: '1', id: this.$route.params.id },
        })
      );
    } else {
      this.$router.push(
        this.localePath({
          name: 'leaflet-id-page',
          params: {
            name: this.$route.params.name,
            page: '1',
            id: this.$route.params.id,
          },
        })
      );
    }

    this.leafletModule.setGridVisibility(!this.isGridVisible);
  }
}
