import { Expose, Type } from 'class-transformer';
import 'reflect-metadata';

export default class BadgeImg {
  @Expose()
  url!: string;

  @Expose({ name: 'mime_type' })
  mimeType!: string | number;

  @Expose({ name: 'mime_main' })
  mimeMain!: string;
}
