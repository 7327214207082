import { Expose, Type } from 'class-transformer';
import ServerFile from './file';

export default class Contest {
  @Expose({ name: 'date_from' })
  dateFrom!: string;

  @Expose({ name: 'date_to' })
  dateTo!: string;

  @Type(() => ServerFile)
  file!: ServerFile;

  @Expose({ name: 'is_active' })
  isActive!: boolean;

  @Expose({ name: 'redirect_url' })
  redirectUrl!: string | null;

  @Expose()
  slug!: string;
}
