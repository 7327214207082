import { Expose, Type } from 'class-transformer';
import Banner from '~/models/banner';
import ProductResults from '~/models/product-results';
import 'reflect-metadata';
import BannerWithDescription from './bannerWithDescription';
import ServerFile from './file';
import ProductGroup from './product-group';
import Contest from './contest';

export default class Widget {
  @Expose()
  id?: number;

  @Expose()
  title?: string;

  @Expose()
  description?: string;

  @Expose({ name: 'redirect_url' })
  redirectUrl!: string | null;

  @Expose()
  slug!: string;

  @Expose({ name: 'html_content' })
  htmlContent!: string | null;

  @Type(() => Banner)
  banners!: Banner[] | null;

  @Type(() => Banner)
  @Expose({ name: 'banner_files' })
  bannerFiles!: Banner[] | null;

  @Type(() => ServerFile)
  file?: ServerFile | null;

  @Type(() => BannerWithDescription)
  @Expose({ name: 'banners_with_description' })
  bannersWithDescription!: BannerWithDescription[] | null;

  @Expose()
  groups!: Widget[] | null;

  @Type(() => ProductResults)
  results!: ProductResults;

  @Type(() => ProductResults)
  @Expose({ name: 'group_products' })
  groupProducts!: ProductResults[] | null;

  @Type(() => ProductGroup)
  @Expose({ name: 'product_groups' })
  productGroups!: ProductGroup[] | null;

  @Expose({ name: 'date_from' })
  dateFrom?: string | null;

  @Expose({ name: 'date_to' })
  dateTo?: string | null;

  @Type(() => Contest)
  contests?: Contest[] | null;
}
