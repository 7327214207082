
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import FooterItem from '~/models/footer-item';

@Component({
  name: 'dn-footer-contact'
})
export default class FooterInfo extends Vue {
  @Prop({ type: String }) title?: string;
  @Prop({ type: Array, required: true }) data!: FooterItem[];
}
