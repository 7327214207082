var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.product.price.multibuy)?_c('div',{class:_vm.containerClasses},[(!_vm.hidePerUnit && _vm.product.price.pricePerUnit)?_c('span',{staticClass:"contents whitespace-nowrap"},[_vm._v("\n    ("+_vm._s(_vm.product.price.pricePerUnit)+")\n  ")]):_vm._e(),_vm._v(" "),(!_vm.hideDiscount && +_vm.product.price.oldPrice > +_vm.product.price.price)?_c('span',[_vm._v("\n    "+_vm._s(_vm.$t('BEFORE_DISCOUNT'))+"\n    "),_c('span',{class:_vm.priceClasses},[_vm._v("\n      "+_vm._s(_vm.priceFilter(
          _vm.product.price.oldPrice,
          _vm.product.price.omnibusPriceCurrency,
        ))+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.shouldDisplayOmnibus)?_c('span',{staticClass:"w-35"},[_vm._v("\n    "+_vm._s(_vm.$t('PRICE__LAST_LOWEST_PRICE'))+"\n    "),_c('span',{class:_vm.priceClasses},[_vm._v("\n      "+_vm._s(_vm.priceFilter(
          _vm.product.price.omnibusPrice,
          _vm.product.price.omnibusPriceCurrency,
        ))+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.product.price.isXyPromo)?_c('ProductPriceMultiple',{attrs:{"containerClass":_vm.XYcontainerClasses,"priceClass":_vm.priceClass,"small":_vm.small,"oneItemPrice":_vm.priceFilter(_vm.product.price.oldPrice, _vm.product.price.omnibusPriceCurrency),"fewItemsPrice":_vm.priceFilter(_vm.product.price.price, _vm.product.price.omnibusPriceCurrency),"amountPromotion":_vm.amountPromotion,"pricePerUnit":_vm.product.price.pricePerUnit,"pricePerUnitDefault":_vm.product.price.pricePerUnitDefault}}):_vm._e()],1):(_vm.product.price.multibuy)?_c('ProductPriceMultiple',{attrs:{"containerClass":_vm.containerClasses,"priceClass":_vm.priceClass,"small":_vm.small,"oneItemPrice":_vm.priceFilter(_vm.product.price.oldPrice, _vm.product.price.omnibusPriceCurrency),"fewItemsPrice":_vm.priceFilter(_vm.product.price.price, _vm.product.price.omnibusPriceCurrency),"amountPromotion":_vm.amountPromotion,"pricePerUnit":_vm.product.price.pricePerUnit,"pricePerUnitDefault":_vm.product.price.pricePerUnitDefault}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }