
import { NuxtError } from '@nuxt/types';
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import Error404 from '~/components/errors/Error404.vue';
import Error500 from '~/components/errors/Error500.vue';

@Component({
  name: 'error-page',
})
export default class ErrorPage extends Vue {
  @Prop()
  private error!: NuxtError | null;

  head() {
    return this.error?.statusCode || 'Error';
  }
}
