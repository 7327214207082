
import { Component, Emit, mixins } from 'nuxt-property-decorator';
import NewsletterModalMixin from '~/mixins/newsletter-modal.mixin';
import { StatusType } from '~/models/enums/status-type';
import { NewsletterFormModel } from '~/models/forms/newsletter-form';
import { Form } from '~/models/forms/reactive-form';
import { NewsletterModalStep } from '~/models/newsletter';
import { NewsletterService } from '~/services/newsletter.service';
import { setClientCookie } from '~/services/cookies.service';
import { cookieKeys } from '~/constants/cookie-keys';
import { NOTIFICATION_TYPE_ENUM } from '~/models/enums/notification-type.enum';
import { FooterMock } from '~/mocks/footer.mock';

@Component({
  name: 'dn-newsletter',
})
export default class Newsletter extends mixins(NewsletterModalMixin) {
  private form: Form<NewsletterFormModel> | null = null;
  private termsAndConditionsLink = FooterMock.getFooterData().sections[3].find((section) => section.title === 'Regulaminy')?.items?.find((item) => item.name === 'Regulamin')?.href || '';

  public static errorMsg(error: any, t: Function, context: object): string {
    if (error?.response?.data?.startsWith('Can not create/update contact because contact with the same extId/email/phone number already exists:'))  {
      return t.bind(context)('NEWSLETTER__ERROR_USER_EXISTS').toString() || '';
    }

    return t('NEWSLETTER__ERROR').toString() || '';
  }

  private created() {
    this.createForm();
  }

  private mounted() {
    this.initModalTimer();
  }

  private async createForm() {
    const newsletterFields = new NewsletterFormModel();
    newsletterFields.email = null;
    newsletterFields.regulationsAcceptance = null;
    this.form = new Form(newsletterFields);
  }

  private onSubmit(): void {
    if (!this.form) return;

    if (!this.form.fields.regulationsAcceptance || !this.form.fields.email) {
      // edgecase monkeypatch
      const email = this.form.fields?.email || (document.querySelector("input[id='email']") as HTMLInputElement)?.value;
      this.showNewsletterModal({ email, regulationsAcceptance: null }, { regulationsAcceptance: "Pole jest wymagane" });
    }

    this.submitHandler(this.form.fields);
  }

  private onError(errors: FormErrors, fields: any): void {
    this.showNewsletterModal(fields, errors)
  }

  private async submitHandler(value: NewsletterFormModel): Promise<void> {
    if (!value.email || !this.form) return;

    try {
      await NewsletterService.addSubscriber(this.$axios, {
        email: value.email,
      });

      this.showNewsletterModal(undefined, undefined, NewsletterModalStep.SUCCESS);
      setClientCookie(cookieKeys.NEWSLETTER_SUBSCRIBED, 'true', 'MAX');

      this.form.clearForm();
    } catch (err: any) {
      this.$notify(Newsletter.errorMsg(err, this.$t, this), NOTIFICATION_TYPE_ENUM.ERROR);
    }
  }
}
