import {
  Module,
  VuexAction,
  VuexMutation,
  VuexModule,
} from 'nuxt-property-decorator';

export interface SnackAction {
  text: string;
  callback: () => void;
}

export interface Snack {
  text: String;
  type?: String;
  action?: SnackAction;
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'SnacksModule',
})
export default class SnacksModule extends VuexModule {
  private snacks: Snack[] = [];

  get list() {
    return this.snacks;
  }

  @VuexMutation
  ADD_SNACK(value: Snack) {
    this.snacks.push(value);
  }

  @VuexMutation
  SHIFT_SNACK() {
    this.snacks.shift();
  }

  @VuexMutation
  CLEAR_SNACKS() {
    this.snacks = [];
  }

  @VuexAction({ commit: 'ADD_SNACK', rawError: true })
  add({
    text,
    action,
    type,
  }: {
    text: string;
    action?: SnackAction;
    type?: string;
  }): Snack {
    return { text, type, action };
  }

  @VuexAction({ commit: 'SHIFT_SNACK', rawError: true })
  shift() {
    return null;
  }

  @VuexAction({ commit: 'CLEAR_SNACKS', rawError: true })
  clearSnacks() {
    return null;
  }
}
