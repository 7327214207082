
import { Component, Watch, mixins } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';
import LeafletMixin from '~/mixins/lealfet.mixin';
import { StatusType } from '~/models/enums/status-type';

@Component({
  name: 'dn-leaflet-header',
})
export default class LeafletHeader extends mixins(DeviceMixin, LeafletMixin) {
  private closeLeaflet() {
    this.$router.push(this.localePath({ name: 'index' }));
  }

  private async changePage(pageNumber: string) {
    this.$router.push(
      this.localePath({
        name: 'leaflet-id-page',
        params: { page: pageNumber },
      })
    );
    this.currentPageNumber = +pageNumber - 1;
  }

  @Watch('$route.params.page', { deep: true })
  private setCurrentPage(currentValue: string, oldValue: string) {
    if (currentValue !== oldValue && !this.isGridVisible) {
      this.currentPageNumber = +currentValue - 1;
    }
  }

  @Watch('isGridVisible')
  private onGridVisibilityChange(currentValue: string) {
    if (!currentValue) {
      this.currentPageNumber = this.isMobile
        ? +this.$route.params.page
        : +this.$route.params.page - 1;
    }
  }

  private shareClickHandler() {
    this.copyLink().then(() => {
      this.$snack(
        this.$t('LEAFLET__LINK_COPIED').toString(),
        StatusType.SUCCESS
      );
    });
  }
}
