
import { Vue, Component } from 'nuxt-property-decorator';
import { cva, VariantProps } from 'class-variance-authority';
import cuid from 'cuid';
import Notification, { NotificationStyle } from '~/models/notifications';
import { NOTIFICATION_TYPE_ENUM } from '~/models/enums/notification-type.enum';
import { NOTIFICATION_SIZE_ENUM } from '~/models/enums/notification-size.enum';

const notificationMixer = cva(
  `relative flex items-center gap-2 md:gap-4 bg-white lg:rounded-tr-none mb-4 md:gap-4 w-[80vw] md:w-[506px] rounded-2xl notification-shadow
  h-min-14 md:h-min-[92px] py-2 md:py-4 px-4 md:px-6 
  font-normal text-xs leading-4
  `,
  {
    variants: {
      type: {
        [NOTIFICATION_TYPE_ENUM.SUCCESS]: 'shadow-primary',
        [NOTIFICATION_TYPE_ENUM.INFO]: 'shadow-info',
        [NOTIFICATION_TYPE_ENUM.WARNING]: 'shadow-warning',
        [NOTIFICATION_TYPE_ENUM.ERROR]: 'shadow-error',
      },
    },
    defaultVariants: {
      type: NOTIFICATION_TYPE_ENUM.SUCCESS,
    },
  }
);

const notificationIconMixer = cva(
    `grid place-items-center bg-zinc-100/20 color-zinc-600 rounded-lg
    w-6 h-6 md:w-10 md:h-10 p-1 md:p-2 min-w-6 min-h-6 md:min-w-10 md:min-h-10
    `,
    {
      variants: {
        type: {
          [NOTIFICATION_TYPE_ENUM.SUCCESS]: 'bg-primary/20',
          [NOTIFICATION_TYPE_ENUM.INFO]: 'bg-info/20',
          [NOTIFICATION_TYPE_ENUM.WARNING]: 'bg-warning/20',
          [NOTIFICATION_TYPE_ENUM.ERROR]: 'bg-error/20',
        },
      },
      defaultVariants: {
        type: NOTIFICATION_TYPE_ENUM.SUCCESS,
      },
    }
)

@Component({
  name: 'dn-notifications',
})
export default class Notifications extends Vue {
  notifications: Notification[] = [];

  private classes(notification: Notification) {
    return this.$options.filters?.merge(
      notificationMixer({
        type: notification.type,
      })
    );
  }

  private classesIcon(notification: Notification) {
    return this.$options.filters?.merge(
      notificationIconMixer({
        type: notification.type,
      })
    );
  }

  private icon(notification: Notification) {
    switch (notification.type) {
      case NOTIFICATION_TYPE_ENUM.SUCCESS:
        return require('~/assets/icons/notification/shield-trust-solid.svg');
      case NOTIFICATION_TYPE_ENUM.INFO:
        return require('~/assets/icons/notification/info-solid.svg');
      case NOTIFICATION_TYPE_ENUM.WARNING:
        return require('~/assets/icons/notification/triangle-warning-solid.svg');
      case NOTIFICATION_TYPE_ENUM.ERROR:
        return require('~/assets/icons/notification/hexagon-exclamation-solid.svg');
    }
  }

  addNotification(message: string, type: NOTIFICATION_TYPE_ENUM, style?: NotificationStyle, title?: string) {
    const id = cuid();
    this.notifications.push({ id, message, title, type, style });
    setTimeout(() => this.removeNotificationById(id), 5000);
  }

  removeNotification(index: number) {
    this.notifications.splice(index, 1);
  }

  removeNotificationById(id: string) {
    this.notifications = this.notifications.filter(notification => notification.id !== id);
  }
}
