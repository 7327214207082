import { render, staticRenderFns } from "./default.vue?vue&type=template&id=89bc58d4"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"
import style0 from "./default.vue?vue&type=style&index=0&id=89bc58d4&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Overlay: require('/frontend/components/ui/Overlay/Overlay.vue').default,Header: require('/frontend/components/layout/Header/Header.vue').default,ScrollToTopButton: require('/frontend/components/layout/common/ScrollToTopButton.vue').default,Newsletter: require('/frontend/components/newsletter/Newsletter.vue').default,Footer: require('/frontend/components/layout/Footer/Footer.vue').default,Notifications: require('/frontend/components/ui/notifications/Notifications.vue').default,PromotionalPopups: require('/frontend/components/promotional-popups/PromotionalPopups.vue').default})
