import { Expose, Transform, Type } from 'class-transformer';
import Banner from '~/models/banner';
import SimpleProducts from '~/models/simple-products';
import DemoCategory from '~/models/demo-category';
import 'reflect-metadata';
import ServerFile from './file';
import ProductResults from '~/models/product-results';

export default class Brand extends SimpleProducts {
  @Expose({ name: 'main_banner' })
  @Transform(({ value }) => (value?.file ? value : { file: value }))
  mainBanner!: Banner | null;

  @Expose({ name: 'demo_category' })
  @Type(() => DemoCategory)
  demoCategory!: DemoCategory[];

  @Expose()
  linkTo?: string;

  @Expose()
  name?: string;

  @Expose()
  photo?: ServerFile

  @Expose({ name: 'video_with_products' })
  @Type(() => ProductResults)
  videoWithProducts?: ProductResults;
}
