import LeafletResponse from '~/models/leaflet-response';
import { plainToInstance } from 'class-transformer';
import { API_URL } from '@/constants/api-url';
import { NuxtAxiosInstance } from '@nuxtjs/axios';

export class LeafletService {
  static getLeaflet(axios: NuxtAxiosInstance): Promise<LeafletResponse> {
    return axios.get(API_URL.LEAFLET.ROOT).then(({ data }) => {
      return plainToInstance(LeafletResponse, data);
    });
  }
}
