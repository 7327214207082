
import { Vue, Component, Emit, Prop } from 'nuxt-property-decorator';
import { StatusType } from '~/models/enums/status-type';
import { NewsletterFormModel } from '~/models/forms/newsletter-form';
import { Form } from '~/models/forms/reactive-form';
import { FooterMock } from '~/mocks/footer.mock';
import { NewsletterService } from '~/services/newsletter.service';
import { setClientCookie } from '~/services/cookies.service';
import { cookieKeys } from '~/constants/cookie-keys';
import { NewsletterModalStep } from '~/models/newsletter';
import Newsletter from '~/components/newsletter/Newsletter.vue';
import { NOTIFICATION_TYPE_ENUM } from '~/models/enums/notification-type.enum';

@Component({
  name: 'newsletter-modal',
})
export default class NewsletterModal extends Vue {
  @Prop({ type: String, required: false }) private initialStep?: NewsletterModalStep;
  @Prop({ type: Object, required: false }) fields?: NewsletterFormModel;
  @Prop({ type: Object, required: false }) errors?: FormErrors;

  private step = NewsletterModalStep.FORM;
  private form: Form<NewsletterFormModel> | null = null;
  private socialLinks = FooterMock.getFooterData().socialLinks.items;
  private termsAndConditionsLink = FooterMock.getFooterData().sections[3].find((section) => section.title === 'Regulaminy')?.items?.find((item) => item.name === 'Regulamin')?.href || '';
  private STEPS = NewsletterModalStep;

  private created() {
    this.createForm();
  }

  private mounted(): void {
    if (this.initialStep) {
      this.step = this.initialStep;
    }
  }

  private async createForm() {
    const formFields = new NewsletterFormModel();

    formFields.email = this.fields?.email || '';
    formFields.regulationsAcceptance = this.fields?.regulationsAcceptance || null;

    this.form = new Form(formFields, this.errors);
  }

  private async submitHandler(value: NewsletterFormModel): Promise<void> {
    if (!value.email || !this.form) return;

    try {
      await NewsletterService.addSubscriber(this.$axios, {
        email: value.email,
      });

      setClientCookie(cookieKeys.NEWSLETTER_SUBSCRIBED, 'true', 'MAX');
      this.setStep(NewsletterModalStep.SUCCESS);
      this.form.clearForm();
    } catch (err: any) {
      this.$notify(Newsletter.errorMsg(err, this.$t, this), NOTIFICATION_TYPE_ENUM.ERROR);
    }
  }

  private onSubmit(): void {
    if (!this.form) return;

    this.submitHandler(this.form.fields);
  }

  private setStep(step: NewsletterModalStep): void {
    this.step = step;
  }

  @Emit('close')
  private closeModal() {}
}
