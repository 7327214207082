var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative",class:_vm.wrapperCls},[_vm._t("default",null,{"props":{
      placeholder: _vm.formattedLabel,
      label: _vm.formattedLabel,
      isError: !!_vm.error,
      error: _vm.error,
    }}),_vm._v(" "),(!!_vm.error && _vm.defaultError)?_c('div',{staticClass:"flex items-center text-xs basis-full",class:{
      'max-h-0': !_vm.error,
      'max-h-[20px]': _vm.error,
    }},[_c('span',{staticClass:"text-error mt-1"},[_vm._v(" "+_vm._s(_vm.error))])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }