import { Expose } from 'class-transformer';

export default class Screen {
  @Expose()
  slug!: string;

  @Expose({ name: 'alcohol_info_html' })
  alcoholInfo!: string;

  @Expose({ name: 'are_prices_visible' })
  arePricesVisible!: boolean;
}
