import { render, staticRenderFns } from "./LoadingOverlay.vue?vue&type=template&id=451f6cf2"
import script from "./LoadingOverlay.vue?vue&type=script&lang=ts"
export * from "./LoadingOverlay.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/frontend/components/ui/loader/Loader.vue').default})
