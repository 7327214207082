import { API_URL } from '~/constants/api-url';
import Section from '~/models/section';
import Screen from '~/models/screen';
import { plainToInstance } from 'class-transformer';
import { NuxtAxiosInstance } from '@nuxtjs/axios';

export class ScreenService {
  static getScreen(axios: NuxtAxiosInstance, slug: string): Promise<Screen> {
    return axios.get(API_URL.SCREENS.SCREEN(slug)).then((res) => {
      return plainToInstance(Screen, res.data);
    });
  }

  static getScreenSections(
    axios: NuxtAxiosInstance,
    screen: string
  ): Promise<Section[]> {
    return axios
      .get(API_URL.SCREENS.ROOT, { params: { screen } })
      .then((res) => {
        return res.data.results.map((section: Section) => {
          return plainToInstance(Section, section);
        });
      });
  }
}
