import { Plugin } from '@nuxt/types';

import { SnackAction } from '~/store/SnacksModule';

declare module 'vue/types/vue' {
  export interface Vue {
    $snack(message: string, type?: string, action?: SnackAction): void;
  }
}

const snacks: Plugin = ({ store }, inject) => {
  inject('snack', (message: string, type?: string, action?: SnackAction) => {
    store.dispatch('SnacksModule/add', { text: message, type, action });
  });
};

export default snacks;
