
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Form from 'components/ui/form/Form.vue';

@Component({
  name: 'dn-form-control',
})
export default class FormControl extends Vue {
  $parent!: Vue['$parent'] & Form;
  @Prop({ type: Boolean, default: true }) defaultError?: boolean;
  @Prop({ type: String }) wrapperClass?: string;
  @Prop({ type: String }) label?: string;
  @Prop({ type: String }) rule?: string;
  @Prop({ type: Boolean, default: false }) isRequired!: boolean;

  private get formattedLabel(): string | undefined {
    if (!this.label) return;
    return `${this.label} ${this.isRequired ? '*' : ''}`;
  }

  private get error(): string | undefined {
    return this.$parent.form?.errors && this.rule
      ? this.$parent.form.errors[this.rule]
      : undefined;
  }

  private get wrapperCls(): string {
    return this.$options.filters?.merge(this.wrapperClass || '', this.error && 'hasError');
  }
}
