
import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
import Category from '~/models/category';
import LinkTypeMixin from '~/mixins/link-type.mixin';
import NavbarItemClass from '~/models/navbarItem';
import { StatusType } from '~/models/enums/status-type';
import { NOTIFICATION_TYPE_ENUM } from '~/models/enums/notification-type.enum';

@Component({
  name: 'dn-toolbar-item',
})
export default class NavbarItem extends mixins(LinkTypeMixin) {
  @Prop({ type: Object, required: true }) item!: NavbarItemClass;
  @Prop({ type: Array }) dropdownData!: Category[];

  private isLoading: boolean = false;
  private isOpen: boolean = false;

  @Watch('$route')
  private hideDropdown(event: Event) {
    if (!this.$el.contains(event.target as HTMLElement)) {
      this.isOpen = false;
    }
  }

  private isDropdown(item: NavbarItemClass): boolean {
    return !!item.dropdownData || !!item.dropdownDataDynamicFn;
  }

  private mounted() {
    document.addEventListener('click', this.hideDropdown);

    if (this.item.dropdownDataDynamicFn) {
      this.fetchDropdownData();
    }
  }

  private beforeDestroy() {
    document.removeEventListener('click', this.hideDropdown);
  }

  private async fetchDropdownData(): Promise<void> {
    this.isLoading = true;

    if (!this.item.dropdownDataDynamicFn) {
      return;
    }

    try {
      const data = await this.item.dropdownDataDynamicFn(this.$axios);

      if (data) {
        this.item.dropdownData = this.item.itemMapper? data.map((elem: any) => this.item.itemMapper?.(elem)) : data;
      }
    } catch(e) {
      this.$notify(JSON.stringify(e), NOTIFICATION_TYPE_ENUM.ERROR)
    } finally {
      this.isLoading = false;
    }
  }
}
