import { Expose, Type } from 'class-transformer';
import File from '~/models/file';
import 'reflect-metadata';
import Banner from './banner';

export default class BannerWithDescription {
  @Expose()
  title!: string;

  @Expose()
  description!: string | null;

  @Expose()
  banner!: Banner;
}
