
import { Component, mixins } from 'nuxt-property-decorator';
import ButtonMixin from '~/mixins/button.mixin';
import { ButtonSize } from '~/models/enums/button-size';

@Component({
  name: 'dn-round-button',
})
export default class RoundButton extends mixins(ButtonMixin) {
  buttonSizeClasses: any = {
    [ButtonSize.SMALL]: 'h-8 w-8 text-lg',
    [ButtonSize.MEDIUM]: 'h-10 w-10 text-2xl',
    [ButtonSize.LARGE]: 'h-12.5 w-12.5 text-2xl',
  };

  get buttonSizeClass(): string {
    return this.buttonSizeClasses[this.size];
  }
}
