
import { getModule, Component, Vue } from 'nuxt-property-decorator';

@Component({
  name: 'dn-promotional-popups',
})
export default class PromotionalPopups extends Vue {
  private get popups() {
    return this.$store.state.GlobalModule.fetchedPopups || [];
  }
}
