
import { mixins, Component, Watch } from 'nuxt-property-decorator';
import LeafletMixin from '~/mixins/lealfet.mixin';

@Component({
  name: 'dn-leaflet-mobile-footer',
})
export default class LeafletMobileFooter extends mixins(LeafletMixin) {
  private onChange(e: InputEvent) {
    let val = Number((e.target as HTMLInputElement).value)
    if(val <= 0 || isNaN(val)) val = 1;
    if (val >= this.totalPageNumber) val = this.totalPageNumber;
    this.$router.push(
        this.localePath({
          name: 'leaflet-id-page',
          params: {
            page: val.toString(),
          },
        })
      );
  }

  private get showPaging(): boolean {
    return (
      this.$route.path !==
      this.localePath({
        name: 'leaflet-grid-id-page',
      })
    );
  }

  @Watch('$route.params.page', { deep: true })
  private setCurrentPage() {
    this.currentPageNumber = +this.$route.params.page;
  }
}
