
import { Component, Prop, mixins } from 'nuxt-property-decorator';
import { plainToInstance } from 'class-transformer';
import Photo from '~/models/photo';
import ProductPrice from '~/models/product-price';
import { VariantPopup } from '~/models/popup';
import { ProductService } from '~/services/product.service';
import DeviceMixin from '~/mixins/device.mixin';
import { BADGES_TYPE } from '~/constants/badges';

@Component({
  name: 'dn-promotional-popup-variant-template',
})
export default class PromotionalPopupVariantTemplate extends mixins(DeviceMixin) {
  @Prop({ type: Object, required: true }) popup!: VariantPopup;

  private BADGES_TYPE = BADGES_TYPE;

  private get backgroundImage() {
    const photos = plainToInstance(Photo, this.popup.variant?.photos as Photo[]);
    return photos.find((photo) => photo?.backgroundImage);
  }

  private get productHeroImage() {
    const photos = plainToInstance(Photo, this.popup.variant?.photos as Photo[]);
    return photos.find((photo) => photo?.heroBanner === true) || this.popup.variant?.photos?.[0];
  }

  private handleShowOffer() {
    if (this.popup.variant?.slug) {
      this.$router.push(`/produkt/${this.popup.variant.slug}`);
    }

    this.$nuxt.$emit('close-popup', this.popup.id);
  }
}
