import { Expose, Type } from 'class-transformer';
import File from '~/models/file';
import 'reflect-metadata';

export default class Banner {
  @Expose()
  id?: number;

  @Expose()
  title!: string;

  @Expose()
  alt!: string;

  @Expose()
  slug!: string;

  @Type(() => File)
  file!: File;

  @Expose({ name: 'redirect_url' })
  redirectUrl?: string | null;

  @Expose()
  label?: string;

  @Expose()
  date?: string;

  @Expose({ name: 'date_from' })
  dateFrom?: string | null;

  @Expose({ name: 'date_to' })
  dateTo?: string | null;

  @Expose({ name: 'context' })
  context?: string;
}
