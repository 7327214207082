
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import { StatusType } from '~/models/enums/status-type';
import { Snack } from '~/store/SnacksModule';
import DeviceMixin from '~/mixins/device.mixin';

@Component({
  name: 'vos-snackbar',
})
export default class Snackbar extends mixins(DeviceMixin) {
  @Prop({ type: Object, default: null }) current!: Snack | null;
  @Prop({ type: Boolean, default: false }) visible!: boolean;

  private get bgColorClass() {
    if (!this.current?.type) return 'bg-black';
    return this.current?.type == StatusType.ERROR ? 'bg-red-700' : 'bg-primary';
  }
}
