/* eslint-disable import/no-mutable-exports */
import { Store } from 'vuex';
import { getModule } from 'nuxt-property-decorator';
import { IRootState } from '@/store/types';
import GlobalModule from '~/store/GlobalModule';
import ScreenModule from '~/store/ScreenModule';
import LeafletModule from '~/store/LeafletModule';
import ListingModule from '~/store/ListingModule';

let globalModule: GlobalModule;
let screenModule: ScreenModule;
let leafletModule: LeafletModule;
let listingModule: ListingModule;

function initialiseStores(store: Store<IRootState>): void {
  globalModule = getModule(GlobalModule, store);
  screenModule = getModule(ScreenModule, store);
  leafletModule = getModule(LeafletModule, store);
  listingModule = getModule(ListingModule, store);
}

export { initialiseStores, globalModule, screenModule, leafletModule, listingModule };
