var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._g({staticClass:"transform px-4 font-semibold tracking-wider transition-colors duration-100",class:[
    _vm.borderClass,
    _vm.displayClass,
    _vm.bgColorClass,
    _vm.textColorClass,
    _vm.textSizeClass,
    _vm.sizeClass,
    _vm.roundedClass,
    {
      'font-oswald uppercase': !_vm.lowerCase,
      'shadow-no-offset': _vm.shadow,
      'cursor-not-allowed opacity-70': _vm.disabled,
      'hover:shadow-hover': !_vm.noShadowHover,
    },
    _vm.overrideClass,
  ],attrs:{"aria-label":_vm.ariaLabel,"type":_vm.type,"disabled":_vm.disabled}},_vm.$listeners),[_c('div',{staticClass:"relative flex w-full items-center self-center"},[(!!_vm.$slots['prepend'])?[_c('div',{class:{ 'mr-3': _vm.label }},[_vm._t("prepend")],2)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-auto"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),(!!_vm.$slots['append'])?[_c('div',{class:{ 'ml-6': _vm.label }},[_vm._t("append")],2)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }