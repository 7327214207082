// @ts-nocheck
import NavbarItem from '~/models/navbarItem';
import { i18n } from '~/plugins/i18n';
import cuid from 'cuid';
import book from '/assets/icons/menu/book.svg';
import meat from '~/assets/icons/menu/meat.svg';
import percent from '~/assets/icons/menu/percent.svg';
import grocery from '~/assets/icons/menu/grocery.svg';
import newIcon from '~/assets/icons/menu/new.svg';
import vegetables from '~/assets/icons/menu/vegetables.svg';
import bread from '~/assets/icons/menu/bread.svg';
import flower from '~/assets/icons/menu/flower.svg';
import alcohol from '~/assets/icons/menu/alcohol.svg';
import beer from '~/assets/icons/menu/beer.svg';
import card from '~/assets/icons/menu/cards.svg';
import briefcase from '~/assets/icons/menu/briefcase.svg';
import prestige from '~/assets/icons/menu/prestige.svg';
import building from '~/assets/icons/menu/building.svg';
import handsHeart from '~/assets/icons/menu/hands-heart.svg';
import newsletter from '~/assets/icons/menu/newsletter.svg';
import messagesQuestion from '~/assets/icons/menu/messages-question.svg';
import facebook from '~/assets/icons/menu/facebook.svg';
import instagram from '/assets/icons/menu/instagram.svg';
import { BrandService } from '~/services/brands.service';
import Brand from '~/models/brand';

export const navbarItems = (): NavbarItem[] => {
  return [
    {
      id: cuid(),
      name: i18n.t('NAVBAR__LEAFLET').toString(),
      highlighted: false,
      icon: book,
      alt: i18n.t('NAVBAR__LEAFLET').toString(),
      showOnDesktop: true,
      mark: true,
      to: {
        name: 'leaflet-list-listTab',
        params: { listTab: 'standard' },
      },
    },
    {
      id: cuid(),
      name: i18n.t('NAVBAR__CHEAPER_NOW').toString(),
      alt: i18n.t('NAVBAR__CHEAPER_NOW').toString(),
      highlighted: false,
      icon: percent,
      showOnDesktop: true,
      to: {
        name: 'category-category',
        params: { category: 'teraz-taniej' },
      },
    },
    {
      id: cuid(),
      name: i18n.t('NAVBAR__OFFER').toString(),
      alt: i18n.t('NAVBAR__OFFER').toString(),
      highlighted: false,
      icon: grocery,
      showOnDesktop: true,
      dropdownData: [
        {
          id: cuid(),
          name: i18n.t('NAVBAR__NEWS').toString(),
          alt: i18n.t('NAVBAR__NEWS').toString(),
          icon: newIcon,
          to: { name: 'category-category', params: { category: 'nowosci' } },
        },
        {
          id: cuid(),
          name: i18n.t('NAVBAR__MEAT_AND_SOUSAGE').toString(),
          alt: i18n.t('NAVBAR__MEAT_AND_SOUSAGE').toString(),
          icon: meat,
          to: {
            name: 'category-category',
            params: { category: 'miesa-i-wedliny' },
          },
        },
        {
          id: cuid(),
          name: i18n.t('NAVBAR__VEGETABLES_AND_FRUITS').toString(),
          alt: i18n.t('NAVBAR__VEGETABLES_AND_FRUITS').toString(),
          icon: vegetables,
          to: {
            name: 'category-category',
            params: { category: 'owoce-i-warzywa' },
          },
        },
        {
          id: cuid(),
          name: i18n.t('NAVBAR__BREAD').toString(),
          alt: i18n.t('NAVBAR__BREAD').toString(),
          icon: bread,
          to: { name: 'category-category', params: { category: 'pieczywo' } },
        },
        {
          id: cuid(),
          name: i18n.t('NAVBAR__FLOWERS').toString(),
          alt: i18n.t('NAVBAR__FLOWERS').toString(),
          icon: flower,
          to: { name: 'category-category', params: { category: 'kwiaty' } },
        },
        {
          id: cuid(),
          name: i18n.t('NAVBAR__DINO_BAR').toString(),
          alt: i18n.t('NAVBAR__DINO_BAR').toString(),
          icon: alcohol,
          to: {
            name: 'category-category',
            params: { category: 'zakatek-alkoholi' },
          },
        },
        {
          id: cuid(),
          name: i18n.t('NAVBAR__BEAR_CORNER').toString(),
          alt: i18n.t('NAVBAR__BEAR_CORNER').toString(),
          icon: beer,
          to: {
            name: 'category-category',
            params: { category: 'zakatek-piwosza' },
          },
        },
      ],
    },
    {
      id: cuid(),
      name: i18n.t('NAVBAR__SERVICES').toString(),
      alt: i18n.t('NAVBAR__SERVICES').toString(),
      icon: card,
      showOnDesktop: true,
      highlighted: false,
      to: { name: 'services' },
    },
    {
      id: cuid(),
      name: i18n.t('NAVBAR__CAREER').toString(),
      alt: i18n.t('NAVBAR__CAREER').toString(),
      icon: briefcase,
      showOnDesktop: true,
      to: { name: 'career' },
      highlighted: false,
    },
    {
      id: cuid(),
      name: i18n.t('NAVBAR__OUR_BRANDS').toString(),
      alt: i18n.t('NAVBAR__OUR_BRANDS').toString(),
      icon: prestige,
      showOnDesktop: true,
      to: { name: 'brands' },
      highlighted: false,
      dropdownShowMore: { name: 'brands', id: cuid(), to: { name: 'brands' } },
      dropdownDataDynamicFn: BrandService.getBrands,
      itemMapper: (item: Brand) => ({
        id: cuid(),
        name: item.name || '',
        icon: item.photo?.url || '',
        highlighted: false,
        ...(item.linkTo ? { href: item.linkTo } : { to: ({ name: 'brands-slug', params: { slug: item.name } }) }),
        style: {
          bigItem: true,
        },
      }),
    },
    {
      id: cuid(),
      name: i18n.t('GROUP_DINO').toString(),
      alt: i18n.t('GROUP_DINO').toString(),
      icon: building,
      showOnDesktop: false,
      href: 'https://grupadino.pl/',
      highlighted: false,
    },
    {
      id: cuid(),
      name: i18n.t('NAVBAR__FOUNDATION').toString(),
      alt: i18n.t('NAVBAR__FOUNDATION').toString(),
      icon: handsHeart,
      showOnDesktop: false,
      to: { name: 'foundation' },
      highlighted: false,
    },
    {
      id: cuid(),
      name: i18n.t('NEWSLETTER').toString(),
      alt: i18n.t('NEWSLETTER').toString(),
      icon: newsletter,
      showOnDesktop: false,
      to: { name: 'index', hash: '#newsletter' },
      highlighted: false,
    },
    {
      id: cuid(),
      name: i18n.t('COMMON__CONTACT').toString(),
      alt: i18n.t('COMMON__CONTACT').toString(),
      icon: messagesQuestion,
      showOnDesktop: false,
      to: { name: 'contact' },
      highlighted: false,
    },
    {
      id: cuid(),
      name: i18n.t('FACEBOOK').toString(),
      alt: i18n.t('FACEBOOK').toString(),
      icon: facebook,
      showOnDesktop: false,
      href: 'https://www.facebook.com/dinonajblizejciebie/',
      highlighted: false,
    },
    {
      id: cuid(),
      name: i18n.t('INSTAGRAM').toString(),
      alt: i18n.t('INSTAGRAM').toString(),
      icon: instagram,
      showOnDesktop: false,
      href: 'https://www.instagram.com/dino__polska',
      highlighted: false,
    },
  ];
};
