import { plainToInstance } from 'class-transformer';
import { ImageSize } from '~/models/enums/image-size';
import { WidgetContext } from '~/models/enums/widget-context';
import Widget from '~/models/widget';
import Brand from '~/models/brand';
import { API_URL } from '~/constants/api-url';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { CUSTOM_BRANDS_URL } from '~/constants/brand';
import BrandByName from '~/models/brand-by-name';

export class BrandService {
  static getBrands(axios: NuxtAxiosInstance): Promise<Brand[]> {
    return axios.get(API_URL.BRANDS.ROOT).then((res) => {
      let brands = plainToInstance(Brand, res.data.results) as unknown as Brand[];

      brands = brands.map((brand) => ({
        ...brand,
        linkTo: CUSTOM_BRANDS_URL[brand.name as keyof typeof CUSTOM_BRANDS_URL] || brand.linkTo,
      }));

      return brands;
    });
  }

  static getBrand(
    axios: NuxtAxiosInstance,
    isMobile: boolean,
    slug: string,
  ): Promise<Brand> {
    return axios
      .get(API_URL.BRANDS.BRAND(slug), {
        params: {
          ...(isMobile && { context: WidgetContext.WEB_MOBILE }),
        },
      })
      .then((res) => {
        return plainToInstance(Brand, res.data);
      });
  }

  static getBrandVariants(
    axios: NuxtAxiosInstance, 
    name: string, 
    { offset = 0, limit = 12, context = WidgetContext.WEB_MOBILE, sort }: { offset: number, limit: number, context: WidgetContext, sort?: string }
  ): Promise<BrandByName> {
    return axios
      .get(API_URL.BRANDS.BY_NAME(name), {
        params: {
          offset,
          limit,
          context,
          sort,
        },
      })
      .then((res) => {
        return plainToInstance(BrandByName, res.data);
      });
  }

  static getBrandBanner(
    axios: NuxtAxiosInstance,
    slug: string,
    isMobile: boolean = false
  ): Promise<Widget> {
    const params = {
      ...(isMobile && { context: WidgetContext.WEB_MOBILE }),
      size: isMobile ? ImageSize.MOBILE_BANNER : ImageSize.DESKTOP_BANNER,
    };

    return axios.get(API_URL.WIDGETS.BANNER(slug), { params }).then((res) => {
      return plainToInstance(Widget, res.data);
    });
  }
}
