import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b4e40742 = () => interopDefault(import('../pages/contact-returns.vue' /* webpackChunkName: "pages/contact-returns" */))
const _660356fa = () => interopDefault(import('../pages/foundation.vue' /* webpackChunkName: "pages/foundation" */))
const _53acca88 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _590e882e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7e3c52f2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _056700b6 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _59c0df56 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _17b29165 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _5e693f3c = () => interopDefault(import('../pages/trigger-error.vue' /* webpackChunkName: "pages/trigger-error" */))
const _689514e5 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _67d54ec7 = () => interopDefault(import('../pages/business-offer/tabs.vue' /* webpackChunkName: "pages/business-offer/tabs" */))
const _6a3adaea = () => interopDefault(import('../pages/business-offer/tabs/plots.vue' /* webpackChunkName: "pages/business-offer/tabs/plots" */))
const _44a16343 = () => interopDefault(import('../pages/business-offer/tabs/space-rental.vue' /* webpackChunkName: "pages/business-offer/tabs/space-rental" */))
const _0bc00e42 = () => interopDefault(import('../pages/business-offer/tabs/suppliers.vue' /* webpackChunkName: "pages/business-offer/tabs/suppliers" */))
const _2a7da4d7 = () => interopDefault(import('../pages/career/join-us.vue' /* webpackChunkName: "pages/career/join-us" */))
const _28e5c7a8 = () => interopDefault(import('../pages/career/results.vue' /* webpackChunkName: "pages/career/results" */))
const _9986ae60 = () => interopDefault(import('../pages/contact-office.vue' /* webpackChunkName: "pages/contact-office" */))
const _4e795f7a = () => interopDefault(import('../pages/contact-suppliers.vue' /* webpackChunkName: "pages/contact-suppliers" */))
const _74d9844e = () => interopDefault(import('../pages/contact-plots.vue' /* webpackChunkName: "pages/contact-plots" */))
const _7dbb7338 = () => interopDefault(import('../pages/contact-rental.vue' /* webpackChunkName: "pages/contact-rental" */))
const _4001a77c = () => interopDefault(import('../pages/contact-plants.vue' /* webpackChunkName: "pages/contact-plants" */))
const _6d3bb334 = () => interopDefault(import('../pages/contact-infringement.vue' /* webpackChunkName: "pages/contact-infringement" */))
const _45b82b94 = () => interopDefault(import('../pages/contact-remark.vue' /* webpackChunkName: "pages/contact-remark" */))
const _62161512 = () => interopDefault(import('../pages/leaflet/list.vue' /* webpackChunkName: "pages/leaflet/list" */))
const _20ee5120 = () => interopDefault(import('../pages/leaflet/list/_listTab.vue' /* webpackChunkName: "pages/leaflet/list/_listTab" */))
const _62a37e62 = () => interopDefault(import('../pages/regulations/content/regulations-content.vue' /* webpackChunkName: "pages/regulations/content/regulations-content" */))
const _96d6a04a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _51f62940 = () => interopDefault(import('../pages/career/_slug.vue' /* webpackChunkName: "pages/career/_slug" */))
const _875434d4 = () => interopDefault(import('../pages/category/_category/index.vue' /* webpackChunkName: "pages/category/_category/index" */))
const _580a3e0e = () => interopDefault(import('../pages/brands/_slug.vue' /* webpackChunkName: "pages/brands/_slug" */))
const _170011e0 = () => interopDefault(import('../pages/offer/_slug.vue' /* webpackChunkName: "pages/offer/_slug" */))
const _2414abe6 = () => interopDefault(import('../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _54ceae8c = () => interopDefault(import('../pages/regulations/_regulation.vue' /* webpackChunkName: "pages/regulations/_regulation" */))
const _ff6dc7e2 = () => interopDefault(import('../pages/regulations/_regulation/index.vue' /* webpackChunkName: "pages/regulations/_regulation/index" */))
const _2d6ae1a7 = () => interopDefault(import('../pages/search/_search.vue' /* webpackChunkName: "pages/search/_search" */))
const _2c14642e = () => interopDefault(import('../pages/leaflet/grid/_id/_page.vue' /* webpackChunkName: "pages/leaflet/grid/_id/_page" */))
const _72f8cc50 = () => interopDefault(import('../pages/leaflet/_id/_page.vue' /* webpackChunkName: "pages/leaflet/_id/_page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact-returns",
    component: _b4e40742,
    name: "contact-returns___pl"
  }, {
    path: "/fundacja",
    component: _660356fa,
    name: "foundation___pl"
  }, {
    path: "/kariera",
    component: _53acca88,
    name: "career___pl"
  }, {
    path: "/kontakt",
    component: _590e882e,
    name: "contact___pl"
  }, {
    path: "/login",
    component: _7e3c52f2,
    name: "login___pl"
  }, {
    path: "/map",
    component: _056700b6,
    name: "map___pl"
  }, {
    path: "/marki-wlasne",
    component: _59c0df56,
    name: "brands___pl"
  }, {
    path: "/o-nas",
    component: _17b29165,
    name: "about-us___pl"
  }, {
    path: "/trigger-error",
    component: _5e693f3c,
    name: "trigger-error___pl"
  }, {
    path: "/uslugi",
    component: _689514e5,
    name: "services___pl"
  }, {
    path: "/business-offer/tabs",
    component: _67d54ec7,
    name: "business-offer-tabs___pl",
    children: [{
      path: "plots",
      component: _6a3adaea,
      name: "business-offer-tabs-plots___pl"
    }, {
      path: "space-rental",
      component: _44a16343,
      name: "business-offer-tabs-space-rental___pl"
    }, {
      path: "suppliers",
      component: _0bc00e42,
      name: "business-offer-tabs-suppliers___pl"
    }]
  }, {
    path: "/kariera/dolacz-do-nas",
    component: _2a7da4d7,
    name: "career-join-us___pl"
  }, {
    path: "/kariera/wyniki",
    component: _28e5c7a8,
    name: "career-results___pl"
  }, {
    path: "/kontakt/biuro-obslugi-klienta",
    component: _9986ae60,
    name: "contact-office___pl"
  }, {
    path: "/kontakt/dla-dostawcow",
    component: _4e795f7a,
    name: "contact-suppliers___pl"
  }, {
    path: "/kontakt/poszukujemy-gruntow",
    component: _74d9844e,
    name: "contact-plots___pl"
  }, {
    path: "/kontakt/wynajem-powierzchni",
    component: _7dbb7338,
    name: "contact-rental___pl"
  }, {
    path: "/kontakt/zamowienie-roslin",
    component: _4001a77c,
    name: "contact-plants___pl"
  }, {
    path: "/kontakt/zglos-naruszenie",
    component: _6d3bb334,
    name: "contact-infringement___pl"
  }, {
    path: "/kontakt/zglos-uwagi-pomysl",
    component: _45b82b94,
    name: "contact-remark___pl"
  }, {
    path: "/leaflet/list",
    component: _62161512,
    name: "leaflet-list___pl",
    children: [{
      path: "/gazetki/lista/:listTab",
      component: _20ee5120,
      name: "leaflet-list-listTab___pl"
    }]
  }, {
    path: "/regulations/content/regulations-content",
    component: _62a37e62,
    name: "regulations-content-regulations-content___pl"
  }, {
    path: "/",
    component: _96d6a04a,
    name: "index___pl"
  }, {
    path: "/kariera/:slug",
    component: _51f62940,
    name: "career-slug___pl"
  }, {
    path: "/kategoria/:category",
    component: _875434d4,
    name: "category-category___pl"
  }, {
    path: "/marki-wlasne/:slug",
    component: _580a3e0e,
    name: "brands-slug___pl"
  }, {
    path: "/oferta/:slug",
    component: _170011e0,
    name: "offer-slug___pl"
  }, {
    path: "/produkt/:slug",
    component: _2414abe6,
    name: "products-slug___pl"
  }, {
    path: "/regulations/:regulation?",
    component: _54ceae8c,
    children: [{
      path: "/regulaminy/:regulation",
      component: _ff6dc7e2,
      name: "regulations-regulation___pl"
    }]
  }, {
    path: "/wyszukiwarka/:query",
    component: _2d6ae1a7,
    name: "search-search___pl"
  }, {
    path: "/gazetka/:id/strony/:page",
    component: _2c14642e,
    name: "leaflet-grid-id-page___pl"
  }, {
    path: "/gazetka/:id/:page",
    component: _72f8cc50,
    name: "leaflet-id-page___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
