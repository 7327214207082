import {
  Module,
  VuexAction,
  VuexModule,
  VuexMutation,
} from 'nuxt-property-decorator';
import { WidgetService } from '~/services/widget.service';
import Section from '~/models/section';
import Screen from '~/models/screen';
import { WidgetImageSize } from '~/constants/widget-image-size';
import { WidgetType } from '~/models/enums/widget-type';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { ImageSize } from '~/models/enums/image-size';
import Vue from 'vue';
import { ScreenService } from '~/services/screen.service';
import { deleteProductsPrices } from '~/utils/delete-products-prices';
import { CategoryMock } from '~/mocks/category.mock';
import Widget from '~/models/widget';

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ScreenModule',
})
export default class ScreenModule extends VuexModule {
  sections: { [key: string]: Section[] } = {};
  screen: Screen | null = null;

  get getAlcoholInfo() {
    return this.screen ? this.screen.alcoholInfo : '';
  }

  get getIfPricesAreVisible() {
    return this.screen ? this.screen.arePricesVisible : true;
  }

  @VuexMutation
  SET_SECTIONS({ name, data }: { name: string; data: Section[] }) {
    Vue.set(this.sections, name, data);
  }

  @VuexMutation
  SET_SCREEN(data: Screen) {
    this.screen = data;
  }

  @VuexAction({ rawError: true })
  async fetchScreen({
    axios,
    name,
  }: {
    axios: NuxtAxiosInstance;
    name: string;
  }) {
    const data: Screen = await ScreenService.getScreen(axios, name);
    this.SET_SCREEN(data);
  }

  @VuexAction({ rawError: true })
  async fetchSectionsContent({
    axios,
    sections,
    isMobile,
    name,
    params = {},
    imageSize: passedImageSize,
  }: {
    axios: NuxtAxiosInstance;
    sections: Section[];
    isMobile: boolean;
    name: string;
    params?: { [key: string]: string | (string | null)[] };
    imageSize?: ImageSize;
  }) {
    const imageSize: Record<WidgetType, ImageSize> = new WidgetImageSize(
      isMobile,
    ).getSize();


    let data: Section[] = (
      await Promise.all(
        sections.map(async (section) => {
          try {

            let forcedParams = null;
            if (params.category) {
              forcedParams = params
            }

            
            let content = await WidgetService.getSingleWidget(
              axios,
              section.contentUrl,
              isMobile,
              passedImageSize || imageSize[section.widget],
              this.getIfPricesAreVisible ? undefined : deleteProductsPrices,
              forcedParams || [WidgetType.PRODUCT_GROUP_GRID_LIST, WidgetType.PRODUCT_GROUP_LIST_TAB].includes(section.widget) ? forcedParams || params : {},
            );

            if (content.redirectUrl === undefined) {
              content = {
                ...content,
                title: section.title,
                redirectUrl: section.redirectUrl,
                description: section.description,
              };
            }

            return {
              ...section,
              content,
            };
          } catch (e) {
            console.log(e);
          }
        }),
      )
    ).filter((e) => !!e) as Section[];

    if (name === 'contact') {
      // interpolate contact sections for now - some sections are fetched, some are hardcoded - will be refactored once proper CMS ready
      const sectionBanners = data.find((section) => section.slug === 'dla_przedsiebiorcow') as Section;
      const sectionDistributionCenters = data.find((section) => section.slug === 'centra-dustrynucji') as Section;

      data = [
        sectionBanners,
        {
          widget: WidgetType.CONTACT_BOXES,
          redirectUrl: '',
          redirectUrlText: '',
          contentId: '',
          contentType: NaN,
          contentUrl: '',
          dateFrom: '',
          dateTo: '',
          slug: '',
        },
        sectionDistributionCenters,
      ]
    }

    if (params.category === 'kwiaty') {
      // interpolate flowerpage sections for now - some sections are fetched, some are hardcoded - will be refactored once proper CMS ready
      const productsGrid = data.find((section) => section.slug === 'kwiaty-grid') as Section;
      const rest = data.filter((section) => ['kwiaty-grid'].includes(section.slug) === false) || [];

      data = [
        ...rest,
        CategoryMock.getFlowerPageSections()[0],
        CategoryMock.getFlowerPageSections()[1],
        productsGrid,
        CategoryMock.getFlowerPageSections()[2],
      ]
    }

    if (params.category === 'owoce-i-warzywa') {
      // interpolate owoce i warzywa sections for now - some sections are fetched, some are hardcoded - will be refactored once proper CMS ready
      data = [
        ...data,
        ...CategoryMock.getFruitsAndVegetablesPageSections(),
      ]
    }

    if (params.category === 'pieczywo') {
      // interpolate pieczywo sections for now - some sections are fetched, some are hardcoded - will be refactored once proper CMS ready
      data = [
        ...data,
        ...CategoryMock.getBakeryPageSections(),
      ]
    }

    return this.SET_SECTIONS({ name, data });
  }
}
