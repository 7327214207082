import { plainToInstance } from 'class-transformer';
import { API_URL } from '@/constants/api-url';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import TopBanner from '~/models/layout/top-banner';

export class LayoutService {
  static getTopBanner(
    axios: NuxtAxiosInstance
  ): Promise<TopBanner | undefined> {
    return axios.get(API_URL.LAYOUT.TOP_BANNERS).then(({ data }) => {
      const results = data.results;

      if (!results.length) return undefined;

      return plainToInstance(TopBanner, results[0]);
    });
  }
}
