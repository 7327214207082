import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { API_URL } from '~/constants/api-url';
import MetaInformationInterface from '~/models/meta-information.interface';


export class SeoService {
  static getMetaTags(axios: NuxtAxiosInstance): Promise<MetaInformationInterface[]> {
    return axios.get(API_URL.SEO.META_INFO).then((res) => {
      return res.data.results;
    });
  }
}
