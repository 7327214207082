import { Expose } from 'class-transformer';

export default class ProductPrice {
  @Expose()
  price!: string;

  @Expose()
  multibuy!: boolean;

  @Expose()
  unit!: string;

  @Expose({ name: 'green_text' })
  greenText!: string | null;

  @Expose({ name: 'is_xy_promo' })
  isXyPromo!: boolean | null;

  @Expose({ name: 'xy_promo' })
  xyPromoText!: string | null;

  @Expose({ name: 'multibuy_text' })
  multibuyText!: string | null;

  @Expose({ name: 'price_per_unit' })
  pricePerUnit!: string | null;

  @Expose({ name: 'price_per_unit_default' })
  pricePerUnitDefault!: string | null;

  @Expose({ name: 'omnibus_price' })
  omnibusPrice!: string | null;

  @Expose({ name: 'omnibus_price_currency' })
  omnibusPriceCurrency!: string | null;

  @Expose({ name: 'old_price' })
  oldPrice!: number | string;

  @Expose({ name: 'special_price_conditions' })
  specialPriceConditions!: number | string | null;

  @Expose({ name: 'reduction_value' })
  reductionValue!: string;

  @Expose({ name: 'price_currency' })
  priceCurrency!: number | string;

  @Expose({ name: 'old_price_currency' })
  oldPriceCurrency!: number | string;

  @Expose({ name: 'regular_price' })
  regularPrice!: number | string;

  @Expose()
  variant!: number;

  @Expose({ name: 'is_superprice' })
  isSuperPrice!: boolean;
}
