
import { Component, Prop, Vue, mixins } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';
import Banner from '~/models/banner';
import { BannerPopup } from '~/models/popup'

@Component({
  name: 'dn-promotional-popup-banner-template',
})
export default class PromotionalPopupBannerTemplate extends mixins(DeviceMixin) {
  @Prop({ type: Object, required: true }) popup!: BannerPopup;
  
  get banner() {
    return this.popup.bannerFiles.find((banner: Banner) => banner.context === (this.isMobile ? 'mobile' : 'desktop'));
  }
}
