import { render, staticRenderFns } from "./Newsletter.vue?vue&type=template&id=7703e88e"
import script from "./Newsletter.vue?vue&type=script&lang=ts"
export * from "./Newsletter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmailIcon: require('/frontend/components/ui/icons/EmailIcon.vue').default,Input: require('/frontend/components/ui/inputs/Input.vue').default,FormControl: require('/frontend/components/ui/form/FormControl.vue').default,CheckboxInput: require('/frontend/components/ui/inputs/CheckboxInput.vue').default,ArrowIcon: require('/frontend/components/ui/icons/ArrowIcon.vue').default,Button: require('/frontend/components/ui/buttons/Button.vue').default,Form: require('/frontend/components/ui/form/Form.vue').default})
