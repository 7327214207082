import { Component, Prop, Vue } from 'nuxt-property-decorator';
import NuxtLink from '~/models/nuxt-link';

@Component
export default class LinkTypeMixin extends Vue {
  @Prop({ type: [String, Object], default: undefined }) to?: String | NuxtLink;
  @Prop({ type: String, default: undefined }) href?: string;
  private get linkType(): string {
    if (this.to) {
      return 'nuxt-link';
    } else {
      return 'a';
    }
  }

  private get linkAttrs() {
    if (this.linkType === 'nuxt-link') {
      return { to: this.to ? this.localePath(this.to as NuxtLink) : '' };
    } else {
      return {
        href: this.href,
        target: '_blank',
      };
    }
  }
}
