var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative w-full flex flex-col items-start gap-2"},[(_vm.label)?_c('label',{class:_vm.labelClasses,attrs:{"for":_vm.input}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n    "),(_vm.optional)?_c('span',{staticClass:"font-light text-zinc-500"},[_vm._v("("+_vm._s(_vm.$t('COMMON__OPTIONAL'))+")")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.$slots.icon)?_c('span',{staticClass:"absolute start-4 top-2.5 z-1"},[_vm._t("icon")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative w-full"},[_c('input',{class:[
        {
          'focus:border-primary focus:ring-primary': _vm.isFocused && !_vm.secondary,
          'focus:border-white focus:ring-white': _vm.isFocused && _vm.secondary,
          'bg-zinc-100 cursor-not-allowed': _vm.disabled,
          'pl-12': !!_vm.$slots.icon,
          '!pr-12': _vm.unit,
        },
        _vm.isError && !_vm.isFocused ? _vm.inputErrorClass : '',
        _vm.inputClasses,
      ],attrs:{"type":_vm.inputType,"name":_vm.input,"id":_vm.input,"placeholder":_vm.placeholder === undefined ? _vm.label : _vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.onBlur($event.target.value)},"focus":function($event){return _vm.onFocus($event.target.value)},"input":function($event){return _vm.onChange($event.target.value)}}}),_vm._v(" "),(_vm.type === 'password')?_c('div',{staticClass:"icon absolute h-8 w-8 top-0 bottom-0 my-auto right-3 flex justify-center items-center rounded-full cursor-pointer hover:bg-gray-3 text-xl",class:{ 'icon-home': _vm.showPassword, 'icon-eye': !_vm.showPassword },on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_vm._e(),_vm._v(" "),(_vm.unit)?_c('div',{staticClass:"absolute top-0 bottom-0 right-0 grid place-items-center h-full pl-4 pr-6 bg-white border border-zinc-300 rounded-r-full",class:{
        '!border-red-500': _vm.isError,
        '!border-primary': _vm.isFocused,
      }},[_vm._v("\n      "+_vm._s(_vm.unit)+"\n    ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }