
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import NavbarItem from '~/models/navbarItem';

@Component({
  name: 'dn-navbar',
})
export default class Navbar extends Vue {
  @Prop({ type: Array, required: true }) items!: NavbarItem[];
}
