export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"pl","iso":"pl-PL","file":"pl-PL.json"}],
  defaultLocale: "pl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":false},
  differentDomains: false,
  baseUrl: "",
  vuex: false,
  parsePages: false,
  pages: {"about-us":{"pl":"/o-nas"},"contact":{"pl":"/kontakt"},"contact-office":{"pl":"/kontakt/biuro-obslugi-klienta"},"foundation":{"pl":"/fundacja"},"services":{"pl":"/uslugi"},"regulations/_regulation/index":{"pl":"/regulaminy/:regulation"},"products/_slug":{"pl":"/produkt/:slug"},"offer/_slug":{"pl":"/oferta/:slug"},"category/_category/index":{"pl":"/kategoria/:category"},"leaflet/list/_listTab":{"pl":"/gazetki/lista/:listTab"},"leaflet/_id/_page":{"pl":"/gazetka/:id/:page"},"leaflet/grid/_id/_page":{"pl":"/gazetka/:id/strony/:page"},"brands/_slug":{"pl":"/marki-wlasne/:slug"},"brands/index":{"pl":"/marki-wlasne"},"career/_slug":{"pl":"/kariera/:slug"},"career/index":{"pl":"/kariera"},"career/join-us":{"pl":"/kariera/dolacz-do-nas"},"career/results":{"pl":"/kariera/wyniki"},"contact-suppliers":{"pl":"/kontakt/dla-dostawcow"},"contact-plots":{"pl":"/kontakt/poszukujemy-gruntow"},"contact-rental":{"pl":"/kontakt/wynajem-powierzchni"},"contact-remark":{"pl":"/kontakt/zglos-uwagi-pomysl"},"contact-infringement":{"pl":"/kontakt/zglos-naruszenie"},"contact-plants":{"pl":"/kontakt/zamowienie-roslin"},"search/_search":{"pl":"/wyszukiwarka/:query"}},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"pl","iso":"pl-PL","file":"pl-PL.json"}],
  localeCodes: ["pl"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "p",
  3: "l",
  4: "-",
  5: "P",
  6: "L",
  7: ".",
  8: "j",
  9: "s",
  10: "o",
  11: "n",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: "l",
  19: "o",
  20: "c",
  21: "a",
  22: "l",
  23: "e",
  24: "s",
  25: "/",
  26: "p",
  27: "l",
  28: "-",
  29: "P",
  30: "L",
  31: ".",
  32: "j",
  33: "s",
  34: "o",
  35: "n",
  36: "\"",
  37: "}",
}

export const localeMessages = {
  'pl-PL.json': () => import('../../locales/pl-PL.json' /* webpackChunkName: "lang-pl-PL.json" */),
}
