
import { Component, mixins, Prop, Vue } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';
import { setClientCookie, HOURS_3_IN_SECONDS } from '~/services/cookies.service';

@Component({
  name: 'dn-dialog',
})
export default class Dialog extends mixins(DeviceMixin) {
  @Prop({ type: [String, Number] })
  private id!: string | number;

  @Prop({ type: String })
  private width!: string;

  @Prop({ type: String })
  private title!: string | null;

  @Prop({ type: Function })
  private component!: Vue;

  @Prop({ type: Object })
  private props!: { [key: string]: any };

  @Prop()
  private events!: { [key: string]: (...events: any[]) => void };

  @Prop({ type: Boolean })
  private showClose!: boolean;

  @Prop({ type: Boolean })
  private closeOnOutsideClick!: boolean;

  @Prop({ type: Boolean })
  private rounded!: boolean;

  @Prop({ type: String })
  private dialogClasses!: string;

  @Prop({ type: String })
  private contentClasses!: string;

  @Prop({ type: String })
  private closeBtnClasses!: string;

  @Prop({ type: String })
  private overlayClasses!: string;

  @Prop({ type: Boolean })
  private mobileFullscreen!: boolean;

  @Prop({ type: Boolean })
  private mobileDisplayAsDrawer!: boolean;

  @Prop({ type: Boolean })
  private blurOut!: boolean;

  private get dialogCls(): string {
    return this.$options.filters?.merge(
      'dialog absolute left-1/2 top-1/2 max-h-screen w-full -translate-x-1/2 -translate-y-1/2 transform bg-inversed md:pb-16 animate-appear',
      {
        'rounded-xl': this.rounded && !this.isFullscreen,
        'flex min-h-full flex-col': this.isFullscreen,
        'pb-8 w-[calc(100%-32px)]': !this.isFullscreen,
      },
      this.dialogClasses,
    )
  }

  private get likeDrawerCls(): string {
    return this.$options.filters?.merge('like-drawer', this.dialogClasses);
  }

  private get contentCls(): string {
    const screenSizeClasses = this.isFullscreen ? 'dialog-content-full' : 'dialog-content'

    return this.$options.filters?.merge(screenSizeClasses, "overflow-y-auto px-6 pt-3 md:px-10 md:pt-0", this.contentClasses || '');
  }
  
  private get isFullscreen(): boolean {
    return this.isMobile && this.mobileFullscreen;
  }

  mounted() {
    document.querySelector('#app')!.appendChild(this.$el);
    this.freezeScroll();

    this.$nuxt.$on('close-popup', this.handleClosePopup)
  }

  beforeDestroy() {
    this.$el.remove();
    this.$nuxt.$on('close-popup', this.handleClosePopup)
  }

  destroy() {
    this.unfreezeScroll();
    this.$destroy();
  }

  freezeScroll(): void {
    document.querySelector('body')!.style.setProperty('--saved-scroll-position', `-${window.scrollY}px`);
    setTimeout(() => {
      // to let window.scrollBy() to be executed first (see dialog.ts plugin)
      document.querySelector('body')!.classList.add('freeze');
    }, 50);
  }

  unfreezeScroll(): void {
    const body = document.querySelector('body') as HTMLElement;
    const position = Math.abs(parseInt(body!.style.getPropertyValue('--saved-scroll-position')))
    body!.classList.remove('freeze');
    window.scrollTo(0, position);
  }

  handleClosePopup(popupId: string | number) {
    this.close();
  }

  markDismissed() {
    if (!this.id) {
      return;
    }
  
    setClientCookie(`popup-${this.id}-dismissed`, 'true', HOURS_3_IN_SECONDS);
  }

  close() {
    this.markDismissed();

    if (this.events.close) {
      this.events.close();
      this.destroy();
    } else {
      this.destroy();
    }
  }
}
