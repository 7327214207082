import { Expose, Type } from 'class-transformer';
import ProductPrice from '@/models/product-price';
import ProductData from '~/models/product-data';
import 'reflect-metadata';
import ProductBanner from './product-banner';
import Photo from '~/models/photo';

export default class Product {
  @Expose()
  id!: number;

  @Expose()
  photos!: (ProductBanner[] & Photo[]) | null;

  @Expose()
  slug!: string;

  @Expose()
  @Type(() => ProductPrice)
  price!: ProductPrice | null;

  @Expose()
  @Type(() => ProductData)
  details!: ProductData;

  @Expose({ name: 'general_info' })
  generalInfo!: string[] | {}[];

  @Expose()
  backgroundImage?: string

  @Expose()
  videoBackground?: string;

  @Expose()
  mobileVideoBackground?: string;

  //TODO remove after components changes
  promotionalText?: { firstLine: string; secondLine: string };
  manufacturer?: string;
  quantity?: number;
  unitText?: string;

  @Expose()
  videoUrl?: string;

  @Expose()
  mobileVideoUrl?: string;

  @Expose()
  redirectUrl?: string;
}
