import { Expose, Type } from 'class-transformer';
import 'reflect-metadata';
import File from '~/models/file';

export default class Photo {
  @Expose()
  id!: number;

  @Expose()
  alt!: string;

  @Expose()
  @Type(() => File)
  file!: File;

  @Expose({ name: 'background_image' })
  @Type(() => File)
  backgroundImage!: boolean;

  @Expose({ name: 'disable_on_product_page' })
  disableOnProductPage?: boolean;

  @Expose({ name: 'hero_banner' })
  heroBanner!: boolean;

  @Expose({ name: 'main' })
  main!: boolean;
}
