import { render, staticRenderFns } from "./leaflet.vue?vue&type=template&id=63de4b83"
import script from "./leaflet.vue?vue&type=script&lang=ts"
export * from "./leaflet.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LeafletHeader: require('/frontend/components/leaflet/sub/LeafletHeader.vue').default,LeafletMobileFooter: require('/frontend/components/leaflet/sub/LeafletMobileFooter.vue').default,Snackbar: require('/frontend/components/ui/snackbar/Snackbar.vue').default})
