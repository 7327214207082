import { extend, localize } from 'vee-validate';
import {
  required,
  email,
  max,
  min,
  confirmed,
  numeric,
  alpha_spaces,
} from 'vee-validate/dist/rules';
import pl from 'vee-validate/dist/locale/pl.json';
import en from 'vee-validate/dist/locale/en.json';
import { Context } from '@nuxt/types';
import { validatePostalCode } from '~/utils/validators/postal-code-validator';

export default function ({ app: { i18n } }: Context) {
  extend('required', required);
  extend('required-true', { validate: value => !!value });
  extend('email', {
    ...email,
    ...{
      message: _ => i18n.tc('VALIDATION__EMAIL_INVALID'),
    },
  });
  extend('max', {
    ...max,
    ...{
      message: (fieldName, { length }) =>
        i18n.tc('VALIDATION__MAX_LENGTH_INVALID', undefined, [
          fieldName,
          length,
        ]),
    },
  });
  extend('min', {
    ...min,
    ...{
      message: (fieldName, { length }) =>
        i18n.tc('VEE_VALIDATE__MIN', undefined, { fieldName, length }),
    },
  });
  extend('confirmed', confirmed);
  extend('confirmed_password', {
    ...confirmed,
    ...{
      message: (fieldName, { target }) =>
        i18n.tc('VALIDATION__CONFIRM_FIELD_INVALID', undefined, [
          target,
          fieldName,
        ]),
    },
  });
  extend('numeric', numeric);
  extend('phone', {
    message: fieldName => {
      return i18n.tc('VEE_VALIDATE__PHONE', undefined, { fieldName });
    },
    validate: async value => {
      const PhoneNumber = (await import('awesome-phonenumber')).default;
      const phone = new PhoneNumber(value);
      return {
        valid: phone.isValid(),
      };
    },
  });
  extend('postal_code', {
    message: () => {
      return i18n.tc('VALIDATION__POSTAL_CODE_INVALID');
    },
    validate: value => {
      return {
        valid: validatePostalCode(value, 'PL'),
      };
    },
    params: [
      {
        name: 'countryCode',
        isTarget: true,
      },
    ],
  });
  extend('alpha_spaces', {
    ...alpha_spaces,
    ...{
      message: fieldName =>
        i18n.tc('VEE_VALIDATE__ALPHA_SPACES', undefined, { fieldName }),
    },
  });

  localize('en', en);
  localize('pl', pl);
}
