
import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator';

@Component({
  name: 'dn-modal-header',
})
export default class ModalHeader extends Vue {
  @Prop({ type: String }) title?: string;

  @Emit('close')
  onClose(): void {}
}
