
import { Vue, Component, Model, Prop, Emit } from 'nuxt-property-decorator';

@Component({
  name: 'dn-input',
})
export default class Input extends Vue {
  @Model('input', { type: String }) value!: string;

  @Prop({ type: String, default: 'text' }) type!: string;
  @Prop({ type: String, required: true }) name!: string;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: '' }) placeholder!: string;
  @Prop({ type: String, default: '' }) helper!: string;
  @Prop({ type: String, default: '' }) containerClass!: string;
  
  private isFocused: boolean = false;

  private showPassword: boolean = false;

  private get containerClasses() {
    return this.$options.filters?.merge(
      'flex items-center space-x-2 rounded-full overflow-hidden bg-white h-10 px-4 border',
      this.$attrs.isError
        ? 'border-red mb-3'
        : this.isFocused
          ? 'border-zinc-900'
          : 'border-zinc-300',
      this.$vnode.data?.staticClass,
    );
  }

  private get inputType() {
    return this.showPassword ? 'text' : this.$attrs.type;
  }

  @Emit('blur')
  private onBlur() {
    this.isFocused = false;
  }

  @Emit('focus')
  private onFocus() {
    this.isFocused = true;
  }
}
