import { Expose } from 'class-transformer';
export default class ServerFile {
  @Expose({ name: 'mime_type' })
  mimeType!: string;

  @Expose({ name: 'mime_maintype' })
  mimeMainType!: string;

  @Expose()
  url!: string;

  @Expose()
  file?: string;
}
