import FooterSection from '~/models/footer-section';
import cuid from 'cuid';

export class FooterMock {
  static getFooterData(): {
    sections: FooterSection[][];
    appLinks: FooterSection;
    socialLinks: FooterSection;
    contact: FooterSection;
    openingHours: FooterSection;
  } {
    return data;
  }
}

// TODO add links 'to', verify when api ready
const data: {
  sections: FooterSection[][];
  appLinks: FooterSection;
  socialLinks: FooterSection;
  contact: FooterSection;
  customerServiceOffice: FooterSection;
  openingHours: FooterSection;
} = {
  sections: [
    [
      {
        id: cuid(),
        title: 'Gazetka',
        items: [
          {
            id: cuid(),
            name: 'W tym tygodniu',
            to: {
              name: 'leaflet-list-listTab',
              params: { listTab: 'standard' },
            },
          },
        ],
      },
      {
        id: cuid(),
        title: 'Dino',
        items: [
          { id: cuid(), name: 'O nas', to: { name: 'about-us' } },
          { id: cuid(), name: 'Kariera', to: { name: 'career' } },
          { id: cuid(), name: 'Fundacja', to: { name: 'foundation' } },
          { id: cuid(), name: 'Grupa Dino', href: 'https://grupadino.pl/' },
        ],
      },
    ],
    [
      {
        id: cuid(),
        title: 'Oferta',
        items: [
          {
            id: cuid(),
            name: 'Teraz taniej',
            to: {
              name: 'category-category',
              params: { category: 'teraz-taniej' },
            },
          },
          {
            id: cuid(),
            name: 'Nowości',
            to: { name: 'category-category', params: { category: 'nowosci' } },
          },
          {
            id: cuid(),
            name: 'Mięso i wędliny',
            to: {
              name: 'category-category',
              params: { category: 'miesa-i-wedliny' },
            },
          },
          {
            id: cuid(),
            name: 'Warzywa i owoce',
            to: {
              name: 'category-category',
              params: { category: 'owoce-i-warzywa' },
            },
          },
          {
            id: cuid(),
            name: 'Pieczywo',
            to: { name: 'category-category', params: { category: 'pieczywo' } },
          },
          {
            id: cuid(),
            name: 'Kwitnące okazje',
            to: { name: 'category-category', params: { category: 'kwiaty' } },
          },
          {
            id: cuid(),
            name: 'Zakątek alkoholi',
            to: {
              name: 'category-category',
              params: { category: 'zakatek-alkoholi' },
            },
          },
          {
            id: cuid(),
            name: 'Zakątek piwosza',
            to: {
              name: 'category-category',
              params: { category: 'zakatek-piwosza' },
            },
          },
          {
            id: cuid(),
            name: 'Nasze marki',
            to: {
              name: 'brands',
            },
            decorator: true,
          },
          {
            id: cuid(),
            name: 'Usługi',
            to: { name: 'services' },
          },
        ],
      },
    ],
    [
      {
        id: cuid(),
        title: 'Kontakt',
        items: [
          {
            id: cuid(),
            name: 'Główna strona kontaktowa',
            to: { name: 'contact' },
            decorator: true,
          },
          {
            id: cuid(),
            name: 'Reklamacje',
            // to: { name: 'contact-returns' },
            href: '/docs/FORMULARZ_REKLAMACYJNY_03_02_25.pdf',
          },
          {
            id: cuid(),
            name: 'Biuro obsługi klienta',
            to: { name: 'contact-office' },
          },
          {
            id: cuid(),
            name: 'Dla dostawców',
            to: { name: 'contact-suppliers' },
          },
          {
            id: cuid(),
            name: 'Poszukujemy gruntów',
            to: { name: 'contact-plots' },
          },
          {
            id: cuid(),
            name: 'Wynajem powierzchni',
            to: { name: 'contact-rental' },
          },
          {
            id: cuid(),
            name: 'Zgłoś uwagi/pomysły',
            to: { name: 'contact-remark' },
          },
          {
            id: cuid(),
            name: 'Zgłoś naruszenie',
            to: { name: 'contact-infringement' },
          },
          {
            id: cuid(),
            name: 'Zamówienie roślin',
            to: { name: 'contact-plants' },
          },
        ],
      },
    ],
    [
      {
        id: cuid(),
        title: 'Regulaminy',
        items: [
          {
            id: cuid(),
            name: 'Polityka Prywatności',
            to: {
              name: 'regulations-regulation',
              params: { regulation: 'rodo' },
            },
          },
          {
            id: cuid(),
            name: 'Regulamin',
            href: '/docs/Regulamin_strony_internetowej_www_marketdino_pl_v6.pdf',
          },
          {
            id: cuid(),
            name: 'Cookies',
            to: {
              name: 'regulations-regulation',
              params: { regulation: 'cookies' },
            },
          },
        ],
      },
      {
        id: cuid(),
        title: 'Bądź na bieżąco',
        items: [
          {
            id: cuid(),
            name: 'Facebook',
            href: 'https://www.facebook.com/dinonajblizejciebie/',
          },
          {
            id: cuid(),
            name: 'Instagram',
            href: 'https://www.instagram.com/dino__polska',
          },
        ],
      },
    ],
  ],
  appLinks: {
    title: 'Aplikacja',
    items: [
      // {
      //   id: cuid(),
      //   name: 'Google Play',
      //   img: '/images/google-play.svg',
      //   to: {},
      // },
      // { id: cuid(), name: 'App Store', img: '/images/app-store.svg', to: {} },
    ],
  },
  socialLinks: {
    title: 'Bądź na bieżąco',
    items: [
      {
        id: cuid(),
        name: 'Facebook',
        icon: require('~/assets/img/facebook.svg'),
        href: 'https://www.facebook.com/dinonajblizejciebie/',
      },
      {
        id: cuid(),
        name: 'Instagram',
        icon: require('~/assets/img/instagram.svg'),
        href: 'https://www.instagram.com/dino__polska',
      },
      // { id: cuid(), name: 'YouTube', icon: 'icon-youtube', to: {} },
    ],
  },
  contact: {
    title: 'Skontaktuj się z nami',
    items: [
      {
        id: cuid(),
        icon: 'icon-phone',
        name: '(+48) 62 72 55 400',
      },
      {
        id: cuid(),
        icon: 'icon-mail',
        name: 'sekretariat@marketdino.pl',
      },
    ],
  },
  customerServiceOffice: {
    title: 'Biuro Obsługi Klienta',
    items: [
      {
        id: cuid(),
        icon: 'icon-phone',
        name: '(+48) 62 72 13 416',
      },
      {
        id: cuid(),
        icon: 'icon-mail',
        name: 'bok@marketdino.pl',
      },
    ],
  },
  openingHours: {
    title: 'Markety Dino godziny otwarcia:',
    items: [
      {
        id: cuid(),
        name: 'Poniedziałek - Sobota 6:00 - 22:30',
      },
      {
        id: cuid(),
        name: 'Niedziela handlowa 8:30 - 20:00',
      },
      {
        id: cuid(),
        name: '<p class="text-xs font-normal mt-4 text-zinc-600 whitespace-normal">*wybrane markety otwarte w krótszych niż standardowe godzinach. Szczegóły w marketach Dino.</p>',
      },
    ],
  },
};
