import {
  Module,
  VuexAction,
  VuexModule,
  VuexMutation,
} from 'nuxt-property-decorator';
import { Popup } from '~/models/popup';

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'GlobalModule',
})
export default class GlobalModule extends VuexModule {
  isMobilePopupVisible: boolean = false;
  isPopupVisible: boolean = false;
  isOverlayVisible: boolean = false;
  isMainOverlayVisible: boolean = false;
  fetchedPopups: Popup[] = [];

  get getIsPopupVisible(): boolean {
    return this.isPopupVisible;
  }

  @VuexMutation
  SET_IS_POPUP_VISIBLE(payload: boolean) {
    this.isPopupVisible = payload;
  }

  @VuexMutation
  SET_IS_MOBILE_POPUP_VISIBLE(payload: boolean) {
    this.isMobilePopupVisible = payload;
  }

  @VuexMutation
  SET_IS_OVERLAY_VISIBLE(payload: boolean) {
    this.isOverlayVisible = payload;
  }

  @VuexMutation
  SET_IS_MAIN_OVERLAY_VISIBLE(payload: boolean) {
    this.isMainOverlayVisible = payload;
  }

  @VuexMutation
  async SET_FETCHED_POPUPS(payload: Popup[]) {
    this.fetchedPopups = payload;
  }

  @VuexAction({ rawError: true })
  async setIsPopupVisible(payload: boolean) {
    this.SET_IS_POPUP_VISIBLE(payload);
  }

  @VuexAction({ rawError: true })
  async setIsMobilePopupVisible(payload: boolean) {
    this.SET_IS_MOBILE_POPUP_VISIBLE(payload);
  }

  @VuexAction({ rawError: true })
  async setIsOverlayVisible(payload: boolean) {
    this.SET_IS_OVERLAY_VISIBLE(payload);
  }

  @VuexAction({ rawError: true })
  async setIsMainOverlayVisible(payload: boolean) {
    this.SET_IS_MAIN_OVERLAY_VISIBLE(payload);
  }

  @VuexAction({ rawError: true })
  async setFetchedPopups(payload: Popup[]) {
    this.SET_FETCHED_POPUPS(payload);
  }
}
