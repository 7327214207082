import { Expose, Type } from 'class-transformer';

import Product from '~/models/product';

import 'reflect-metadata';

export default class ProductGroupWithUrl {
  @Expose()
  name!: string;

  @Expose({ name: 'content_url' })
  contentUrl!: string;
}
