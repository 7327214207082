export const CategoryHeader = () => import('../../components/category/CategoryHeader.vue' /* webpackChunkName: "components/category-header" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/business-offer/contact-form.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const PlantsForm = () => import('../../components/business-offer/plants-form.vue' /* webpackChunkName: "components/plants-form" */).then(c => wrapFunctional(c.default || c))
export const PlotsForm = () => import('../../components/business-offer/plots-form.vue' /* webpackChunkName: "components/plots-form" */).then(c => wrapFunctional(c.default || c))
export const RemarkForm = () => import('../../components/business-offer/remark-form.vue' /* webpackChunkName: "components/remark-form" */).then(c => wrapFunctional(c.default || c))
export const RentsForm = () => import('../../components/business-offer/rents-form.vue' /* webpackChunkName: "components/rents-form" */).then(c => wrapFunctional(c.default || c))
export const ReturnsForm = () => import('../../components/business-offer/returns-form.vue' /* webpackChunkName: "components/returns-form" */).then(c => wrapFunctional(c.default || c))
export const SuppliersForm = () => import('../../components/business-offer/suppliers-form.vue' /* webpackChunkName: "components/suppliers-form" */).then(c => wrapFunctional(c.default || c))
export const ElementPlaceholder = () => import('../../components/containers/ElementPlaceholder.vue' /* webpackChunkName: "components/element-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PageWrapper = () => import('../../components/containers/PageWrapper.vue' /* webpackChunkName: "components/page-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ContactFormLinks = () => import('../../components/contact/contact-form-links.vue' /* webpackChunkName: "components/contact-form-links" */).then(c => wrapFunctional(c.default || c))
export const Drawer = () => import('../../components/drawer/Drawer.vue' /* webpackChunkName: "components/drawer" */).then(c => wrapFunctional(c.default || c))
export const Error404 = () => import('../../components/errors/Error404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c))
export const Error500 = () => import('../../components/errors/Error500.vue' /* webpackChunkName: "components/error500" */).then(c => wrapFunctional(c.default || c))
export const Foundation = () => import('../../components/foundation/Foundation.vue' /* webpackChunkName: "components/foundation" */).then(c => wrapFunctional(c.default || c))
export const PlayIcon = () => import('../../components/icons/PlayIcon.vue' /* webpackChunkName: "components/play-icon" */).then(c => wrapFunctional(c.default || c))
export const LeafletGrid = () => import('../../components/leaflet/LeafletGrid.vue' /* webpackChunkName: "components/leaflet-grid" */).then(c => wrapFunctional(c.default || c))
export const LeafletPage = () => import('../../components/leaflet/LeafletPage.vue' /* webpackChunkName: "components/leaflet-page" */).then(c => wrapFunctional(c.default || c))
export const LoadingOverlay = () => import('../../components/loading-overlay/LoadingOverlay.vue' /* webpackChunkName: "components/loading-overlay" */).then(c => wrapFunctional(c.default || c))
export const Newsletter = () => import('../../components/newsletter/Newsletter.vue' /* webpackChunkName: "components/newsletter" */).then(c => wrapFunctional(c.default || c))
export const PromotionalPopups = () => import('../../components/promotional-popups/PromotionalPopups.vue' /* webpackChunkName: "components/promotional-popups" */).then(c => wrapFunctional(c.default || c))
export const ScrollableContainer = () => import('../../components/scrollable-container/ScrollableContainer.vue' /* webpackChunkName: "components/scrollable-container" */).then(c => wrapFunctional(c.default || c))
export const Services = () => import('../../components/services/Services.vue' /* webpackChunkName: "components/services" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/auth/LoginForm/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const RegisterForm = () => import('../../components/auth/RegisterForm/RegisterForm.vue' /* webpackChunkName: "components/register-form" */).then(c => wrapFunctional(c.default || c))
export const HeaderAlkoholContent = () => import('../../components/category/contents/HeaderAlkoholContent.vue' /* webpackChunkName: "components/header-alkohol-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderBakeryContent = () => import('../../components/category/contents/HeaderBakeryContent.vue' /* webpackChunkName: "components/header-bakery-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderBeerContent = () => import('../../components/category/contents/HeaderBeerContent.vue' /* webpackChunkName: "components/header-beer-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderFlowerContent = () => import('../../components/category/contents/HeaderFlowerContent.vue' /* webpackChunkName: "components/header-flower-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderFruitsAndVeggiesContent = () => import('../../components/category/contents/HeaderFruitsAndVeggiesContent.vue' /* webpackChunkName: "components/header-fruits-and-veggies-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderMeatContent = () => import('../../components/category/contents/HeaderMeatContent.vue' /* webpackChunkName: "components/header-meat-content" */).then(c => wrapFunctional(c.default || c))
export const AlcoholAlertDialog = () => import('../../components/dialogs/alcohol-alert/AlcoholAlertDialog.vue' /* webpackChunkName: "components/alcohol-alert-dialog" */).then(c => wrapFunctional(c.default || c))
export const NewsletterModal = () => import('../../components/dialogs/newsletter-modal/NewsletterModal.vue' /* webpackChunkName: "components/newsletter-modal" */).then(c => wrapFunctional(c.default || c))
export const FoundationSection = () => import('../../components/foundation/sub/FoundationSection.vue' /* webpackChunkName: "components/foundation-section" */).then(c => wrapFunctional(c.default || c))
export const BasicList = () => import('../../components/job-openings/common/BasicList.vue' /* webpackChunkName: "components/basic-list" */).then(c => wrapFunctional(c.default || c))
export const MultipleSelection = () => import('../../components/job-openings/common/MultipleSelection.vue' /* webpackChunkName: "components/multiple-selection" */).then(c => wrapFunctional(c.default || c))
export const TreeList = () => import('../../components/job-openings/common/TreeList.vue' /* webpackChunkName: "components/tree-list" */).then(c => wrapFunctional(c.default || c))
export const JobOffers = () => import('../../components/job-openings/job-offers/JobOffers.vue' /* webpackChunkName: "components/job-offers" */).then(c => wrapFunctional(c.default || c))
export const JoinUs = () => import('../../components/job-openings/join-us/JoinUs.vue' /* webpackChunkName: "components/join-us" */).then(c => wrapFunctional(c.default || c))
export const JobCategories = () => import('../../components/job-openings/job-categories/JobCategories.vue' /* webpackChunkName: "components/job-categories" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/layout/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/layout/Header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/layout/common/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const Preloader = () => import('../../components/layout/common/Preloader.vue' /* webpackChunkName: "components/preloader" */).then(c => wrapFunctional(c.default || c))
export const ScrollToTopButton = () => import('../../components/layout/common/ScrollToTopButton.vue' /* webpackChunkName: "components/scroll-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../components/list/List/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const ListHeader = () => import('../../components/list/ListHeader/ListHeader.vue' /* webpackChunkName: "components/list-header" */).then(c => wrapFunctional(c.default || c))
export const ListHeaderFiltersForm = () => import('../../components/list/ListHeaderFiltersForm/ListHeaderFiltersForm.vue' /* webpackChunkName: "components/list-header-filters-form" */).then(c => wrapFunctional(c.default || c))
export const ListTopSection = () => import('../../components/list/ListTopSection/ListTopSection.vue' /* webpackChunkName: "components/list-top-section" */).then(c => wrapFunctional(c.default || c))
export const PaginationMini = () => import('../../components/list/PaginationMini/PaginationMini.vue' /* webpackChunkName: "components/pagination-mini" */).then(c => wrapFunctional(c.default || c))
export const LeafletGridItem = () => import('../../components/leaflet/sub/LeafletGridItem.vue' /* webpackChunkName: "components/leaflet-grid-item" */).then(c => wrapFunctional(c.default || c))
export const LeafletHeader = () => import('../../components/leaflet/sub/LeafletHeader.vue' /* webpackChunkName: "components/leaflet-header" */).then(c => wrapFunctional(c.default || c))
export const LeafletListItem = () => import('../../components/leaflet/sub/LeafletListItem.vue' /* webpackChunkName: "components/leaflet-list-item" */).then(c => wrapFunctional(c.default || c))
export const LeafletListRow = () => import('../../components/leaflet/sub/LeafletListRow.vue' /* webpackChunkName: "components/leaflet-list-row" */).then(c => wrapFunctional(c.default || c))
export const LeafletMobileFooter = () => import('../../components/leaflet/sub/LeafletMobileFooter.vue' /* webpackChunkName: "components/leaflet-mobile-footer" */).then(c => wrapFunctional(c.default || c))
export const LeafletRangeSlider = () => import('../../components/leaflet/sub/LeafletRangeSlider.vue' /* webpackChunkName: "components/leaflet-range-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductBasketCard = () => import('../../components/products/ProductCard/ProductBasketCard.vue' /* webpackChunkName: "components/product-basket-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/products/ProductCard/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardHorizontal = () => import('../../components/products/ProductCard/ProductCardHorizontal.vue' /* webpackChunkName: "components/product-card-horizontal" */).then(c => wrapFunctional(c.default || c))
export const ProductCardSimple = () => import('../../components/products/ProductCard/ProductCardSimple.vue' /* webpackChunkName: "components/product-card-simple" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/products/ProductDetails/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductsList = () => import('../../components/products/ProductsList/ProductsList.vue' /* webpackChunkName: "components/products-list" */).then(c => wrapFunctional(c.default || c))
export const ProductsListSimple = () => import('../../components/products/ProductsList/ProductsListSimple.vue' /* webpackChunkName: "components/products-list-simple" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/products/common/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const ProductAlcoholMessage = () => import('../../components/products/common/ProductAlcoholMessage.vue' /* webpackChunkName: "components/product-alcohol-message" */).then(c => wrapFunctional(c.default || c))
export const ProductAttribute = () => import('../../components/products/common/ProductAttribute.vue' /* webpackChunkName: "components/product-attribute" */).then(c => wrapFunctional(c.default || c))
export const ProductBadges = () => import('../../components/products/common/ProductBadges.vue' /* webpackChunkName: "components/product-badges" */).then(c => wrapFunctional(c.default || c))
export const ProductDiscount = () => import('../../components/products/common/ProductDiscount.vue' /* webpackChunkName: "components/product-discount" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/products/common/ProductGallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductImage = () => import('../../components/products/common/ProductImage.vue' /* webpackChunkName: "components/product-image" */).then(c => wrapFunctional(c.default || c))
export const ProductLabel = () => import('../../components/products/common/ProductLabel.vue' /* webpackChunkName: "components/product-label" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/products/common/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceDisplay = () => import('../../components/products/common/ProductPriceDisplay.vue' /* webpackChunkName: "components/product-price-display" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceView = () => import('../../components/products/common/ProductPriceView.vue' /* webpackChunkName: "components/product-price-view" */).then(c => wrapFunctional(c.default || c))
export const ProductPromotionalText = () => import('../../components/products/common/ProductPromotionalText.vue' /* webpackChunkName: "components/product-promotional-text" */).then(c => wrapFunctional(c.default || c))
export const ProductRelated = () => import('../../components/products/common/ProductRelated.vue' /* webpackChunkName: "components/product-related" */).then(c => wrapFunctional(c.default || c))
export const ProductXYPromo = () => import('../../components/products/common/ProductXYPromo.vue' /* webpackChunkName: "components/product-x-y-promo" */).then(c => wrapFunctional(c.default || c))
export const PromotionalPopup = () => import('../../components/promotional-popups/sub/PromotionalPopup.vue' /* webpackChunkName: "components/promotional-popup" */).then(c => wrapFunctional(c.default || c))
export const VideoSlide = () => import('../../components/sliders/VideoSlide/VideoSlide.vue' /* webpackChunkName: "components/video-slide" */).then(c => wrapFunctional(c.default || c))
export const ServiceSection = () => import('../../components/services/sub/ServiceSection.vue' /* webpackChunkName: "components/service-section" */).then(c => wrapFunctional(c.default || c))
export const Overlay = () => import('../../components/ui/Overlay/Overlay.vue' /* webpackChunkName: "components/overlay" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/ui/breadcrumbs/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/ui/buttons/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/ui/buttons/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const DropdownButton = () => import('../../components/ui/buttons/DropdownButton.vue' /* webpackChunkName: "components/dropdown-button" */).then(c => wrapFunctional(c.default || c))
export const RoundButton = () => import('../../components/ui/buttons/RoundButton.vue' /* webpackChunkName: "components/round-button" */).then(c => wrapFunctional(c.default || c))
export const Dialog = () => import('../../components/ui/dialogs/Dialog.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../components/ui/form/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const FormControl = () => import('../../components/ui/form/FormControl.vue' /* webpackChunkName: "components/form-control" */).then(c => wrapFunctional(c.default || c))
export const ArrowIcon = () => import('../../components/ui/icons/ArrowIcon.vue' /* webpackChunkName: "components/arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const EmailIcon = () => import('../../components/ui/icons/EmailIcon.vue' /* webpackChunkName: "components/email-icon" */).then(c => wrapFunctional(c.default || c))
export const EyeOffIcon = () => import('../../components/ui/icons/EyeOffIcon.vue' /* webpackChunkName: "components/eye-off-icon" */).then(c => wrapFunctional(c.default || c))
export const ShareIcon = () => import('../../components/ui/icons/ShareIcon.vue' /* webpackChunkName: "components/share-icon" */).then(c => wrapFunctional(c.default || c))
export const ImageWrapper = () => import('../../components/ui/image-wrapper/ImageWrapper.vue' /* webpackChunkName: "components/image-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Img = () => import('../../components/ui/img/img.vue' /* webpackChunkName: "components/img" */).then(c => wrapFunctional(c.default || c))
export const Autocomplete = () => import('../../components/ui/inputs/Autocomplete.vue' /* webpackChunkName: "components/autocomplete" */).then(c => wrapFunctional(c.default || c))
export const CheckboxInput = () => import('../../components/ui/inputs/CheckboxInput.vue' /* webpackChunkName: "components/checkbox-input" */).then(c => wrapFunctional(c.default || c))
export const DropdownInput = () => import('../../components/ui/inputs/DropdownInput.vue' /* webpackChunkName: "components/dropdown-input" */).then(c => wrapFunctional(c.default || c))
export const DropdownWrapper = () => import('../../components/ui/inputs/DropdownWrapper.vue' /* webpackChunkName: "components/dropdown-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../components/ui/inputs/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/ui/inputs/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const RadioInput = () => import('../../components/ui/inputs/RadioInput.vue' /* webpackChunkName: "components/radio-input" */).then(c => wrapFunctional(c.default || c))
export const Selectbox = () => import('../../components/ui/inputs/Selectbox.vue' /* webpackChunkName: "components/selectbox" */).then(c => wrapFunctional(c.default || c))
export const SelectboxWrapper = () => import('../../components/ui/inputs/SelectboxWrapper.vue' /* webpackChunkName: "components/selectbox-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TelephoneInput = () => import('../../components/ui/inputs/TelephoneInput.vue' /* webpackChunkName: "components/telephone-input" */).then(c => wrapFunctional(c.default || c))
export const TextAreaField = () => import('../../components/ui/inputs/TextAreaField.vue' /* webpackChunkName: "components/text-area-field" */).then(c => wrapFunctional(c.default || c))
export const TextInput = () => import('../../components/ui/inputs/TextInput.vue' /* webpackChunkName: "components/text-input" */).then(c => wrapFunctional(c.default || c))
export const VirtualDropdown = () => import('../../components/ui/inputs/VirtualDropdown.vue' /* webpackChunkName: "components/virtual-dropdown" */).then(c => wrapFunctional(c.default || c))
export const LightboxGallery = () => import('../../components/ui/lightbox-gallery/LightboxGallery.vue' /* webpackChunkName: "components/lightbox-gallery" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/ui/loader/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const ModalHeader = () => import('../../components/ui/modal-header/ModalHeader.vue' /* webpackChunkName: "components/modal-header" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/ui/notifications/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const Snackbar = () => import('../../components/ui/snackbar/Snackbar.vue' /* webpackChunkName: "components/snackbar" */).then(c => wrapFunctional(c.default || c))
export const Sticky = () => import('../../components/ui/sticky/Sticky.vue' /* webpackChunkName: "components/sticky" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../components/ui/tabs/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/ui/tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TabsFilled = () => import('../../components/ui/tabs/TabsFilled.vue' /* webpackChunkName: "components/tabs-filled" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/widgets/Accordion/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const BannerListAuto = () => import('../../components/widgets/BannerListAuto/BannerListAuto.vue' /* webpackChunkName: "components/banner-list-auto" */).then(c => wrapFunctional(c.default || c))
export const BannerListAutoFourColumns = () => import('../../components/widgets/BannerListAuto/BannerListAutoFourColumns.vue' /* webpackChunkName: "components/banner-list-auto-four-columns" */).then(c => wrapFunctional(c.default || c))
export const BannerListAutoTwoColumns = () => import('../../components/widgets/BannerListAuto/BannerListAutoTwoColumns.vue' /* webpackChunkName: "components/banner-list-auto-two-columns" */).then(c => wrapFunctional(c.default || c))
export const BannerListNoSpace = () => import('../../components/widgets/BannerListNoSpace/BannerListNoSpace.vue' /* webpackChunkName: "components/banner-list-no-space" */).then(c => wrapFunctional(c.default || c))
export const BannerSingle = () => import('../../components/widgets/BannerSingle/BannerSingle.vue' /* webpackChunkName: "components/banner-single" */).then(c => wrapFunctional(c.default || c))
export const BannersCarousel = () => import('../../components/widgets/BannersCarousel/BannersCarousel.vue' /* webpackChunkName: "components/banners-carousel" */).then(c => wrapFunctional(c.default || c))
export const BannerList = () => import('../../components/widgets/BannersList/BannerList.vue' /* webpackChunkName: "components/banner-list" */).then(c => wrapFunctional(c.default || c))
export const BigProductCarousel = () => import('../../components/widgets/BigProductCarousel/BigProductCarousel.vue' /* webpackChunkName: "components/big-product-carousel" */).then(c => wrapFunctional(c.default || c))
export const BannerCarousel = () => import('../../components/widgets/CardCarousel/BannerCarousel.vue' /* webpackChunkName: "components/banner-carousel" */).then(c => wrapFunctional(c.default || c))
export const CardCarousel = () => import('../../components/widgets/CardCarousel/CardCarousel.vue' /* webpackChunkName: "components/card-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductCarousel = () => import('../../components/widgets/CardCarousel/ProductCarousel.vue' /* webpackChunkName: "components/product-carousel" */).then(c => wrapFunctional(c.default || c))
export const CategoryFullWidthPromo = () => import('../../components/widgets/CategoryFullWidthPromo/CategoryFullWidthPromo.vue' /* webpackChunkName: "components/category-full-width-promo" */).then(c => wrapFunctional(c.default || c))
export const CategoryTwoColumnsPromo = () => import('../../components/widgets/CategoryTwoColumnsPromo/CategoryTwoColumnsPromo.vue' /* webpackChunkName: "components/category-two-columns-promo" */).then(c => wrapFunctional(c.default || c))
export const ContactBoxes = () => import('../../components/widgets/ContactBoxes/ContactBoxes.vue' /* webpackChunkName: "components/contact-boxes" */).then(c => wrapFunctional(c.default || c))
export const Contest = () => import('../../components/widgets/Contest/Contest.vue' /* webpackChunkName: "components/contest" */).then(c => wrapFunctional(c.default || c))
export const CustomHTML = () => import('../../components/widgets/CustomHTML/CustomHTML.vue' /* webpackChunkName: "components/custom-h-t-m-l" */).then(c => wrapFunctional(c.default || c))
export const FourTileBanner = () => import('../../components/widgets/FourTileBanner/FourTileBanner.vue' /* webpackChunkName: "components/four-tile-banner" */).then(c => wrapFunctional(c.default || c))
export const GridBannersWithDescription = () => import('../../components/widgets/GridBannersWithDescription/GridBannersWithDescription.vue' /* webpackChunkName: "components/grid-banners-with-description" */).then(c => wrapFunctional(c.default || c))
export const LeafletWithBannerSlider = () => import('../../components/widgets/LeafletWithBannerSlider/LeafletWithBannerSlider.vue' /* webpackChunkName: "components/leaflet-with-banner-slider" */).then(c => wrapFunctional(c.default || c))
export const PickupPoints = () => import('../../components/widgets/PickupPoints/PickupPoints.vue' /* webpackChunkName: "components/pickup-points" */).then(c => wrapFunctional(c.default || c))
export const ProductGroupList = () => import('../../components/widgets/ProductGroupList/ProductGroupList.vue' /* webpackChunkName: "components/product-group-list" */).then(c => wrapFunctional(c.default || c))
export const ProductGroupListGrid = () => import('../../components/widgets/ProductGroupListGrid/ProductGroupListGrid.vue' /* webpackChunkName: "components/product-group-list-grid" */).then(c => wrapFunctional(c.default || c))
export const ProductGroupListTabs = () => import('../../components/widgets/ProductGroupListTabs/ProductGroupListTabs.vue' /* webpackChunkName: "components/product-group-list-tabs" */).then(c => wrapFunctional(c.default || c))
export const SliderWithProductGroups = () => import('../../components/widgets/SliderWithProductGroups/SliderWithProductGroups.vue' /* webpackChunkName: "components/slider-with-product-groups" */).then(c => wrapFunctional(c.default || c))
export const ThreeLeafletWithBannerSlider = () => import('../../components/widgets/ThreeLeafletWithBannerSlider/ThreeLeafletWithBannerSlider.vue' /* webpackChunkName: "components/three-leaflet-with-banner-slider" */).then(c => wrapFunctional(c.default || c))
export const TwoTileBanner = () => import('../../components/widgets/TwoTileBanner/TwoTileBanner.vue' /* webpackChunkName: "components/two-tile-banner" */).then(c => wrapFunctional(c.default || c))
export const VideoWithProductsSlider = () => import('../../components/widgets/VideoWithProductsSlider/VideoWithProductsSlider.vue' /* webpackChunkName: "components/video-with-products-slider" */).then(c => wrapFunctional(c.default || c))
export const BannerCarouselFourColumns = () => import('../../components/widgets/Wrappers/BannerCarouselFourColumns.vue' /* webpackChunkName: "components/banner-carousel-four-columns" */).then(c => wrapFunctional(c.default || c))
export const BannerListFourColumns = () => import('../../components/widgets/Wrappers/BannerListFourColumns.vue' /* webpackChunkName: "components/banner-list-four-columns" */).then(c => wrapFunctional(c.default || c))
export const BannerListTwoColumns = () => import('../../components/widgets/Wrappers/BannerListTwoColumns.vue' /* webpackChunkName: "components/banner-list-two-columns" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/widgets/common/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const WidgetContainer = () => import('../../components/widgets/common/WidgetContainer.vue' /* webpackChunkName: "components/widget-container" */).then(c => wrapFunctional(c.default || c))
export const JobOfferCard = () => import('../../components/job-openings/job-offers/sub/JobOfferCard.vue' /* webpackChunkName: "components/job-offer-card" */).then(c => wrapFunctional(c.default || c))
export const JobOfferDetailsComponent = () => import('../../components/job-openings/job-offers/sub/JobOfferDetailsComponent.vue' /* webpackChunkName: "components/job-offer-details-component" */).then(c => wrapFunctional(c.default || c))
export const JobOfferList = () => import('../../components/job-openings/job-offers/sub/JobOfferList.vue' /* webpackChunkName: "components/job-offer-list" */).then(c => wrapFunctional(c.default || c))
export const JoinUsCard = () => import('../../components/job-openings/join-us/sub/JoinUsCard.vue' /* webpackChunkName: "components/join-us-card" */).then(c => wrapFunctional(c.default || c))
export const JoinUsDialog = () => import('../../components/job-openings/join-us/sub/JoinUsDialog.vue' /* webpackChunkName: "components/join-us-dialog" */).then(c => wrapFunctional(c.default || c))
export const JoinUsList = () => import('../../components/job-openings/join-us/sub/JoinUsList.vue' /* webpackChunkName: "components/join-us-list" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/job-openings/job-categories/sub/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const Filters = () => import('../../components/job-openings/job-categories/sub/Filters.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c))
export const FiltersVertical = () => import('../../components/job-openings/job-categories/sub/FiltersVertical.vue' /* webpackChunkName: "components/filters-vertical" */).then(c => wrapFunctional(c.default || c))
export const FooterInfo = () => import('../../components/layout/Footer/sub/FooterInfo.vue' /* webpackChunkName: "components/footer-info" */).then(c => wrapFunctional(c.default || c))
export const FooterLinkList = () => import('../../components/layout/Footer/sub/FooterLinkList.vue' /* webpackChunkName: "components/footer-link-list" */).then(c => wrapFunctional(c.default || c))
export const Announcement = () => import('../../components/layout/Header/announcement/Announcement.vue' /* webpackChunkName: "components/announcement" */).then(c => wrapFunctional(c.default || c))
export const AnnouncementChunk = () => import('../../components/layout/Header/announcement/AnnouncementChunk.vue' /* webpackChunkName: "components/announcement-chunk" */).then(c => wrapFunctional(c.default || c))
export const NavbarSearch = () => import('../../components/layout/Header/search/NavbarSearch.vue' /* webpackChunkName: "components/navbar-search" */).then(c => wrapFunctional(c.default || c))
export const NavbarSearchInput = () => import('../../components/layout/Header/search/NavbarSearchInput.vue' /* webpackChunkName: "components/navbar-search-input" */).then(c => wrapFunctional(c.default || c))
export const NavbarSearchResults = () => import('../../components/layout/Header/search/NavbarSearchResults.vue' /* webpackChunkName: "components/navbar-search-results" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuHeader = () => import('../../components/layout/Header/sub/MobileMenuHeader.vue' /* webpackChunkName: "components/mobile-menu-header" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/layout/Header/sub/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const NavbarDropdown = () => import('../../components/layout/Header/sub/NavbarDropdown.vue' /* webpackChunkName: "components/navbar-dropdown" */).then(c => wrapFunctional(c.default || c))
export const NavbarItem = () => import('../../components/layout/Header/sub/NavbarItem.vue' /* webpackChunkName: "components/navbar-item" */).then(c => wrapFunctional(c.default || c))
export const NavbarItemMobile = () => import('../../components/layout/Header/sub/NavbarItemMobile.vue' /* webpackChunkName: "components/navbar-item-mobile" */).then(c => wrapFunctional(c.default || c))
export const NavbarMobileMenu = () => import('../../components/layout/Header/sub/NavbarMobileMenu.vue' /* webpackChunkName: "components/navbar-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const NavbarTop = () => import('../../components/layout/Header/sub/NavbarTop.vue' /* webpackChunkName: "components/navbar-top" */).then(c => wrapFunctional(c.default || c))
export const ProductAmountSelector = () => import('../../components/products/ProductCard/sub/ProductAmountSelector.vue' /* webpackChunkName: "components/product-amount-selector" */).then(c => wrapFunctional(c.default || c))
export const ProductAvailableInStoresBadge = () => import('../../components/products/ProductCard/sub/ProductAvailableInStoresBadge.vue' /* webpackChunkName: "components/product-available-in-stores-badge" */).then(c => wrapFunctional(c.default || c))
export const ProductMainPrice = () => import('../../components/products/ProductCard/sub/ProductMainPrice.vue' /* webpackChunkName: "components/product-main-price" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceBlock = () => import('../../components/products/ProductCard/sub/ProductPriceBlock.vue' /* webpackChunkName: "components/product-price-block" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceMultiple = () => import('../../components/products/ProductCard/sub/ProductPriceMultiple.vue' /* webpackChunkName: "components/product-price-multiple" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailsTable = () => import('../../components/products/ProductDetails/sub/ProductDetailsTable.vue' /* webpackChunkName: "components/product-details-table" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailsTextSection = () => import('../../components/products/ProductDetails/sub/ProductDetailsTextSection.vue' /* webpackChunkName: "components/product-details-text-section" */).then(c => wrapFunctional(c.default || c))
export const ListGrid = () => import('../../components/products/ProductsList/sub/ListGrid.vue' /* webpackChunkName: "components/list-grid" */).then(c => wrapFunctional(c.default || c))
export const ProductsListCategories = () => import('../../components/products/ProductsList/sub/ProductsListCategories.vue' /* webpackChunkName: "components/products-list-categories" */).then(c => wrapFunctional(c.default || c))
export const ProductsListFilterDialog = () => import('../../components/products/ProductsList/sub/ProductsListFilterDialog.vue' /* webpackChunkName: "components/products-list-filter-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProductsListPromo = () => import('../../components/products/ProductsList/sub/ProductsListPromo.vue' /* webpackChunkName: "components/products-list-promo" */).then(c => wrapFunctional(c.default || c))
export const PromotionalPopupBannerTemplate = () => import('../../components/promotional-popups/sub/templates/PromotionalPopupBannerTemplate.vue' /* webpackChunkName: "components/promotional-popup-banner-template" */).then(c => wrapFunctional(c.default || c))
export const PromotionalPopupVariantTemplate = () => import('../../components/promotional-popups/sub/templates/PromotionalPopupVariantTemplate.vue' /* webpackChunkName: "components/promotional-popup-variant-template" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../components/widgets/Accordion/sub/AccordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const BannerListAutoCustom = () => import('../../components/widgets/BannerListAuto/sub/BannerListAutoCustom.vue' /* webpackChunkName: "components/banner-list-auto-custom" */).then(c => wrapFunctional(c.default || c))
export const CarouselButton = () => import('../../components/widgets/BannersCarousel/sub/CarouselButton.vue' /* webpackChunkName: "components/carousel-button" */).then(c => wrapFunctional(c.default || c))
export const CarouselContainer = () => import('../../components/widgets/BannersCarousel/sub/CarouselContainer.vue' /* webpackChunkName: "components/carousel-container" */).then(c => wrapFunctional(c.default || c))
export const CarouselControls = () => import('../../components/widgets/BannersCarousel/sub/CarouselControls.vue' /* webpackChunkName: "components/carousel-controls" */).then(c => wrapFunctional(c.default || c))
export const PromotionalBanner = () => import('../../components/widgets/BannersCarousel/sub/PromotionalBanner.vue' /* webpackChunkName: "components/promotional-banner" */).then(c => wrapFunctional(c.default || c))
export const BigProductCardSlide = () => import('../../components/widgets/BigProductCarousel/sub/BigProductCardSlide.vue' /* webpackChunkName: "components/big-product-card-slide" */).then(c => wrapFunctional(c.default || c))
export const SectionHeader = () => import('../../components/widgets/CardCarousel/sub/SectionHeader.vue' /* webpackChunkName: "components/section-header" */).then(c => wrapFunctional(c.default || c))
export const PickupPointsMap = () => import('../../components/widgets/PickupPoints/sub/PickupPointsMap.vue' /* webpackChunkName: "components/pickup-points-map" */).then(c => wrapFunctional(c.default || c))
export const PickupPointsPopup = () => import('../../components/widgets/PickupPoints/sub/PickupPointsPopup.vue' /* webpackChunkName: "components/pickup-points-popup" */).then(c => wrapFunctional(c.default || c))
export const PickupPointsResults = () => import('../../components/widgets/PickupPoints/sub/PickupPointsResults.vue' /* webpackChunkName: "components/pickup-points-results" */).then(c => wrapFunctional(c.default || c))
export const WidgetTitle = () => import('../../components/widgets/common/WidgetTitle/WidgetTitle.vue' /* webpackChunkName: "components/widget-title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
