
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import AnnouncementChunk from './AnnouncementChunk.vue';
import DeviceMixin from '~/mixins/device.mixin';

@Component({
  name: 'dn-Announcement',
})
export default class Announcement extends mixins(DeviceMixin) {
  @Prop({ type: String, default: '' }) private text!: string;
  @Prop({ type: [Object, String], default: '' }) private wrapperClass!: Object | string;
  @Prop({ type: Boolean, default: true }) private singleOnMobile!: boolean;

  private isStopped = false;

  private get wrapperCls() {
    return this.$options.filters?.merge(
      this.wrapperClass,
      {
        'pt-0': !!this.text
      }
    );
  }

  private get marqueeText() {
    return this.text;
  }

  private start() {
    this.isStopped = false;
  }

  private stop() {
    this.isStopped = true;
  }

}
