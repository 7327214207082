import {
  IsNotEmpty as _IsNotEmpty,
  IsEmail as _IsEmail,
  MaxLength as _MaxLength,
  MinLength as _MinLength,
  NotEquals as _NotEquals,
  IsPhoneNumber as _IsPhoneNumber,
  IsNotEmptyObject as _IsNotEmptyObject,
  ValidationOptions,
  IsUrl as _IsUrl,
  registerDecorator,
  ValidationArguments,
} from 'class-validator';
import { i18n } from '~/plugins/i18n';

export const Required = (options?: ValidationOptions): PropertyDecorator =>
  _IsNotEmpty({
    message: () => i18n.t('VALIDATE__EMPTY').toString(),
    ...options,
  });

export const RequiredObject = (
  options?: ValidationOptions
): PropertyDecorator =>
  _IsNotEmptyObject(
    {},
    {
      message: () => i18n.t('VALIDATE__EMPTY').toString(),
      ...options,
    }
  );

export const Email = (options?: ValidationOptions): PropertyDecorator =>
  _IsEmail(
    {},
    {
      message: () => i18n.t('VALIDATE__EMAIL').toString(),
      ...options,
    }
  );

export const NotEquals = (options?: ValidationOptions): PropertyDecorator =>
  _NotEquals(false, {
    message: () => i18n.t('VALIDATE__EMPTY').toString(),
    ...options,
  });

export const IsCustomDateString = (options?: ValidationOptions): PropertyDecorator =>
  (object: Object, propertyName: string | Symbol) => {
    registerDecorator({
      name: 'isFlexibleDateString',
      target: object.constructor,
      propertyName: String(propertyName),
      options: options,
      validator: {
        validate(value: any) {
          if (typeof value !== 'string') {
            return false;
          }
          
          const date = new Date(value);
          return !isNaN(date.getTime());
        },
        defaultMessage(args: ValidationArguments) {
          return `${args.property} must be a valid date string`;
        }
      }
    });
  };

export const IsPhoneNumber = (options?: ValidationOptions): PropertyDecorator =>
  _IsPhoneNumber('PL', {
    message: () => i18n.t('VALIDATE__PHONE').toString(),
    ...options,
  });

export const IsUrl = (options?: ValidationOptions): PropertyDecorator =>
  _IsUrl(
    {},
    {
      message: () => i18n.t('VALIDATE__IS_URL').toString(),
      ...options,
    }
  );

export const MaxLength = (
  max: number,
  options?: ValidationOptions
): PropertyDecorator =>
  _MaxLength(max, {
    message: () => i18n.t('VALIDATE__PASSWORD_TOO_LONG', [max]).toString(),
    ...options,
  });
export const MinLength = (
  min: number,
  options?: ValidationOptions
): PropertyDecorator =>
  _MinLength(min, {
    message: () => i18n.t('VALIDATE__PASSWORD_TOO_SHORT', [min]).toString(),
    ...options,
  });

export const AcceptedFile = (
  mimeList: { mime: string; ext: string }[],
  validationOptions?: ValidationOptions
) => {
  return function (object: Object, propertyName: string) {
    return registerDecorator({
      name: 'AcceptedFile',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [],
      options: {
        message: () =>
          i18n
            .t('VALIDATE__ACCEPTED_FILES', [
              mimeList.map((item) => item.ext).join(', '),
            ])
            .toString(),
        ...validationOptions,
      },
      validator: {
        validate(value: File, args: ValidationArguments) {
          if (
            !value ||
            (value?.type &&
              (mimeList.map((item) => item.mime) ?? []).includes(value?.type))
          ) {
            return true;
          }
          return false;
        },
      },
    });
  };
};

/**
 * Checks if the file size is not larger than given number.
 *
 * @param {number} maxSize Max file size in kilobytes.
 * @param {ValidationOptions} validationOptions Other validation options.
 */
export const FileMaxSize = (
  maxSize: number,
  validationOptions?: ValidationOptions
) => {
  return function (object: Object, propertyName: string) {
    return registerDecorator({
      name: 'AcceptedFile',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [],
      options: {
        message: () => i18n.t('VALIDATE__FILE_MAX_SIZE').toString(),
        ...validationOptions,
      },
      validator: {
        validate(value: File, args: ValidationArguments) {
          if (!value || value?.size <= maxSize * 1000) {
            return true;
          }
          return false;
        },
      },
    });
  };
};
