import type { Context } from '@nuxt/types';
import { Route, NavigationGuardNext } from 'vue-router';
import VueRouter from 'vue-router/types';

export default ({ app }: Context) => {
  type CustomRouter = Pick<
    VueRouter,
    'push' | 'replace' | 'go' | 'back' | 'forward'
  >;
  const router: VueRouter = app.router!;

  // `programmatic` informs that navigation change is from router functions or browser back
  let programmatic = false;

  const methods = [
    'push',
    'replace',
    'go',
    'back',
    'forward',
  ] as (keyof CustomRouter)[];

  methods.forEach(methodName => {
    const method: Function = router[methodName];
    (router as CustomRouter)[methodName] = (...args: any[]): any => {
      programmatic = true;
      method.apply(router, args);
    };
  });

  router!.beforeEach((_: Route, from: Route, next: NavigationGuardNext) => {
    next();
    app.router!.programmatic = programmatic || from.name === null;
    app.router!.previousRoute = from;
    programmatic = false;
  });
};
