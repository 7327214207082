import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=29add0ca"
import script from "./Error404.vue?vue&type=script&lang=ts"
export * from "./Error404.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/frontend/components/ui/img/img.vue').default})
