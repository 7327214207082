import { Component, Vue } from 'nuxt-property-decorator';
import debounce from 'lodash/debounce';
import { TIMERS } from '~/constants/timers';
import { DeviceSize } from '~/models/enums/device-size';
import { Orientation as ScreenOrientation } from '~/models/enums/orientation';
@Component
export default class DeviceMixin extends Vue {
  isMounted: boolean = false;
  private screenOrientation: ScreenOrientation = ScreenOrientation.HORIZONTAL;

  private breakpoints: { [key: string]: boolean } = {
    isMobile: false,
    isTablet: false,
    isMobileOrTablet: false,
  };

  get isMobile(): boolean {
    return this.isMounted
      ? this.breakpoints.isMobile
      : this.$device.isMobileOrTablet;
  }

  get isTablet(): boolean {
    return this.isMounted ? this.breakpoints.isTablet : this.$device.isTablet;
  }

  get isMobileOrTablet(): boolean {
    return this.isMounted
      ? this.breakpoints.isMobileOrTablet
      : this.$device.isMobileOrTablet;
  }

  get isHorizontal(): boolean {
    return this.screenOrientation === ScreenOrientation.HORIZONTAL;
  }

  get isVertical(): boolean {
    return this.screenOrientation === ScreenOrientation.VERTICAL;
  }

  private setBreakpoints(w: number, h: number) {
    this.breakpoints.isMobile = w <= DeviceSize.MOBILE;
    this.breakpoints.isTablet = w > DeviceSize.MOBILE && w <= DeviceSize.TABLET;
    this.breakpoints.isMobileOrTablet = w <= DeviceSize.TABLET;
    this.screenOrientation =
      w > h ? ScreenOrientation.HORIZONTAL : ScreenOrientation.VERTICAL;
  }

  private onResize({ target: { innerWidth, innerHeight } }: any) {
    this.setBreakpoints(innerWidth, innerHeight);
  }

  mounted() {
    this.setBreakpoints(window.innerWidth, window.innerHeight);
    window.addEventListener('resize', debounce(this.onResize, TIMERS.DEBOUNCE));
    this.isMounted = true;
  }

  beforeDestroy() {
    window.removeEventListener(
      'resize',
      debounce(this.onResize, TIMERS.DEBOUNCE)
    );
  }
}
