
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { priceFilter } from '~/filters/price';

@Component({
  name: 'dn-product-price-multiple',
  methods: { priceFilter },
})
export default class ProductPriceMutliple extends Vue {
  @Prop({ type: String, required: true }) oneItemPrice!: string;
  @Prop({ type: String, required: true }) fewItemsPrice!: string;
  @Prop({ type: Number }) amountPromotion!: number;
  @Prop({ type: String }) pricePerUnit!: string;
  @Prop({ type: String }) pricePerUnitDefault!: string;
  @Prop({ type: Boolean, default: false }) small!: boolean;
  @Prop({ type: String, default: '' }) containerClass!: string;
  @Prop({ type: String, default: '' }) priceClass!: string;
  @Prop({ type: String, default: '' }) priceFewClass!: string;

  private get priceClasses() {
    return this.$options.filters?.merge(
      'inline-block bg-primary text-white rounded-full whitespace-nowrap',
      {
        'px-1 h-3.5 xl:h-4': this.small,
        'px-1.5 md:px-2 h-5 md:h-6': !this.small,
      },
      this.priceClass,
    );
  }
}
