
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  name: 'dn-mobile-menu-header',
})
export default class MobileMenuHeader extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Boolean, required: false }) isActive!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  subCategory!: boolean;
  @Prop({ type: Function, required: false }) onClose!: () => void;
}
