import uniqBy from 'lodash/uniqBy';
import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator';
import Vue from 'vue';
import Product from '~/models/product';

type HistoryEntry = {
  products: Product[];
};

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ListingModule',
})
export default class ListingModule extends VuexModule {
  private entries: { [key: string]: HistoryEntry } = {};

  get products(): (key: string) => HistoryEntry | null {
    return (key) => this.entries[key] || null;
  }

  @VuexMutation
  SET({
    key,
    products,
  }: {
    key: string;
    products: Product[];
  }) {
    Vue.set(this.entries, key, { products });
  }
}
