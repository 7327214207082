export const TIMERS = {
  TIMEOUT: 300,
  SLIDE_CHANGE: 500,
  DEBOUNCE: 300,
  DEBOUNCE_MAPS: 1000,
  BANNER_SLIDE: 3000,
  BANNER_SLIDE_PREVIEW: 10000,
  NEWSLETTER__MODAL_TIMEOUT: 60000,
  QUANTITY_CHANGE: 1000,
};
