import { render, staticRenderFns } from "./LeafletMobileFooter.vue?vue&type=template&id=04d90d2e"
import script from "./LeafletMobileFooter.vue?vue&type=script&lang=ts"
export * from "./LeafletMobileFooter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/frontend/components/ui/buttons/BaseButton.vue').default,Input: require('/frontend/components/ui/inputs/Input.vue').default,Footer: require('/frontend/components/layout/Footer/Footer.vue').default})
