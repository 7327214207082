import { Context } from '@nuxt/types';
import { handler } from '~/services/error.service';

export default function ({
  $axios,
  $config: { authToken },
  app,
  redirect,
  error,
}: Context) {
  $axios.onRequest((config) => {
    if (authToken) {
      config.headers.Authorization = `Token ${authToken}`;
    } else {
      throw new Error(
        'Authorization token needed. Set it on AUTH_TOKEN variable',
      );
    }
  });

  $axios.onRequestError((e) =>
    handler(e, app.router!.app, { redirect, error }),
  );

  $axios.onError((error) => {
    if (error.response) {
      const statusCode = error.response.status;

      switch (true) {
        case statusCode === 404:
          console.error('The requested resource was not found.');
          break;

        case statusCode === 500:
          console.error('Internal server error.');
          break;

        default:
          console.error(
            `API error (code ${statusCode}): ${error.response?.data?.message || 'Unknown error'}`,
          );
          break;
      }
    } else {
      console.error('Unable to connect to the server.');
    }

    return Promise.reject(error);
  });
}
