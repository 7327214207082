
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import Product from '~/models/product';
import { priceFilter } from '~/filters/price';

@Component({
  name: 'dn-product-price-display',
  methods: { priceFilter },
})
export default class ProductPriceDisplay extends Vue {
  @Prop({ type: Object, required: true }) product!: Product;
  @Prop({ type: Boolean, default: false }) small!: boolean;
  @Prop({ type: String, default: '' }) priceClass!: string;
  @Prop({ type: String, default: '' }) multipleClass!: string;
  @Prop({ type: Boolean, default: false }) invertColors!: boolean;
  @Prop({ type: Boolean, default: false }) hidePerUnit!: boolean;
  @Prop({ type: Boolean, default: false }) hideDiscount!: boolean;
  @Prop({ type: Boolean, default: false }) hideOmnibus!: boolean;

  private get amountPromotion() {
    if (
      this.product &&
      this.product.price &&
      typeof this.product.price.specialPriceConditions === 'string'
    ) {
      const amount = (
        this.product.price.specialPriceConditions as string
      ).match(/\d+/);
      return amount ? parseInt(amount[0], 10) : undefined;
    }
    return undefined;
  }

  private get shouldDisplayOmnibus() {
    if (!this.product.price?.omnibusPrice || this.hideOmnibus) {
      return false;
    }

    return parseFloat(String(this.product.price?.price)) < parseFloat(String(this.product.price?.oldPrice));
  }

  private get containerClasses() {
    return this.$options.filters?.merge(
      'flex flex-col text-zinc-600 font-lexend',
      {
        'text-2xs xl:text-xs gap-1 leading-4': this.small,
        'text-sm md:text-base gap-1 md:gap-2 leading-5 md:leading-6': !this.small,
        'text-white': this.invertColors,
      },
    );
  }

  private get XYcontainerClasses() {
    return this.$options.filters?.merge(
      'flex flex-col text-zinc-600 font-lexend',
      {
        'text-2xs xl:text-xs gap-1 leading-4': this.small,
        'text-sm md:text-base gap-1 md:gap-2 leading-5 md:leading-6': !this.small,
        'text-white': this.invertColors,
      },
      this.multipleClass
    );
  }

  private get priceClasses() {
    return this.$options.filters?.merge(
      'inline-block bg-primary text-white rounded-full whitespace-nowrap',
      {
        'px-1 h-3.5 xl:h-4': this.small,
        'px-1.5 md:px-2 h-5 md:h-6': !this.small,
      },
      this.priceClass,
    );
  }
}
