import lottie from 'lottie-web';

// makes lottie api available from this.$lottie in a nuxt application
export default (_: any, inject: any) => {
  inject('lottie', lottie);
};

declare module 'vue/types/vue' {
  interface Vue {
    $lottie: {
      loadAnimation: (options: any) => any;
    };
  }
}