import { Expose } from 'class-transformer';

export default class Pagination<T> {
  @Expose()
  count!: number;

  @Expose()
  next!: string;

  @Expose()
  previous!: string;

  @Expose()
  results!: T;
}
