export const DAYS_400_IN_SECONDS = 400 * 24 * 60 * 60;
export const DAYS_30_IN_SECONDS = 30 * 24 * 60 * 60;
export const DAYS_14_IN_SECONDS = 14 * 24 * 60 * 60;
export const HOURS_3_IN_SECONDS = 3 * 60 * 60;

export const getClientCookie = (name: string): string => {
  if (typeof document === 'undefined') {
    return '';
  }

  const cookies = document.cookie.split(';');
  const cookie = cookies.find((cookie) => cookie.trim().startsWith(`${name}=`));

  return cookie ? cookie.split('=')[1] : '';
}

export const setClientCookie = (name: string, value: string, seconds: number | "MAX" = DAYS_30_IN_SECONDS): void => {
  if (typeof document === 'undefined') {
    return;
  }

  if (seconds === 'MAX') {
    /** NOTE: Max cookie Expires/Max-Age is 400 days according to standards **/
    seconds = DAYS_400_IN_SECONDS;
  }

  const date = new Date();
  date.setTime(date.getTime() + seconds * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}
