import Vue from 'vue';
import Notifications from '~/components/ui/notifications/Notifications.vue';
import { NotificationStyle } from '~/models/notifications';
import { NOTIFICATION_TYPE_ENUM } from '~/models/enums/notification-type.enum';

const NotificationsConstructor = Vue.extend(Notifications);
let instance: any;

export default function (_: any, inject: any) {
  if (!instance) {
    instance = new NotificationsConstructor();
    instance.$mount();
    document.body.appendChild(instance.$el);
  }

  inject('notify', (message: string, type?: NOTIFICATION_TYPE_ENUM, style?: NotificationStyle, title?: string) => {
    instance.addNotification(message, type, style, title);
  });
}

declare module 'vue/types/vue' {
  export interface Vue {
    $notify(message: string, type?: NOTIFICATION_TYPE_ENUM, style?: NotificationStyle, title?: string): void;
  }
}
