
import { Component, getModule, mixins } from 'nuxt-property-decorator';
import { FooterMock } from '~/mocks/footer.mock';
import FooterSection from '~/models/footer-section';
import DeviceMixin from '~/mixins/device.mixin';
import { NewsletterFormModel } from '~/models/forms/newsletter-form';
import GlobalModule from '~/store/GlobalModule';
import { NewsletterService } from '~/services/newsletter.service';
import SnackbarMixin from '~/mixins/snackbar.mixin';
import { LayoutService } from '~/services/layout.service';
import { PopupService } from '~/services/popup.service';
import { StatusType } from '~/models/enums/status-type';
import { SeoService } from '~/services/seo.service';
import { i18n } from '~/plugins/i18n';
import Loader from '~/components/ui/loader/Loader.vue';
import { NOTIFICATION_TYPE_ENUM } from '~/models/enums/notification-type.enum';

@Component({
  name: 'dn-default-layout',
})
export default class DefaultLayout extends mixins(DeviceMixin) {
  $refs!: Vue['$refs'] & {
    newsletter: { clearForm: () => void };
  };
  private footerData: { [key: string]: FooterSection[][] | FooterSection } =
    FooterMock.getFooterData();
  private globalModule!: GlobalModule;
  private metaTitle = i18n.t('SEO_DEFAULT_META_TITLE');
  private metaDescription = i18n.t('SEO_DEFAULT_META_DESCRIPTION');

  private get isPopupVisible() {
    return this.$store.state.GetIsPopupVisible;
  }

  async created() {
    this.globalModule = getModule(GlobalModule, this.$store);
    await this.fetchMetaData();
    await this.fetchPopups();
  }

  async fetchMetaData() {
    try {
      const data = await SeoService.getMetaTags(this.$axios);
      data.forEach((meta) => {
        if (meta.property === 'meta-description' && meta.value) {
          this.metaDescription = meta.value;
        }

        if (meta.property === 'meta-title' && meta.value) {
          this.metaTitle = meta.value;
        }
      });
    } catch (error) {
      console.error('Błąd pobierania meta danych:', error);
    }
  }

  async fetchPopups() {
    try {
      const popups = await PopupService.getPopups(this.$axios)
      if (popups.length > 0) {
        this.globalModule.setFetchedPopups(popups);
      }
    } catch (error) {
      console.error('Error fetching popups:', error);
    }
  }

  head() {
    return {
      title: this.metaTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.metaDescription,
        },
      ],
    };
  }

  private hidePopup() {
    this.globalModule.setIsPopupVisible(false);
  }
}
