import { Plugin } from '@nuxt/types';
import Vue from 'vue';
import Dialog from '~/components/ui/dialogs/Dialog.vue';
import { mergeFilter } from '~/filters/merge';

export type DialogOpened = { destroy: Function };

interface Options {
  id: string | number | undefined;
  width: string;
  title: string | null;
  props: { [key: string]: any };
  events: { [key: string]: (...events: any[]) => void };
  component?: typeof Vue;
  overlayClasses?: string;
  contentClasses?: string;
  dialogClasses?: string;
  closeBtnClasses?: string;
  blurOut?: boolean;
  showClose: boolean;
  rounded: boolean;
  mobileFullscreen: boolean;
  mobileDisplayAsDrawer: boolean;
  closeOnOutsideClick: boolean;
}

class DialogBuilder {
  private context: Vue;

  private options: Options = {
    id: undefined,
    width: '406px',
    title: null,
    props: {},
    events: {},
    showClose: true,
    rounded: false,
    mobileFullscreen: false,
    mobileDisplayAsDrawer: false,
    closeOnOutsideClick: false,
  };

  private passedOptions?: { overlayCls: string };

  constructor(context: Vue, passedOptions?: { overlayCls: string }) {
    this.context = context;
    this.passedOptions = passedOptions;
  }

  setId(id: string | number) {
    this.options.id = id;
    return this;
  }

  setComponent(component: typeof Vue) {
    this.options.component = component;
    return this;
  }

  setTitle(title: any) {
    this.options.title = title;
    return this;
  }

  setProps(props: any) {
    this.options.props = props;
    return this;
  }

  setEvents(events: any) {
    this.options.events = events;
    return this;
  }

  setWidth(width: string) {
    this.options.width = width;
    return this;
  }

  setCloseOnOutsideClick(closeOnOutsideClick: boolean) {
    this.options.closeOnOutsideClick = closeOnOutsideClick;
    return this;
  }

  setShowClose(showClose: boolean) {
    this.options.showClose = showClose;
    return this;
  }

  setBlurOut(blurOut: boolean) {
    this.options.blurOut = blurOut;
    return this;
  }

  setOverlayClasses(overlayClasses: string) {
    this.options.overlayClasses = mergeFilter(overlayClasses, this.passedOptions?.overlayCls);
    return this;
  }

  setDialogClasses(dialogClasses: string) {
    this.options.dialogClasses = dialogClasses;
    return this;
  }

  setCloseBtnClasses(closeBtnCls: string) {
    this.options.closeBtnClasses = closeBtnCls;
    return this;
  }

  setContentClasses(contentClasses: string) {
    this.options.contentClasses = contentClasses;
    return this;
  }

  setRounded(rounded: boolean) {
    this.options.rounded = rounded;
    return this;
  }

  setMobileFullscreen(mobileFullscreen: boolean) {
    this.options.mobileFullscreen = mobileFullscreen;
    return this;
  }

  setDisplayAsDrawerOnMobile(mobileDisplayAsDrawer: boolean) {
    this.options.mobileDisplayAsDrawer = mobileDisplayAsDrawer;
    return this;
  }

  show(): DialogOpened {
    const Component = Vue.extend(Dialog);
    const instance = new Component({
      el: document.createElement('div'),
      parent: this.context,
      propsData: this.options,
    });

    // hide possible sticky header
    window?.scrollBy(0,1);

    return instance as unknown as DialogOpened;
  }
}

declare module 'vue/types/vue' {
  export interface Vue {
    $dialog(context: Vue, options?: { overlayCls: string }): DialogBuilder;
  }
}

const dialog: Plugin = (_, inject) => {
  inject('dialog', (context: Vue, options?: { overlayCls: string }) => new DialogBuilder(context, options));
};

export default dialog;
