import { Context } from '@nuxt/types';
declare interface GtmInjected {
  init(id: string): void;
  push(obj: unknown): void;
}

declare module 'vue/types/vue' {
  interface Vue {
    readonly $gtm: GtmInjected;
  }
}

declare module '@nuxt/types' {
  interface Context {
    $gtm: GtmInjected;
  }
}

const GTM_ID = 'GTM-57KPX2S';
export default function ({ $config, $gtm }: Context) {
  if ($config.gtmDisabled) return;

  $gtm.init(GTM_ID);
}
