import { Expose } from 'class-transformer';
import Widget from '~/models/widget';
import { WidgetType } from '~/models/enums/widget-type';

export default class Section {
  @Expose()
  slug!: string;

  @Expose({ name: 'content_url' })
  contentUrl!: string;

  @Expose()
  widget!: WidgetType;

  @Expose()
  content?: Widget;

  @Expose()
  title?: string;

  @Expose()
  description?: string;

  @Expose({ name: 'redirect_url' })
  redirectUrl!: string | null;

  @Expose({ name: 'redirect_url_text' })
  redirectUrlText?: string | null;

  @Expose({ name: 'content_type' })
  contentType?: number;

  @Expose({ name: 'content_id' })
  contentId!: string;

  @Expose({ name: 'date_from' })
  dateFrom?: string | null;

  @Expose({ name: 'date_to' })
  dateTo?: string | null;
}
