import { Expose, Type } from 'class-transformer';
import ProductDetails from '~/models/productDetails';
import ProductTag from '~/models/product-tag';
import Badge from '~/models/badge';
import FlowerBadge from '~/models/flower-badge';

export default class ProductData {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose({ name: 'short_description' })
  shortDescription!: string;

  @Expose({ name: 'is_alcohol' })
  isAlcohol?: boolean;

  @Expose({ name: 'html_content' })
  htmlContent!: string | null;

  @Expose()
  details!: ProductDetails[];

  @Expose({ name: 'available_in_selected_stores' })
  availableInSelectedStores!: string | boolean;

  @Expose()
  tags!: ProductTag[];

  @Expose()
  @Type(() => Badge)
  badges!: Badge[] | null;

  @Expose({ name: 'badge_water' })
  @Type(() => FlowerBadge)
  badgeWater!: FlowerBadge | null;

  @Expose({ name: 'badge_sun' })
  @Type(() => FlowerBadge)
  badgeSun!: FlowerBadge | null;

  @Expose({ name: 'badge_environment' })
  @Type(() => FlowerBadge)
  badgeEnvironment!: FlowerBadge | null;

  @Expose({ name: 'name_list' })
  nameList!: string;

  @Expose({ name: 'description_list' })
  descriptionList!: string;
}
