var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.containerClasses},[(_vm.isDiscount || _vm.isXYPromo || _vm.isPromotional || _vm.isSuperPrice)?_c('div',{class:_vm.discountWrapperClasses},[(_vm.isSuperPrice)?[_c('p',{class:[
          _vm.forPDP
            ? 'font-extrabold text-white text-[10px] leading-[14px] xl:text-base/[26px] xl:leading-[26px]'
            : 'text-[8px] font-extrabold leading-[12px] text-white md:text-[10px] md:leading-[14px]',
        ]},[_vm._v("\n        "+_vm._s(_vm.$t('SUPER_PRICE'))+"\n      ")])]:(_vm.isXYPromo)?[_c('p',{class:[
          _vm.forPDP
            ? 'font-extrabold leading-[0.6] text-white text-[20px] xl:text-[30px]'
            : 'text-base/[0.6] font-extrabold leading-[0.6] text-white md:text-[20px]',
        ]},[_vm._v("\n        "+_vm._s(_vm.xyPromoText)+"\n      ")]),_vm._v(" "),_c('p',{class:[
          _vm.forPDP
            ? 'font-extrabold text-white text-[10px] leading-[14px] xl:text-base/[26px] xl:leading-[26px]'
            : 'text-[8px] font-extrabold leading-[12px] text-white md:text-[10px] md:leading-[14px]',
        ]},[_vm._v("\n        "+_vm._s(_vm.$t('GRATIS'))+"\n      ")])]:(_vm.isDiscount)?[_c('p',{class:[
          _vm.forPDP
            ? 'font-extrabold leading-[0.6] text-white text-[20px] xl:text-[30px]'
            : 'text-base/[0.6] font-extrabold leading-[0.6] text-white md:text-[20px]',
        ]},[_vm._v("\n        "+_vm._s(_vm.discount)+"\n      ")])]:(_vm.isPromotional)?_vm._l((_vm.promotional),function(text,index){return _c('p',{class:[
          'whitespace-nowrap font-extrabold text-white',
          index === 0
            ? _vm.forPDP
              ? 'text-[8px] xl:text-xs'
              : 'text-[6px] md:text-[8px]'
            : _vm.forPDP
              ? 'leading-none text-[10px] xl:text-base/none'
              : 'text-[8px] leading-none md:text-[10px]',
        ]},[_vm._v("\n        "+_vm._s(text)+"\n      ")])}):_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{class:_vm.priceWrapperClasses},[_c('p',{class:_vm.priceClasses},[_vm._v("\n      "+_vm._s(_vm.basePrice)+"\n      "),(_vm.penniesPrice)?_c('span',{class:_vm.penniesClasses},[_vm._v("\n        "+_vm._s(_vm.penniesPrice)+"\n      ")]):_vm._e()]),_vm._v(" "),_c('p',{class:_vm.labelClasses},[(!_vm.isSuperPrice)?[(_vm.isXYPromo)?[_vm._v("\n          "+_vm._s(_vm.$t('PRICE_WITHOUT_PROMO_1'))+"\n          "+_vm._s(_vm.$t('PRICE_WITHOUT_PROMO_2'))+"\n        ")]:(_vm.isMultiBuy)?[_vm._v("\n          "+_vm._s(_vm.multiBuyText)+"\n        ")]:_vm._e()]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }