import { Required, Email, NotEquals } from '~/models/forms/validator';
export class NewsletterFormModel {
  @Required()
  @Email()
  email!: string | null;

  @Required()
  @NotEquals()
  regulationsAcceptance!: boolean | null;
}
