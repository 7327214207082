
import { Vue, Component } from 'nuxt-property-decorator';
import { errorRedirectLinksContent } from '~/constants/error-404-content';
import { BasicNuxtLink } from '~/models/basic-link';

@Component({
  name: 'Error404',
})
export default class Error404 extends Vue {
  private links = errorRedirectLinksContent();

  listItemClasses(index: number) {
    return this.$options.filters?.merge(
      'list-none',
      index === 0 && 'col-span-2',
    )
  }

  linkClasses(link: BasicNuxtLink) {
    return this.$options.filters?.merge(
      'w-full px-4 py-2 inline-block rounded-full outline outline-1 text-primary text-sm md:text-base font-medium text-nowrap hover:underline',
      link.highlighted && 'bg-primary text-white'
    );
  }
}

