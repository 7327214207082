var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._g({staticClass:"flex items-center justify-center rounded-full transition-colors duration-100 hover:shadow-hover",class:[
    _vm.borderClass,
    _vm.displayClass,
    _vm.bgColorClass,
    _vm.textColorClass,
    _vm.buttonSizeClass,
    { 'shadow-no-offset border border-white': _vm.shadow },
    { 'opacity-30 cursor-not-allowed': _vm.disabled },
  ],attrs:{"type":_vm.type,"disabled":_vm.disabled}},_vm.$listeners),[_c('i',{class:['icon', _vm.icon]})])
}
var staticRenderFns = []

export { render, staticRenderFns }