
import Vue from 'vue';
import Loader from '~/components/ui/loader/Loader.vue';

interface LoadingOverlayData {
  isLoading: boolean;
  loadingTimeout: ReturnType<typeof setTimeout> | null;
  checkInterval: ReturnType<typeof setInterval> | null;
}

const DISABLED_SLUGS: string[] = ['career-results'];


export default Vue.extend({
  data(): LoadingOverlayData {
    return {
      isLoading: false,
      loadingTimeout: null,
      checkInterval: null
    };
  },
  methods: {
    start(this: Vue & LoadingOverlayData): void {
      if (this.isLoading || this.loadingTimeout) {
        return;
      }

      // throttling to avoid flickering on fast loading sites
      this.loadingTimeout = setTimeout(() => {
        this.isLoading = true;
      }, 200);

      // @ts-ignore
      this.checkInterval = setInterval(() => this.checkCurrentRoute(), 2000);
    },
    finish(this: Vue & LoadingOverlayData): void {
      if (this.loadingTimeout !== null) {
        clearTimeout(this.loadingTimeout);
        this.loadingTimeout = null;
      }
      if (this.checkInterval !== null) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
      this.isLoading = false;
    },
    checkCurrentRoute(this: Vue & LoadingOverlayData): void {
      if (this.isLoading && DISABLED_SLUGS.some(slug => this.$route.name?.includes(slug))) {
        // @ts-ignore
        this.finish();
      }
    },
    beforeDestroy(this: Vue & LoadingOverlayData): void {
      if (this.loadingTimeout !== null) {
        clearTimeout(this.loadingTimeout);
        this.loadingTimeout = null;
      }
      if (this.checkInterval !== null) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    }
  }
});
