
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  name: 'dn-announcement-chunk',
})
export default class AnnouncementChunk extends Vue {
  @Prop({ type: String, default: '' }) private text!: string;
  @Prop({ type: Boolean, default: false }) private isStatic!: boolean;
}
