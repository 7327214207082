import { Expose, Type } from 'class-transformer';
import 'reflect-metadata';
import BadgeImg from '~/models/badge-img';

export default class Badge {
  @Expose()
  id!: number;

  @Expose({ name: 'default_desktop_img' })
  @Type(() => BadgeImg)
  defaultDesktopImg!: BadgeImg;

  @Expose({ name: 'default_tablet_img' })
  @Type(() => BadgeImg)
  defaultTabletImg!: BadgeImg;

  @Expose({ name: 'default_mobile_img' })
  @Type(() => BadgeImg)
  defaultMobileImg!: BadgeImg;

  @Expose({ name: 'big_desktop_img' })
  @Type(() => BadgeImg)
  bigDesktopImg!: BadgeImg;

  @Expose({ name: 'big_tablet_img' })
  @Type(() => BadgeImg)
  bigTabletImg!: BadgeImg;

  @Expose({ name: 'big_mobile_img' })
  @Type(() => BadgeImg)
  bigMobileImg!: BadgeImg;

  @Expose()
  name!: string;

  @Expose({ name: 'sort_order' })
  sortOrder!: number;

  @Expose({ name: 'is_bottom' })
  isBottom!: boolean;

  @Expose({ name: 'listing_dont_show_big' })
  listingDontShowBig!: boolean;

  @Expose({ name: 'video_url' })
  videoUrl?: string;
}
