
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { BasicNuxtLink } from '~/models/basic-link';
import Category from '~/models/category';

@Component({
  name: 'dn-navbar-dropdown',
})
export default class NavbarDropdown extends Vue {
  @Prop({ type: Array, required: false }) items?: Category[];
  @Prop({ type: Object, required: false }) dropdownShowMore?: BasicNuxtLink;
  @Prop({ type: Boolean, required: false }) isLoading?: Boolean;

  private onClickLink(e: Event, item: Category) {
    this.$emit('close');
    if (!item?.to) e.stopPropagation();
  }
}
