import cuid from 'cuid';
import { BasicNuxtLink } from '~/models/basic-link';
import { i18n } from '~/plugins/i18n';

export const errorRedirectLinksContent = (): BasicNuxtLink[] => [
  {
    id: cuid(),
    name: i18n.t('COMMON__BACK_TO_HOME_PAGE').toString(),
    to: 'index',
    highlighted: true,
  },
  {
    id: cuid(),
    name: i18n.t('NAVBAR__LEAFLET').toString(),
    to: {
      name: 'leaflet-list-listTab',
      params: { listTab: 'standard' },
    },
  },  
  {
    id: cuid(),
    name: i18n.t('NAVBAR__OFFER').toString(),
    to: { name: 'brands' },
  },
  {
    id: cuid(),
    name: i18n.t('NAVBAR__SERVICES').toString(),
    to: { name: 'services' },
  },
  {
    id: cuid(),
    name: i18n.t('NAVBAR__CAREER').toString(),
    to: { name: 'career' },
  },
]