
import { Component, getModule, Prop, Vue, Watch } from 'nuxt-property-decorator';
import NavbarItem from '~/models/navbarItem';
import GlobalModule from '~/store/GlobalModule';
import { navbarItems } from '~/constants/navbar-items';
import { OverlayEventBus } from '~/components/ui/Overlay/Overlay.vue';
import { LayoutService } from '~/services/layout.service';

@Component({
  name: 'dn-header',
})
export default class Header extends Vue {
  @Prop({ type: Boolean, default: false }) private isDetached!: boolean;

  private items: NavbarItem[] = navbarItems();
  private isActive: boolean = false;
  private globalModule!: GlobalModule;
  private topBannerHtml?: string = '';
  private additionalInfoHtml?: string = '';
  private closeMobileNavHandler: any;

  private get isMobileFilterPopupVisible(): boolean {
    return this.$store.state.GlobalModule.isMobilePopupVisible;
  }

  private async created() {
    this.globalModule = getModule(GlobalModule, this.$store);
    const { infoHtml, additionalInfoHtml } = await LayoutService.getTopBanner(this.$axios) || {}

    this.topBannerHtml = infoHtml;
    this.additionalInfoHtml = additionalInfoHtml;

    this.globalModule = getModule(GlobalModule, this.$store);
    OverlayEventBus.$off('overlayClicked');
    this.closeMobileNavHandler = this.closeMobileNav.bind(this);
    OverlayEventBus.$on('overlayClicked', this.closeMobileNavHandler);
  }

  private beforeDestroy() {
    OverlayEventBus.$off('overlayClicked');
  }

  @Watch('$route')
  private closeMobileNav() {
    this.isActive = false;
    this.globalModule.setIsMobilePopupVisible(false);
    this.globalModule.setIsMainOverlayVisible(false);
    document.documentElement.style.overflow = 'auto';
  }

  private toggleMobileNav() {
    if (window.innerWidth <= 1279) {
      this.isActive = !this.isActive;
      this.globalModule.setIsMobilePopupVisible(this.isActive);
      this.globalModule.setIsMainOverlayVisible(this.isActive);
    }
  }

  @Watch('globalModule.isOverlayVisible')
  private onOverlayVisibilityChange(isVisible: boolean) {
    if (!isVisible) {
      this.closeMobileNav();
    }
  }
}
