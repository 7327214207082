
import { Vue, Component } from 'nuxt-property-decorator';

@Component({
  name: 'dn-scroll-to-top-button',
})
export default class ScrollToTopButton extends Vue {
  private isButtonVisible = false;

  private handleScroll() {
    this.isButtonVisible = window.scrollY > 100;
  }

  private scrollToPageTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
