var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"enter-class":"opacity-0","enter-to-class":"opacity-100","leave-to-class":"opacity-0"}},[_c('nav',{staticClass:"transition absolute top-full -left-1 translate-y-1 z-20 p-2 w-max bg-white rounded-4xl border border-zinc-300 shadow-md overflow-hidden"},[_c('div',{staticClass:"relative flex flex-col gap-2 max-h-[400px] mx-1 my-1 overflow-y-auto scrollbar-custom",class:[{ 'mb-[72px]': _vm.dropdownShowMore && !_vm.isLoading }]},[(_vm.isLoading)?_c('Loader',{staticClass:"w-[340px] h-[400px]"}):_vm._e(),_vm._v(" "),_vm._l((_vm.items),function(item){return _c(item.to ? 'nuxt-link' : 'a',{key:item?.id,tag:"component",class:[
          { '!w-[320px]': item.style?.bigItem },
          'menu-item link second',
        ],attrs:{"to":_vm.localePath(item.to),"href":item.href,"active-class":"active","exact":"","target":item.to ? null : '_blank'},nativeOn:{"click":function($event){return _vm.onClickLink($event, item)}}},[_c('span',{staticClass:"flex items-center",class:{
            'h-[48px]': item.style?.bigItem,
          }},[_c('Img',{class:[
              'transition',
              {
                'w-5 h-5 mr-2 mb-[2px]': !item.style?.bigItem,
                'rounded-md h-auto w-[48px] mr-4 object-fill':
                  item.style?.bigItem,
              },
            ],attrs:{"src":item?.icon,"alt":item?.alt}})],1),_vm._v(" "),_c('span',{staticClass:"text text-nowrap"},[_vm._v(_vm._s(item.name))])])}),_vm._v(" "),(_vm.dropdownShowMore && !_vm.isLoading)?_c('div',{staticClass:"fixed grid items-center bottom-0 start-0 w-full h-[72px] p-4 bg-white border-t-[1px] border-zinc-300"},[_c('Button',{attrs:{"to":_vm.localePath(_vm.dropdownShowMore)},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n          "+_vm._s(_vm.$t('SEE_ALL'))+"\n        ")])],1):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }