
import Component from 'vue-class-component';
import { Prop, Vue } from 'nuxt-property-decorator';
import { BADGES_TYPE } from '~/constants/badges';
import Product from '~/models/product';

@Component({
  name: 'dn-product-badges',
  inheritAttrs: false,
})
export default class ProductBadges extends Vue {
  @Prop({ type: Boolean, default: false }) forPDP?: boolean;
  @Prop({ type: String, default: '' }) type?: keyof typeof BADGES_TYPE;
  @Prop({ required: true, type: Object }) product?: Product;
  @Prop({ type: Boolean, default: false }) alwaysShowDefault?: boolean;

  private BADGES_TYPE = BADGES_TYPE;

  private get badgesFlower() {
    return [
      this.product?.details?.badgeWater,
      this.product?.details?.badgeSun,
      this.product?.details?.badgeEnvironment,
    ]
      .filter(Boolean)
      .sort((a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0));
  }

  private get badges() {
    const badges = Array.from(this.product?.details?.badges || []).sort(
      (a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0),
    );

    if (this.forPDP || this.alwaysShowDefault) {
      return badges;
    }

    if (this.type === BADGES_TYPE.BOTTOM) {
      return badges.filter((badge) => badge.isBottom);
    }

    if (this.type === BADGES_TYPE.BIG) {
      return badges
        .filter((badge) => !badge.isBottom)
        .filter((badge) => !badge.listingDontShowBig)
        .slice(0, 1);
    }

    const listingDontShowBig = badges
      .filter((badge) => !badge.isBottom)
      .filter((badge) => !badge.listingDontShowBig)
      .slice(0, 1)
      .map((badge) => badge.id);

    return badges
      .filter((badge) => !badge.isBottom)
      .filter((badge) => !listingDontShowBig.includes(badge.id))
      .slice(0, this.badgesFlower.length > 0 ? 3 : 4);
  }
}
