import Widget from '~/models/widget';
import ProductResults from '~/models/product-results';

export const deleteProductsPrices = (_widget: Widget): Widget => {
  const products = (_widget.results as ProductResults)?.products?.map((prod) => ({
    ...prod,
    price: null,
  }));
  return {
    ..._widget,
    results: { ..._widget.results, products: products || [] },
  };
};
