import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=4df16115&scoped=true"
import script from "./Notifications.vue?vue&type=script&lang=ts"
export * from "./Notifications.vue?vue&type=script&lang=ts"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=4df16115&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4df16115",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/frontend/components/ui/img/img.vue').default,Button: require('/frontend/components/ui/buttons/Button.vue').default})
