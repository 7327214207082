import { API_URL } from '~/constants/api-url';
import { NuxtAxiosInstance } from '@nuxtjs/axios';

export class NewsletterService {
  static addSubscriber(
    axios: NuxtAxiosInstance,
    payload: {
      email: string;
    }
  ) {
    return axios.post(API_URL.NEWSLETTER.ROOT, payload);
  }
}
