
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  name: 'dn-img',
})
export default class Img extends Vue {
  @Prop({ type: String, default: '' }) src!: string | null;

  private error = false;

  private placeholderURL = require('~/assets/img/placeholder.svg');

  private get imageURL() {
    return this.src || this.placeholderURL;
  }

  private onError() {
    this.error = true;
  }
}
