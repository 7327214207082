
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import FooterItem from '~/models/footer-item';

@Component({
  name: 'dn-footer-link-list',
})
export default class FooterLinkList extends Vue {
  @Prop({ type: String }) title?: string;
  @Prop({ type: Array, required: true }) links!: FooterItem[];
  @Prop({ type: Boolean, default: false }) horizontal!: boolean;
  @Prop({ type: Boolean, default: false }) wide!: boolean;

  private linkType(item: FooterItem): string {
    if (item.to) {
      return 'nuxt-link';
    } else {
      return 'a';
    }
  }

  private linkAttrs(item: FooterItem) {
    if (this.linkType(item) === 'nuxt-link') {
      return { to: this.localePath(item.to) };
    } else {
      return {
        href: item.href,
        target: '_blank',
      };
    }
  }
}
