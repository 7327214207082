
import { Vue, Component, Model, Prop, Emit } from 'nuxt-property-decorator';
import { ValidationColor } from '~/models/enums/validation-color';

@Component({
  name: 'dn-text-input',
})
export default class TextInput extends Vue {
  @Model('input', { type: String }) value!: string;
  @Prop({ type: String, required: true }) input!: string;
  @Prop({ type: String, required: false }) label?: string;
  @Prop({ type: String, default: 'text' }) type!: string;
  @Prop({ type: Boolean, default: false }) isError!: string;
  @Prop({ type: Boolean, default: false }) secondary!: string;
  @Prop({ type: String, required: false }) placeholder?: string;
  @Prop({ type: Boolean, default: false }) optional?: boolean;
  @Prop({ type: Boolean, default: false }) redesigned?: boolean;
  @Prop({ type: String }) unit?: string;
  @Prop({ type: String, default: ValidationColor.ERROR })
  validationColor!: ValidationColor;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  private isFocused: boolean = false;
  private showPassword: boolean = false;

  private get inputType() {
    return this.showPassword ? 'text' : this.type;
  }

  private get inputClasses() {
    const commonCls = "relative py-3 px-3.5 rounded-md border-1 ring-1 ring-transparent w-full transition-colors";

    if (this.redesigned) {
      return this.$options.filters?.merge(
        commonCls, 
        'px-4 font-normal font-poppins border-zinc-300 rounded-full h-10 shadow-input placeholder:text-zinc-400',
        this.isError && 'border-red-500'
      )
    }

    return this.secondary
      ? `${commonCls} bg-transparent border-white placeholder:text-white`
      : `${commonCls} bg-white border-gray-2`;
  }

  private get labelClasses() {
    if (this.redesigned) {
      return this.$options.filters?.merge(
        "text-base font-medium text-zinc-800"
      );
    }
    
    // basically, display as invisible (accessibility reasons)
    return "absolute -translate-y-6 translate-x-2 text-xs text-transparent";
  }

  private get labelErrorClass() {
    return `text-${this.validationColor}`;
  }

  private get inputErrorClass() {
    return `border-${this.validationColor} focus:border-${this.validationColor} focus:ring-${this.validationColor}`;
  }

  @Emit('blur')
  private onBlur(val: string) {
    this.isFocused = false;
  }

  @Emit('focus')
  private onFocus(val: string) {
    this.isFocused = true;
  }

  @Emit('input')
  private onChange(val: string) {}
}
