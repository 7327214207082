
import { getModule, Component, Prop, Vue } from 'nuxt-property-decorator';
import { Popup } from '~/models/popup';
import PromotionalPopupBannerTemplate from '~/components/promotional-popups/sub/templates/PromotionalPopupBannerTemplate.vue';
import PromotionalPopupVariantTemplate from '~/components/promotional-popups/sub/templates/PromotionalPopupVariantTemplate.vue';

@Component({
  name: 'dn-promotional-popup',
})
export default class PromotionalPopup extends Vue {
  @Prop({ type: Object, default: () => [] }) private popup!: Popup;

  get template() {
    switch(this.popup.contentType) {
      case 'variant':
        return PromotionalPopupVariantTemplate
      case 'file':
        return PromotionalPopupBannerTemplate
      default:
        return PromotionalPopupBannerTemplate
    }
  }

  mounted() {
    const isDismissed = this.$cookies.get(`popup-${this.popup.id}-dismissed`);
    if (isDismissed) {
      return
    }

    this.show()
  }

  show() {
    this.$dialog(this)
      .setId(this.popup.id)
      .setComponent(this.template)
      .setProps({ popup: this.popup })
      .setWidth('812px')
      .setContentClasses('p-0 md:p-0 m-0 md:m-0')
      .setOverlayClasses('z-[31]')
      .setCloseBtnClasses(
        'absolute -right-6 lg:-right-8 -top-20 z-10 grid place-items-center w-10 h-10 rounded-full bg-neutral-50/50 transition-colors hover:bg-neutral-50/80'
      )
      .setDisplayAsDrawerOnMobile(true)
      .show()
  }

}

