
import { Component, Model, Emit, mixins } from 'nuxt-property-decorator';
import LeafletMixin from '~/mixins/lealfet.mixin';
@Component({
  name: 'dn-leaflet-range-slider',
})
export default class LeafletRangeSlider extends mixins(LeafletMixin) {
  @Model('input', { type: Number }) value!: number;

  private get rangeWidth(): string {
    return `calc(${this.value / (this.totalGroupNumber - 1)} * (100% - 24px))`;
  }

  private get pageNumber(): string {
    return this.currentPagesNumbers.join('-');
  }

  @Emit()
  private input(value: string): number | void {
    if (+value < this.totalGroupNumber) {
      return this.pageByGroupId(+value);
    }
  }
}
