import Widget from '~/models/widget';
import { WidgetContext } from '~/models/enums/widget-context';
import { plainToInstance } from 'class-transformer';
import WidgetRequestParams from '~/models/widget-request-params';
import { ImageSize } from '~/models/enums/image-size';
import { API_URL } from '~/constants/api-url';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import ProductGroupWithUrl from '~/models/product-group-with-url';

export class WidgetService {
  static async getSingleWidget(
    axios: NuxtAxiosInstance,
    contentUrl: string,
    isMobile: boolean,
    size: ImageSize,
    mapData?: (data: Widget) => Widget,
    pagingParams: { [key: string]: string | (string | null)[] } = {},
  ): Promise<Widget> {
    const params: WidgetRequestParams = {
      ...(isMobile && { context: WidgetContext.WEB_MOBILE }),
      size,
      ...pagingParams,
    };

    const widget = await axios.get(contentUrl, { params }).then((res) => {
      return res.data;
    });

    const groups =
      widget.groups && widget.groups.length
        ? ((
            await Promise.all(
              widget.groups.map(async (group: any) => {
                const productGroupWidgetData = plainToInstance(
                  ProductGroupWithUrl,
                  group,
                );
                try {
                  let content = await this.getSingleWidget(
                    axios,
                    productGroupWidgetData.contentUrl,
                    isMobile,
                    size,
                    mapData,
                    pagingParams,
                  );
                  return {
                    ...content,
                    slug: productGroupWidgetData.name,
                    title: productGroupWidgetData.name,
                  };
                } catch (e) {
                  console.log(e);
                }
              }),
            )
          ).filter((e) => !!e) as Widget[])
        : [];
    const classWidget = { ...plainToInstance(Widget, widget), groups: groups };
    
    return !!mapData ? mapData(classWidget) : classWidget;
  }

  static getWidgetBySlug(
    axios: NuxtAxiosInstance,
    slug: string,
    isMobile: boolean,
  ): Promise<Widget | undefined> {
    const params = {
      ...(isMobile && { context: WidgetContext.WEB_MOBILE }),
      size: isMobile ? ImageSize.MOBILE_BANNER : ImageSize.DESKTOP_BANNER,
    };

    return axios
      .get(API_URL.WIDGETS.BANNER(slug), { params })
      .then((res) => {
        return plainToInstance(Widget, res.data);
      })
      .catch((err) => {
        return undefined;
      });
  }
}
