import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=05697dce"
import script from "./Footer.vue?vue&type=script&lang=ts"
export * from "./Footer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/frontend/components/ui/img/img.vue').default,FooterInfo: require('/frontend/components/layout/Footer/sub/FooterInfo.vue').default,FooterLinkList: require('/frontend/components/layout/Footer/sub/FooterLinkList.vue').default})
