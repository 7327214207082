import { render, staticRenderFns } from "./Input.vue?vue&type=template&id=0e59fc22"
import script from "./Input.vue?vue&type=script&lang=ts"
export * from "./Input.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/frontend/components/ui/inputs/Input.vue').default})
