
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import FooterSection from '~/models/footer-section';

@Component({
  name: 'dn-footer',
})
export default class Footer extends Vue {
  @Prop({ type: Array, required: true }) sections!: FooterSection[];
  @Prop({ type: Object, required: true }) appLinks!: FooterSection;
  @Prop({ type: Object, required: true }) customerServiceOffice!: FooterSection;
  @Prop({ type: Object, required: true }) openingHours!: FooterSection;

  private year: number = new Date().getFullYear();
}
