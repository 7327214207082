import { Expose } from 'class-transformer';
import Leaflet from '~/models/leaflet';
import File from '~/models/file';
import 'reflect-metadata';

export default class LeafletData {
  @Expose()
  id!: number;

  @Expose({ name: 'is_alcohol' })
  isAlcohol!: boolean;

  @Expose()
  active!: boolean;

  @Expose()
  name!: boolean;

  @Expose({ name: 'active_from' })
  activeFrom!: string | null;

  @Expose({ name: 'active_to' })
  activeTo!: string | null;

  @Expose({ name: 'displayed_from' })
  displayedFrom!: string | null;

  @Expose({ name: 'displayed_to' })
  displayedTo!: string | null;

  @Expose({ name: 'displayed_to' })
  pdf!: File;

  @Expose()
  pages!: Leaflet[];
}
