
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import NavbarItem from '~/models/navbarItem';
import ActiveCategory from '~/models/active-category';
import { BasicNuxtLink } from '~/models/basic-link';

@Component({
  name: 'dn-navbar-mobile-menu',
})
export default class NavbarMobileMenu extends Vue {
  @Prop({ type: Array, required: true }) items!: NavbarItem[];
  @Prop({ type: Boolean, required: false }) isActive!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  subCategory!: boolean;
  @Prop({ type: String, required: false, default: 'Menu' }) title!: string;
  @Prop({ type: Function, required: false }) onClose!: () => void;
  @Prop({ type: Function, required: false }) dropdownDataDynamicFn?: Function;
  @Prop({ type: Function, required: false }) itemMapper?: Function;
  @Prop({ type: Object, required: false }) dropdownShowMore?: BasicNuxtLink;

  private activeCategory: ActiveCategory = { title: '', items: [] };

  private get isCategorySelected(): boolean {
    return !!this.activeCategory?.items.length || !!this.activeCategory?.dropdownDataDynamicFn;
  }

  private onCloseCategories() {
    this.activeCategory.title = '';
    this.activeCategory.items = [];
    this.activeCategory.dropdownDataDynamicFn = undefined;
    this.activeCategory.itemMapper = undefined;
    this.activeCategory.dropdownShowMore = undefined;

  }

  private showSubcategories(item: NavbarItem) {
    if (item.dropdownData || item.dropdownDataDynamicFn) {
      this.activeCategory.title = item.name;
      this.activeCategory.items = item.dropdownData || [];
      this.activeCategory.dropdownDataDynamicFn = item.dropdownDataDynamicFn;
      this.activeCategory.itemMapper = item.itemMapper;
      this.activeCategory.dropdownShowMore = item.dropdownShowMore;
    }
  }
}
