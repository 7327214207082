import { Currency } from '~/models/enums/currency';

export function priceFilter(
  value: number,
  currency: Currency,
  minimumFractionDigits: number = 2
): string {
  //TODO from app locale
  const locale: string = 'pl';
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || Currency.PLN,
    minimumFractionDigits,
  });
  return formatter.format(value);
}
