
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { cx } from 'class-variance-authority';
import { BEER_CATEGORY_SLUG, BEER_SLUG } from '~/constants/category';

@Component({
  name: 'dn-product-alcohol-message',
})
export default class ProductAlcoholMessage extends Vue {
  @Prop({ type: Boolean, default: false }) condensed?: boolean;
  @Prop({ type: Boolean, default: false }) showBeer?: boolean;

  private get isBeer() {
    return (
      this.$route.params?.category === BEER_CATEGORY_SLUG ||
      this.$route.params?.slug?.includes(BEER_SLUG)
    );
  }

  private get containerClasses() {
    return this.$options.filters?.merge(
      cx(
        'flex flex-col items-center border-red border-2 text-center bg-white',
        {
          'py-2 px-4 gap-2 rounded-2xl lg:rounded-3xl': !this.condensed,
          'py-1 px-2 gap-0.5 lg:rounded-2xl rounded-lg': this.condensed,
        },
      ),
    );
  }

  private get textClasses() {
    return this.$options.filters?.merge(
      cx('font-semibold text-red', {
        'text-2.5xs lg:text-base lg:leading-4 leading-3': !this.condensed,
        'lg:text-2.5xs text-[6px] leading-[8px] lg:leading-4': this.condensed,
      }),
    );
  }
}
