var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isLeaf)?_c(_vm.linkType,_vm._b({tag:"component",staticClass:"menu-item link",class:{ 
    mark: _vm.item?.mark,
  },attrs:{"active-class":"active","exact":""}},'component',_vm.linkAttrs,false),[_c('span',{staticClass:"flex items-center",class:{
      'h-[48px]': _vm.item.style?.bigItem
    }},[_c('img',{class:{ 
        'w-5 h-5 mr-2 mb-[2px]': !_vm.item.style?.bigItem, 
        'rounded-md h-auto w-[48px] mr-4 object-fill': _vm.item.style?.bigItem 
      },attrs:{"src":_vm.item?.icon,"alt":_vm.item?.alt}})]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.item.name))])]):_c('button',{staticClass:"menu-item nested",attrs:{"type":"button"}},[_c('img',{staticClass:"image",attrs:{"src":_vm.item?.icon,"alt":_vm.item?.alt}}),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.item.name))])])
}
var staticRenderFns = []

export { render, staticRenderFns }