import { Expose, Type } from 'class-transformer';
import DemoCategory from '~/models/demo-category';
import 'reflect-metadata';
import BannerFile from '~/models/file';

export default class SimpleProducts {
  @Expose()
  promoText?: string;

  @Expose()
  slogan?: string;

  @Expose()
  photo?: BannerFile;

  @Expose()
  title?: string;

  @Expose()
  description?: string;
}
