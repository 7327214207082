import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ThemeColor } from '~/models/enums/theme-color';
import { ElementSize } from '~/models/enums/element-size';

@Component
export default class ButtonMixin extends Vue {
  @Prop({ type: String, default: 'button' }) type!: 'button' | 'submit';
  @Prop({ type: String, default: undefined }) label!: string | undefined;
  @Prop({ type: String, default: undefined }) ariaLabel!: string | undefined;
  @Prop({ type: String, default: undefined }) icon!: string | undefined;
  @Prop({ type: String, default: undefined }) bgColor!: string | undefined;
  @Prop({ type: String, default: undefined }) textColor!: string | undefined;
  @Prop({ type: String, default: undefined }) override!: string | undefined;
  @Prop({ type: Boolean, default: false }) block!: boolean;
  @Prop({ type: Boolean, default: false }) outlined!: boolean;
  @Prop({ type: Boolean, default: false }) roundedFull!: boolean;
  @Prop({ type: Boolean, default: false }) shadow!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Boolean, default: false }) lowerCase!: boolean;
  @Prop({ type: String, default: ElementSize.MD }) size!: ElementSize;
  @Prop({ type: String, default: ElementSize.MD }) textSize!: ElementSize;
  @Prop({ type: Boolean, default: false }) noShadowHover!: boolean;

  ElementSizeEnum = ElementSize;

  bgColorClasses: any = {
    [ThemeColor.PRIMARY]: 'bg-primary hover:bg-primary-dark',
    [ThemeColor.SECONDARY]: 'bg-secondary',
    [ThemeColor.INVERSED]: 'bg-white hover:bg-inversed-dark',
    [ThemeColor.ERROR]: 'bg-red hover:bg-red-700',
    [ThemeColor.GRAY]: 'bg-gray-1',
    transparent: 'bg-transparent',
  };

  textColorClasses: any = {
    [ThemeColor.PRIMARY]: 'text-primary hover:bg-primary',
    [ThemeColor.SECONDARY]: 'text-secondary',
    [ThemeColor.GRAY]: 'text-gray-1',
    [ThemeColor.INVERSED]: 'text-white',
    current: 'text-current',
  };

  sizeClasses: Record<ElementSize, string> = {
    [ElementSize.XS]: 'py-2',
    [ElementSize.SM]: 'py-2.75',
    [ElementSize.MD]: 'py-3',
    [ElementSize.LG]: 'py-4',
    [ElementSize.XL]: 'py-5',
  };

  textSizeClasses: Record<ElementSize, string> = {
    [ElementSize.XS]: 'text-xs',
    [ElementSize.SM]: 'text-sm',
    [ElementSize.MD]: 'text-2sm',
    [ElementSize.LG]: 'text-md',
    [ElementSize.XL]: 'text-lg',
  };

  private get roundedClass(): string {
    return this.roundedFull ? 'rounded-full' : 'rounded-2lg';
  }

  private get displayClass(): string {
    return this.block ? 'block w-full' : 'flex';
  }

  get sizeClass(): string {
    return this.sizeClasses && this.sizeClasses[this.size];
  }

  get textSizeClass(): string {
    return this.textSizeClasses && this.textSizeClasses[this.textSize];
  }

  get textColorClass(): string {
    return this.textColor && this.textColorClasses[this.textColor];
  }

  private get bgColorClass(): string {
    return this.bgColor && this.bgColorClasses[this.bgColor];
  }

  get overrideClass(): string {
    return this.override || '';
  }

  get borderClass(): string | undefined {
    return (this.outlined && 'border border-gray-3') || undefined;
  }
}
