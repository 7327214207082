import { Component, Vue } from 'nuxt-property-decorator';
import NewsletterModal from '~/components/dialogs/newsletter-modal/NewsletterModal.vue';
import { TIMERS } from '~/constants/timers';
import { NewsletterFormModel } from '~/models/forms/newsletter-form';
import { cookieKeys } from '~/constants/cookie-keys';
import { DAYS_14_IN_SECONDS, getClientCookie, setClientCookie } from '~/services/cookies.service';
import { NewsletterModalStep } from '~/models/newsletter';

@Component
export default class NewsletterModalMixin extends Vue {
  private dialog: any | null = null;
  private timer: any | null = null;

  initModalTimer() {
    if (!getClientCookie(cookieKeys.NEWSLETTER_DISMISSED) && !getClientCookie(cookieKeys.NEWSLETTER_SUBSCRIBED)) {
      this.timer = setTimeout(() => {
        this.showNewsletterModal();
      }, TIMERS.NEWSLETTER__MODAL_TIMEOUT);
    }
  }


  showNewsletterModal(fields?: NewsletterFormModel, errors?: FormErrors, initialStep?: NewsletterModalStep) {
    clearTimeout(this.timer);
    this.createAndShowDialog(fields, errors, initialStep);
  }

  createAndShowDialog(fields?: NewsletterFormModel, errors?: FormErrors, initialStep?: NewsletterModalStep) {
    this.dialog = this.$dialog(this)
      .setComponent(NewsletterModal)
      .setProps({
        fields,
        errors,
        initialStep
      })
      .setWidth('546px')
      .setMobileFullscreen(false)
      .setRounded(true)
      .setDialogClasses('p-0 md:p-0 pt-0')
      .setContentClasses('px-0 md:px-0 pt-0')
      .setShowClose(false)
      .setCloseOnOutsideClick(false)
      .setEvents({
        close: () => {
          this.destroyDialog();
          setClientCookie(cookieKeys.NEWSLETTER_DISMISSED, 'true', DAYS_14_IN_SECONDS);
        },
      })
      .show();
  }

  private destroyDialog() {
    this.dialog?.destroy();
    this.dialog = null;
  }
}