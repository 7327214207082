import { Context } from '@nuxt/types';

export default function ({ redirect, route, app }: Context) {
  // If the user is not authenticated

  if (route.path === '/rodo') {
    return redirect(
      app.localePath({
        name: 'regulations-regulation',
        params: { regulation: 'rodo' },
      })
    );
  }
}
